.user-filter-main-desktop {
  width: 100%;
  height: auto;
  border: 1px solid #1ec28b;
  border-radius: 5px;
  margin-top: 3px;
  display: block;
  background: white;
}
.user-filter-second {
  background: #1ec28b;
  color: white;
  display: flex;
  justify-content: center;
  height: 30px;
  column-gap: 7px;
  padding-top: 5px;
}
.filter-popup-icon{

  font:"20px";
  cursor:"pointer"
}
.user-filterage {
  display: flex;
  justify-content: center;
  height: 50px;
  column-gap: 15px;
}
.user-icon-age {
  margin-top: 25px;
  border: 1px solid green;
  color: white;
  background: rgb(30, 194, 139);
  border-radius: 42px;
  width: 10%;
  height: 20px;
}
.user-filter-age {
  width: 72%;
  display: flex;
  justify-content: center !important;
  margin-top: 7px;
  align-items: baseline;
}
.user-filter-Age {
  margin-top: 10px !important;
}
/* .user-icon-date {
} */
.user-icon-country {
  margin-top: 5px;
  border: 1px solid green;
  color: white;
  background: rgb(30, 194, 139);
  border-radius: 42px;
  width: 10%;
  height: 20px;
  margin-left: 8px;
}
.user-filter-Date {
  margin-top: 15px;
  border: 1px solid green;
  color: white;
  background: rgb(30, 194, 139);
  border-radius: 42px;
  width: 11%;
  height: 19px;
  margin-left: 5px;
}
/* .user-filter-Age .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
  width: 90% !important;
} */
.user-filter-Age .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 6px !important;
}
.user-filter-Age .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  top: 0px !important;
}
.user-filter-Age label {
  top: -9px;
  left: 4px;
}
.userfilter-date {
  display: flex;
  column-gap: 14px;
  height: 47px;
}
.user-filterdate {
  width: 75% !important;
}
.user-filterdate .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: 4px;
  height: 36px;
}
.user-filterdate .css-1jgdl3e-MuiFormControl-root-MuiTextField-root input {
  color: black;
}
.user-filter-Country {
  /* display: flex;
  column-gap: 15px;
  height: 42px;
  margin-top: 17px; */

  margin-top: 17px;
  display: flex;
  justify-content: center;
  height: 50px;
  column-gap: 15px;
}
.userfilter-Country {
  width: 75%;
}
.userfilter-Country .MuiOutlinedInput-root {
  padding: 0!important;
  width: 96% !important;
  height: 32px;
}
.userfilter-Country label {
  top: -8px;
  left: 5px;
}
.connect-pop { 
  background-repeat: no-repeat;
  height: 90%;
  display: flex;
  align-items: center;
  overflow-y: auto;
}
.user-validate-age {
  color: red;
  font-size: 13px !important;
  height: 5px;
  margin-top: 4px;
}
.apply-filter-btn {
  background-color: #ff8d8d !important;
  color: white !important;
  border: none !important;
  width: 120px !important;
  height: 20px !important;
  font-size: 12px !important;
  margin-top: 6px !important;
  border-radius: 5px !important;
  margin-bottom: 10px !important;
}
