.email-verification-img {
  display: flex;
  width: 25%;
  margin-top: 10px;
}
.email-verification-content {
  line-height: 1.5;
  font-size: 25px;
  margin-top: 50px;
  padding: 75px;
}
.email-verification-btn {
  background-color: #1ec28b !important;
  margin-top: -50px !important;
  color: white !important;
  border: none !important;
  width: 160px !important;
  height: 40px !important;
  font-size: 15px !important;
  border-radius: 9px !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .email-verification-img {
    display: flex;
    width: 50%;
    height: 25px;
    margin-top: 10px;
}
.email-verification-content {
  line-height: 1.5;
  font-size: 16px;
  margin-top: 50px;
  padding: 75px;
}
}