
.dbsql-profile-main {
  display: flex;
}
.admindashboard-heading {
  font-size: 25px;
  justify-content: start;
  display: flex;
  font-weight: 500 !important;
}
.ads-management-subhead {
  font-size: 19px !important;
  margin-bottom: 30px;
}
.ads-management-subheading {
  color: #1ec28b;
}
.ads-manage-home {
  /* color: gray; */
  color: 12 12 12 (50%);
  text-decoration: none;
}
.ads-management-card-maingrid {
  display: flex;
  column-gap: 80px;
}
.ads-management-card {
  height: 115px;
  border-radius: 10px !important;
  border: 2px solid lightgray;
  /* border:  2px solid 1,12 12 12 ; */
}
.ads-management-second-card {
  height: 115px;
  border-radius: 10px !important;
  border: 2px solid lightgrey;
  box-shadow: none !important;
}
.ads-card-design {
  width: 100%;
  margin: auto;
}
.ads-management-number {
  font-size: 30px;
  color: #1ec28b;
  justify-content: center;
  margin-top: 20px;
  font-weight: bold;
}
.ads-management-content {
  font-size: 20px;
  font-weight: 500;
  color: 12 12 12 (70%);
  margin-top: 20px;
}
.ads-management-main-div {
  margin-top: 20px !important;
  margin-bottom: 30px !important;
  align-items: center !important;
}
.ads-management-button-grid {
  align-items: center;
  justify-content: end !important;
  display: flex;
  border-radius: 2px !important;
}
.ads-management-button {
  background: #1ec28b !important;
  color: white !important;
  text-transform: lowercase !important;
  font-size: 16px !important;
}
.ads-management-icon {
  align-items: center !important;
  display: flex;
  font-size: 15px !important;
}

.ads-management-search-feild {
  display: flex !important;
  border-radius: 5px;
  margin-top: 10px !important;
  font-size: 20px !important;
}
.ads-management-searchicon {
  display: flex !important;
  font-size: 30px !important;
}
.ads-management-table {
  width: 100%;
  height: 30px;
  border: 1px solid lightgray !important;
  border-collapse: collapse;
  border-radius: 10px !important;
  justify-content: center !important;
  display: inline-table !important;
}
.ads-manage-heading-tr-main {
  background: lightgray;
  height: 50px;
}
.ads-manage-heading-tr {
  border: 1px solid lightgrey !important;
  border-collapse: collapse !important;
}
.ads-manage-flipkart {
  font-weight: bold;
  font-style: italic;
}
.ads-manage-flipkart-com {
  color: blue;


  max-width: 200px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ads-manage-main-td {
  column-gap: 10px !important;
  display: flex;
  margin-top: 32px;
  justify-content: center;
}
.ads-manage-eyeicon {
  color: white;
  border-radius: 3px;
  width: 25px;
  height: 20px;
  background: rgb(255 191 0 / 50%) !important;
  cursor: pointer;
}
.ads-manage-deleteicon {
  border-radius: 3px !important;
  width: 25%;
  color: white;
  height: 20px;
  cursor: pointer;
  background: rgb(239 61 25 /50%);
  /* opacity: 0; */
}
.ads-manage-pagination {
  margin-top: 30px;
  border: none !important;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white !important;
  margin-bottom: 30px;
}
.ads-management-pagination-button {
  align-items: center !important;
  background: #1ec28b;
  display: flex !important;
  margin: auto !important;
  justify-content: center !important;
  column-gap: 1px !important;
}
.ads-manage-pagination {
  display: flex;
  justify-content: center;
}
.ads-management-switch {
  color: #1ec28b;
}
.search-illustration-img {
  width: 17%;
  justify-content: center;
  margin-bottom: 20px;
}
.search-bar-no-results-found-main-div {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}
.adsmanagement-no-results-found {
  font-size: 20px;
  font-weight: 600;
  color: rgb(229, 86, 50);
  margin-bottom: 20px;
}
.ads-management-delete-dalog {
  display: flex;
  justify-content: center;
  column-gap: 30px;
}
.upcoming-deleteConfirmationIcon {
  margin-top: 1px;
  color: #ff8d8d !important;
  font-size: 27px;
}
.upcoming-Hrline {
  height: 1px;
  border-width: 0px;
  color: rgb(30, 194, 139);
  background-color: rgb(30, 194, 139);
  width: 70%;
}
.ads-management-areUSure {
  display: flex;
  justify-content: center;
}
.ads-management-buttonsContainer {
  margin-bottom: 30px !important;
  justify-content: center !important;
  display: flex !important;
  column-gap: 100px;
}
.ads-management-yesButton {
  background: #1ec28b !important;
  color: white !important;
  height: 27px !important;
  border-radius: 5px !important;
}
.ads-management-noButton {
  background: #1ec28b !important;
  color: white !important;
  height: 27px !important;
  border-radius: 5px !important;
}

.ads-management-search-feild .MuiOutlinedInput-notchedOutline {
  border: 1px solid #c2c2c2 !important;
}

.ads-management-delete-dialog {
  /* width: 100%; */
  /* height: 52rem; */
  height: 120%;
  background-color: rgba(226, 214, 214, 0.111);

  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;
  /* opacity: 0; */
}

