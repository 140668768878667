.modalBackground {
  width: 94vw;
  height: auto;
  background-color: rgba(255, 141, 141, 10%) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/public/Assets/Images/Image_3.webp);
  background-size: 100%;
}
.PopupflightTakeoff {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}
.popupCatherineImageContainer {
  margin-top: -135px !important;
  display: flex;
  justify-content: center;
}
.popupCatherine {
  height: 180px !important;
  height: 200px !important;
  width: 217px;
}
.curvedDashedImage {
  margin-bottom: -96px;
  width: 780px;
  margin-right: -20px;
}
.FlightTakeoffOutlinedIconpopup {
  font-size: 27px !important;
  color: #ff8d8d !important;
  margin-top: -40px !important;
  margin-left: 14px;
}
.FlightTakeoffOutlinedContainer {
  display: flex;
  justify-content: center;
}
.boxDashedImage {
  margin-left: 207px;
  width: 168px;
  margin-bottom: -122px;
}
.boxDashedImage2 {
  margin-left: 220px;
  width: 168px;
  margin-bottom: -122px;
}
.boxDashedImage3 {
  margin-left: 230px;
  width: 168px;
  margin-bottom: -122px;
}
.boxDashedImage4 {
  margin-left: 240px;
  width: 168px;
  margin-bottom: -122px;
}
.locationDestinationIcons {
  display: flex !important;
  justify-content: center !important;
  margin-top: 15px !important;
}
.locationDestinationMapIcon1 {
  color: #1ec28b;
  font-size: 30px !important;
  margin-left: -222px !important;
}
.dashedImagediv {
  display: flex !important;
  justify-content: center !important;
}
.locationDestinationMapIcon2 {
  color: #ff8d8d !important;
  font-size: 30px !important ;
  margin-left: 345px !important;
}
.locationDestinationText {
  display: flex;
  justify-content: center;
  margin-left: 5px !important;
  margin-bottom: -54px;
}
.flyingFrom {
  font-size: 17px;
  margin-left: -7px;
}

.LA {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-left: 60px;
}
.LA1 {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-left: 25px;
}
.LA2 {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-left: 27px;
}
.LA3 {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-left: 115px;
}
.LA4 {
  font-size: 20px !important;
  font-weight: 500 !important;
}
.LAX {
  font-size: 20px !important;
  font-weight: 500 !important;
}
.Transit1 {
  font-size: 17px !important;
  margin-left: 25px;
}
.Transit2 {
  font-size: 17px !important;
  margin-left: 25px;
}
.Transit3 {
  font-size: 17px !important;
  margin-left: 115px;
}

.connectDubaibaiPopup1 {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-left: 5px;
}
.flyingPheonix {
  margin-right: 55px !important;
}
.PHX {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-left: -8px;
}
.destinationChennaiContainer {
  margin-left: -40px !important;
}
.TransitOneContainer {
  margin-left: -34px !important;
}
.TransitTwoContainer {
  margin-left: -10px !important;
}
.Pheonix {
  font-size: 20px;
  font-weight: 500;
  margin-left: -15px;
}
.connectPopupDestination {
  font-size: 17px;
  margin-left: 40px;
}
.connectPopupChennai {
  font-size: 20px;
  font-weight: 500;
  margin-left: 39px;
}
.modalContainer {
  margin-top: 100px;
  width: 900px;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.titleCatherine {
  font-size: 13px;
  display: flex;
  justify-content: center;
}

.titleCloseBtn {
  display: flex;
  justify-content: end;
  margin-right: -130px;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  margin-top: -200px;
  font-size: 35px;
  cursor: pointer;
  color: white !important;
}

.ageAndGenderContainer {
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  margin-bottom: -30px !important;
  justify-content: center !important;
}

#cancelBtn {
  background-color: crimson;
}


.whatsapp-icon-coonected-List{
padding-top: 5px;
  color: #1ec28b;
}
.mobile-icon-connected-list{
color: #1ec28b;
font-size: 19px;
}
.email-icon-connected-list{
  color: #1ec28b;
  font-size: 19px;
  }
.airlineIndigoContainer {
  display: flex !important;
  justify-content: center;
  margin-bottom: 60px;
  /* margin-left: 3px !important; */
  /* margin-top: 20px !important; */
}
.indigoBox1 {
  background-color: #1ec28b;
  text-align: center;
  width: 81px;
  height: 25px;
  border-radius: 3px;
  /* margin-left: 4px !important; */
  margin-top: -21px;
}
.airlineText1 {
  margin-top: 35px;
  margin-left: 15px !important;
}

.indigoBox2 {
  background-color: #1ec28b;
  width: 80px;
  height: 25px;
  border-radius: 3px;
  margin-left: 18px;
  margin-top: -21px;
}
.indigoBox3 {
  background-color: #1ec28b;
  width: 80px;
  height: 25px;
  border-radius: 3px;
  margin-left: 37px;
  margin-top: -21px;
}
.indigoBox4 {
  background-color: #1ec28b;
  width: 80px;
  height: 25px;
  border-radius: 3px;
  margin-left: 54px;
  margin-top: -21px;
}
.indigoBox5 {
  background-color: #1ec28b;
  width: 80px;
  height: 25px;
  border-radius: 3px;
  margin-left: 70px;
  margin-top: -21px;
}

.airlineText2 {
  margin-top: 35px;
  margin-left: 30px;
}
.airlineText3 {
  margin-top: 35px;
  margin-left: 50px;
}
.airlineText4 {
  margin-top: 35px;
  margin-left: 70px;
}
.airlineText5 {
  margin-top: 35px;
  margin-left: 85px;
}
.dateBoxContainer {
  width: 330px !important;
}

.indigoText {
  text-align: center;
  background-color: #1ec28b;
  border-radius: 5px;
  word-break: break-all;
}
.dateRangeOuterContainer {
  display: flex;
  margin-top: 45px;
  justify-content: center;
}
.boxInnerContainerpopup {
  height: 135px !important;
  background-color: rgba(30, 194, 139, 10%) !important;
  border-radius: 5px;
  margin-top: 25px;
}
.CalendarMonthRoundedIcon {
  color: #ff8d8d !important;
  font-size: 40px !important;
  margin-left: 35px;
}
.dateRangepopup {
  font-size: 18px !important;
  margin-left: 8px;
  width: max-content;
}
.dateBetweenpopup {
  font-size: 20px;
  margin-left: 8px;
  font-weight: 500;
  width: 147px !important;
}
.verticleLine {
  border-left: 1px solid green;
  height: 90px;
  margin-top: 2px !important;
  margin-left: 10px;
}
.PriceChangeIconConnect {
  font-size: 44px !important;
  color: #ff8d8d !important;
  margin-left: 22px;
}
.tippopup {
  font-size: 17px;
  margin-left: -2px;
  width: 96px !important;
}
.USDpopup {
  margin-left: 7px;
  font-size: 20px;
  font-weight: 500;
}
.iamLookingText {
  font-size: 16px;
  margin-left: 240px !important;
  margin-top: 35px !important;
  line-height: 2;
  color: #000000 !important;
}
.placesInChennai {
  display: flex;
  justify-content: center;
}
.iconsOuterContainer1 {
  margin-left: 100px !important;
  margin-top: 20px !important;
}
.tripName {
  margin-left: -40px;
  font-size: 17px;
  color: rgba(0, 0, 0, 80%);
  font-weight: 350;
}
.chennaiExplore {
  margin-left: -45px;
  font-size: 20px;
  color: rgba(0, 0, 0, 100%);
  font-weight: 500;
}
.Explore {
  margin-left: -42px;
  font-size: 20px;
  color: rgba(0, 0, 0, 100%);
  font-weight: 500;
  width: 60%;
}
.meetupLocation {
  margin-left: -45px;
  font-size: 17px;
  color: rgba(0, 0, 0, 80%);
  font-weight: 350;
}
.airportGate {
  margin-left: -45px;
  font-size: 20px;
  color: rgba(0, 0, 0, 100%);
  font-weight: 500;
  width: 60%;
}
.preferedLanguage {
  margin-left: 10px;
  font-size: 17px;
  color: rgba(0, 0, 0, 80%);
  font-weight: 350;
}
.canAssist {
  margin-left: 10px;
  font-size: 17px;
  color: rgba(0, 0, 0, 80%);
  font-weight: 350;
}
.languages {
  margin-left: 8px;
  font-size: 20px;
  color: rgba(0, 0, 0, 100%);
  font-weight: 500;
  word-break: break-all;
}
.preferedLanguage {
  margin-left: 13px;
  font-size: 17px;
  color: rgba(0, 0, 0, 80%);
  font-weight: 350;
}
.carrryImportant {
  font-size: 20px;
  color: rgba(0, 0, 0, 100%);
  font-weight: 500;
}
.Documents {
  font-size: 20px;
  color: rgba(0, 0, 0, 100%);
  font-weight: 500;
  margin-left: 25px;
}

.ModeOfTravelRoundedIcon {
  font-size: 50px !important;
  margin-left: -30px;
}
.PlaceIcon {
  font-size: 50px !important;
  margin-left: -10px !important;
}
.LanguageRoundedIcon {
  color: #1ec28b;
  font-size: 50px !important;
  margin-left: 50px !important;
}
.handShake {
  font-size: 49px;
  margin-left: 42px;
}
.handshakeContainer {
  margin-left: 25px !important;
}

.inviteBtnConnectPeople1 {
  margin-top: 1215px;
  margin-bottom: 60px;
  margin-left: -555px;
  font-size: 17px;
  width: 170px;
  background: #1ec28b;
  border: none;
  height: 43px;
  border-radius: 10px;
  color: white;
}

.connectppl-popup-user-details-text-maingrid {
  margin: auto;
  text-align: center;
  justify-content: center;
  display: flex;
}

.connectppl-popup-user-details-text {
  font-size: 20px;
  font-weight: 500;
  column-gap: 15px;
  display: flex;
  align-items: center;
}

.connectppl-popup-user-details-text-mobileemail-grid {
  margin: auto;
  text-align: center;
  justify-content: center;
  display: flex;
}

.connectppl-popup-user-details-text-email-grid {
  /* margin: auto; */
  text-align: center;
  justify-content: center;
  display: flex;
  margin-top: 0px;
}

.connectppl-popup-user-details-text-mobileemail {
  font-size: 20px;
  font-weight: 500;
  column-gap: 15px;
  display: flex;
  align-items: center;
}
/* --------------------------------------New Css file connectppl popup -------------------------*/
.connectppl-popup-main-background{
  background-color: rgb(255, 242, 244);
  background-image: url(/public/Assets/Images/Image_3.webp); 
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  align-items: center;
  overflow-y: auto;
}

.connectppl-popup-card {
  height: auto;
  width: 85%;
  margin: auto;
}
.connectppl-popup-img-main {
  margin-bottom: -60px;
  text-align: center !important;
}
.connectppl-popup-img {
  border-radius: 10px;
  height: 130px;
  width: 140px;
  border-radius: inherit;
}

.connectppl-popup-name-text {
  font-size: 25px;
  font-weight: 500;
  margin-top: 75px;
}

.connectppl-popup-close-icon {
  display: flex;
  justify-content: end;
  margin-top: 107px;
}
.connectppl-popup-close-icon-btn {
  background: transparent;
  border: none;
  color: black;
  font-size: 30px;
  cursor: pointer;
}
.connectppl-popup-flight-icon {
  text-align: center;
}
.connectppl-popup-curvedDashedImage {
  width: 100%;
  transform: rotateY(35deg);
  margin-bottom: -70px;
}
.connectppl-popup-from-location-icon {
  color: #1ec28b;
  font-size: 20px;
}
.connectppl-popup-dest-location-icon {
  color: #ff8d8d;
  font-size: 20px;
}
.connectppl-popup-from-text {
  font-size: 15px;
  font-weight: 400;
  margin: auto;
}
.connectppl-popup-dest-text {
  font-size: 15px;
  font-weight: 400;
  margin: auto;
}
.connectppl-popup-from-sub-text {
  margin: auto;
  line-height: 2;
  font-size: 20px;
  font-weight: 500;
}
.connectppl-popup-dest-sub-text {
  margin: auto;
  line-height: 2;
  font-size: 20px;
  font-weight: 500;
}
.connect-ppl-airlines-main {
  display: flex;
  justify-content: center;
  text-align: center;
}
.connect-ppl-airlines-box {
  border: 1px solid #1ec28b;
  background: #1ec28b;
  width: 72%;
  height: 25px;
  margin-top: 60px;
}
.connectppl-popup-date-tip-main {
  background-color: rgba(30, 194, 139, 10%) !important;
  width: 50% !important;
  margin: auto !important;
  margin-top: 25px !important;
  height: 175px !important;
  text-align: center !important;
}

.connectppl-popup-date {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.connectppl-popup-tip-expected {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.connectppl-popup-date-expected-text {
  font-size: 15px;
  margin: auto;
  font-weight: 400;
  line-height: 1.5;
}
.connectppl-popup-tip-expected-text {
  font-size: 15px;
  margin: auto;
  font-weight: 400;
  line-height: 1.5;
}

.connectppl-popup-date-expected-text-sub-range {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.75;
}
.connectppl-popup-date-expected-text-sub {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.75;
}
.connectppl-popup-tip-expected-text-sub {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.75;
}
.connectppl-popup-date-icon {
  font-size: 45px !important;
  color: #ff8d8d !important;
  margin: auto;
}
.connectppl-popup-price-icon {
  font-size: 45px !important;
  color: #ff8d8d !important;
}
.connectppl-popup-tip-verticleLine-main {
  margin: auto !important;
  display: flex;
  justify-content: center;
}
.connectppl-popup-tip-verticleLine {
  border-left: 2px solid #1ec28b;
  height: 100px;
}
.connectppl-popup-trip-details-main {
  text-align: center;
  margin-top: 25px !important;
  margin-bottom: 50px !important;
}
.connectppl-popup-details-text {
  font-size: 20px !important;
  margin-bottom: 25px !important;
  margin-top: 25px !important;
}
.connectppl-popup-trip-details-icon {
  color: #1ec28b;
  font-size: 40px !important;
}
.connectppl-popup-trip-details {
  font-size: 15px;
  font-weight: 400;
}
.connectppl-popup-trip-details-sub {
  font-weight: 600;
  font-size: 20px;
}
.connectppl-popup-trip-invite-main {
  text-align: center;
  margin-top: 50px;
}
.connectppl-popup-trip-invite-btn {
  margin-bottom: 60px;
  font-size: 19px;
  background: #ff8d8d;
  border: none;
  height: 40px;
  width: 19%;
  font-weight: 600;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}

.connect-ppl-airlines-hr-line {
  background: #1ec28b;
  margin-top: 20px;
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
}
.desktop-view-transit-part {
  display: block;
}
.mobile-view-transit-part {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .connectppl-popup-card {
    height: auto;
    width: 100%;
    margin: auto;
  }
  .connectppl-popup-close-icon {
    display: flex;
    justify-content: end;
    margin-top: 73px;
  }
  .connectppl-popup-img-main {
    margin-bottom: -50px;
    text-align: center !important;
  }
  .connectppl-popup-img {
    border-radius: 10px;
    height: 100px;
    width: 100px;
  }
  .connectppl-popup-curvedDashedImage {
    width: 100%;
    transform: rotateY(60deg);
    margin-bottom: -70px;
  }
  .connectppl-popup-date-tip-main {
    background-color: rgba(30, 194, 139, 10%) !important;
    width: 100% !important;
    margin: auto !important;
    margin-top: 25px !important;
    height: 185px !important;
    text-align: center !important;
  }
  .connectppl-popup-details-text {
    font-size: 15px !important;
    margin-bottom: 25px !important;
    margin-top: 25px !important;
  }
  .connectppl-popup-trip-details-sub {
    font-weight: 600;
    font-size: 15px;
  }
  .connectppl-popup-trip-details-main {
    text-align: center;
    margin-top: 0px !important;
    margin-bottom: 50px !important;
  }
  .connectppl-popup-trip-invite-btn {
    margin-bottom: 60px;
    font-size: 15px;
    background: #ff8d8d;
    border: none;
    height: 40px;
    width: 60%;
    font-weight: 700;
    border-radius: 10px;
    color: white;
  }
  .connect-ppl-dotted-image {
    transform: rotate(90deg);
    height: 7px;
  }
  .connectppl-popup-user-details-text {
    font-size: 12px;
    font-weight: 500;
    column-gap: 7px;
  }

  .connectppl-popup-user-details-text-mobileemail {
    font-size: 12px;
    font-weight: 500;
    grid-column-gap: 15px;
    column-gap: 7px;
    display: flex;
    align-items: flex-start;
    text-align: initial;
  }

  .connectppl-popup-user-details-text-mobileemail-grid {
    /* margin: auto; */
    text-align: center;
    justify-content: center;
    display: flex;
  }

  .connectppl-popup-user-details-text-email-grid {
    /* margin: auto; */
    text-align: center;
    justify-content: center;
    display: flex;
    margin-top: -23px !important;
  }

  .connect-ppl-pop-up-travel-details-cardmobile {
    height: auto;
    text-align: center;
    background-color: rgba(30, 194, 139, 50%) !important;
    padding-bottom: 10px;
  }

  .connect-ppl-pop-up-travel-details {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  .desktop-view-transit-part {
    display: none;
  }
  .mobile-view-transit-part {
    display: block;
  }
  .whatsapp-icon-coonected-List{
    padding-top: 0px;
    color: #1ec28b;
    font-size: 16px;
    }

    .mobile-icon-connected-list{
      color: #1ec28b;
      font-size: 13px;
      }
      .email-icon-connected-list{
        color: #1ec28b;
        font-size: 13px;
        }
}
