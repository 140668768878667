.dbsql-profile-main {
    display: flex;
  }
  .admindashboard-heading {
    font-size: 25px;
    justify-content: start;
    display: flex;
    font-weight: 500 !important;
  }
  .ads-management-subhead {
    margin-bottom: 30px;
  }
  .ads-management-subheading {
    color: #1ec28b;
  }
  .ads-manage-home{
    color: gray;
    text-decoration: none;
  }