.first_con_s4{
    display: flex;
    align-items: center;
    /* padding: 1rem; */
    /* background-color: aqua; */
    /* flex-direction: row !important; */
}

.main_con_s4{
    /* background-color: #bbefde; */
    border-radius: 0.5rem;
    background-color: #d9f8ed;
    border-bottom:4px solid #1ec28b;
  padding: 1rem;            /*---------------------------------------------------------> 2
    width: 50%;
    /* margin:1rem; */
}

._border_s4{
    border: 2px solid #ff8d8d;
    border-radius: 1rem;
    padding: 1rem;    /*---------------------------------------------------------> 2 */
    width: 70%;        /*---------------------------------------------------------> 2 */
    /* background-color: #bbefde; */
}

._border_s4_con{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
     padding: 1rem;      /*---------------------------------------------------------> 2
    /* background-color: #bbefde; */
}

.text_style_full span{
    font-size: 1.3rem !important;    /*---------------------------------------------------------> 1 */
    font-weight:400;

    /* word-wrap: break-word;
    align-items: right; */
    /* background-color: #ff8d8d; */
}
.data-fulldetail{
    /* background-color: #ff8d8d; */
    font-size: 1.3rem !important;    /*---------------------------------------------------------> 1 */
    font-weight:400;
    word-break: break-all;
    text-align: justify;
    /* cursor:copy; */
}
.tansit_con_s4{
  display: flex;
  /* background-color: #ff8d8d; */
  width: 90%;         /*---------------------------------------------------------> 3 */
}

.transit_content_s4{
    display: flex;
    margin-bottom: 1rem;
    width: 50%;
}

.s4_heading{
    font-size: 1.3rem !important;
    margin-bottom: 1rem !important;
}


/*notes...

_border_s4_con---->_border_s4--->
main_con_s4(lightPink)

notes end.....*/


@media screen and (max-width: 415px) {
    .main_con_s4{
      padding: 0.3rem;            /*---------------------------------------------------------> 2*/
        width: 50%;
        /* margin:1rem; */
    }

    ._border_s4{
        margin-top: 1rem;
        padding: 0.5rem;    /*---------------------------------------------------------> 2 */
        width: 96%;        /*---------------------------------------------------------> 2 */
        /* background-color: #bbefde; */
    }

    .text_style_full span{
        font-size: 0.8rem !important;    /*---------------------------------------------------------> 1 */
    }

    ._border_s4_con{
         padding: 0.3rem;      /*---------------------------------------------------------> 2 */
        /* background-color: #bbefde; */
    }

    .tansit_con_s4{
        display: flex;
        flex-direction: column;
        width: 100%;         /*---------------------------------------------------------> 3 */
      }

      .transit_content_s4{
        width: 100%;
    }

    .line_s4{
        width: 80%;
        opacity: 0.3;
        border: none;
        border-bottom: solid 2px #1ec28b;
        margin-bottom: 1rem;
    }

    .s4_heading{
        font-size: 1rem !important;
    }
    
    
  }

  @media screen and (max-width: 361px) {

    .text_style_full span{
        font-size: 0.8rem !important;    /*---------------------------------------------------------> 1 */
    }
    
  }

 
  