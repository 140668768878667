.desicom-studentMainHeading {
  display: flex;
  font-size: 40px;
  font-family: sans-serif !important;
  font-weight: 700;
}
.desicom-hrStudent {
  border: 1px solid rgb(30, 194, 139) !important;
}

.desicom-hrDiv {
  width: 30%;
  margin-top: -37px;
}
.desicom-makeMoneyText {
  width: 100%;
  font-size: 29px;
  display: flex;
  text-align: initial;
  line-height: 1.5;
  font-family: sans-serif;
  margin-top: 10px;
}
.desicom-homeheading {
  text-align: initial;
}

.desicom-globeFlightImage {
  width: 66%;
  height: 400px;
  display: flex;
  margin: auto;
}

.desicom-itServiceText {
  width: 100%;
  display: flex;
  text-align: initial;
  line-height: 2;
  font-size: 28px;
}
.desicom-ruReadyText {
  width: 100%;
}
.desicom-addYourTrip {
  text-align: initial;
  width: 100%;
  font-weight: 600;
  font-size: 39px;
  line-height: 1.5;
  margin-top: 25px;
}
.desicom-createBtn {
  display: flex;
  border: 1px solid #ff8d8d;
  color: white;
  background: #ff8d8d;
  width: 30%;
  height: 60px;
  margin-top: 40px;
  align-items: center;
  border-radius: 4px;
  font-size: 23px;
  font-weight: 600;
  cursor: pointer;
}
.desicom-ArrowCircleRightRoundedIcon {
  font-size: 68px !important;
  display: flex;
  margin: auto;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .desicom-studentMainHeading {
    display: flex;
    font-size: 14px;
    font-family: sans-serif !important;
    font-weight: 700;
  }

  .desicom-hrStudent {
    border: 1px solid rgb(30, 194, 139) !important;
  }

  .desicom-hrDiv {
    width: 45%;
    margin-top: -28px;
  }
  .desicom-makeMoneyText {
    width: 100%;
    font-size: 17px;
    display: flex;
    text-align: initial;
    line-height: 1.5;
    font-family: sans-serif;
    margin-top: -17px;
  }
  .desicom-homeheading {
    text-align: initial;
  }
  
  .desicom-globeFlightImage {
    width: 100%;
  }
  .desicom-itServiceText {
    width: 100%;
    display: flex;
    text-align: initial;
    line-height: 2;
    font-size: 17px;
}

.desicom-ruReadyText {
  width: 100%;
}
.desicom-addYourTrip {
  text-align: initial;
  width: 100%;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.5;
}
.desicom-createBtn {
  display: flex;
    border: 1px solid #ff8d8d;
    color: white;
    background: #ff8d8d;
    width: 72%;
    height: 39px;
    margin-top: 20px;
    align-items: center;
    border-radius: 4px;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 25px;
    cursor: pointer;
}
.desicom-ArrowCircleRightRoundedIcon {
  font-size: 68px !important;
  display: flex;
  margin: auto;
}
}