.notify-dashboard-main {
  display: flex;
}

.notify-formob {
  display: contents;
}
.notifyedListBox {
  background-color: rgb(30 194 139 / 10%) !important;
  height: auto;
  width: 700px;
  margin: auto;
  margin-top: -25px;
  border-radius: 20px;
  margin-bottom: 25px;
  margin-left: 15px;
  margin-right: 15px;
}

.notifyedListText {
  text-align: start;
  margin-left: 35px;
  padding-top: 34px;
  font-weight: 600;
  font-family: sans-serif;
  font-size: 25px;
}

.notifyedListsearchbtn {
  border: 2px solid #1ec28b;
  width: 88%;
  border-radius: 5px;
  height: 50px;
  margin-top: 25px;
  /* margin-left: -94px; */
}

.notifyedListsearchbtnicon {
  margin-left: -40px !important;
  color: #1ec28b !important;
}

.notifyedListmessageContainer-main {
  display: flex !important;
  flex-direction: column !important;
  margin-top: 18px !important;
  /* margin-bottom: -80px; */

}

.notifyedListmessageGrid {
  margin-top: 10px;
}

.notifyedList-messageMainbox {
  line-height: 1;
  background-color: white;
  width: 80%;
  margin: auto;
  height: auto;
  border-radius: 10px;
  border: 1.5px solid #1ec28b !important;
  padding-top: 4px;
  margin-bottom: 15px;
}

.notifyedListmessagecontainer {
  height: 5px;
  width: 5px;
  margin-left: 18px;
  margin-top: 2px;
}

.notifyedListmessageinner {
  height: 62px;
  width: 65px;
  background: white;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-top: 1%;
  border: 1.5px solid #1ec28b !important;
}

.notifyedListmessageImg {
  height: 52px;
  margin-left: 0px;
  padding-top: 5px;
  padding-left: 0px;
  border-radius: inherit;
  width: 55px;
}

.notifyedListmessageThara {
  font-family: sans-serif !important;
  font-size: 19px !important;
  font-weight: 600 !important;
  text-align: initial;
}

.notifyedListTharaText {
  font: menu !important;
  text-align: initial;
  padding-top: 10px;
  margin-bottom: 20px !important;
}

.notificationLoadMore {
  height: 31px;
  color: white !important;
  display: flex !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  background: #ff8d8d !important;
  width: 24%;
  margin: auto !important;
  font-size: 11px !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .notify-dashboard-main {
    display: block;
  }
  .notification-sidebar-links-main {
    display: none;
  }
  .notify-formob {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .notify-invitation-myprofile-main {
    display: block;
  }
  .notifyedListBox {
    background-color: rgb(30 194 139 / 10%) !important;
    height: auto;
    width: 100%;
    margin: auto;
    margin-top: -25px;
    border-radius: 20px;
    margin-bottom: 25px;
  }

  .notifyedListProfile {
    background-color: #1ec28b !important;
    color: white !important;
    width: 77px;
    margin-top: 18px !important;
    border-radius: 7px !important;
    height: 25px;
  }
  .notifyedList-messageMainbox {
    line-height: 1;
    background-color: white;
    width: 95%;
    margin: auto;
    /* height: 75px; */
    height: auto;
    border-radius: 10px;
    border: 1.5px solid #1ec28b !important;
    padding-top: 4px;
    margin-bottom: 15px;
  }
  .notificationLoadMore {
    height: 31px;
    color: white !important;
    display: flex !important;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
    background: #ff8d8d !important;
    width: 41%;
    margin: auto !important;
    font-size: 8px !important;
}
.notifyedListmessagecontainer {
  height: 5px;
  width: 5px;
  margin-left: 18px;
  margin-top: 6px;
  align-items: center;
  display: grid;
}
.notifyedListmessageinner {
  height: 49px;
  width: 52px;
  background: white;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-top: 0%;
  border: 1.5px solid #1ec28b !important;
}
.notifyedListmessageImg {
  height: 40px;
  margin-left: 0px;
  padding-top: 5px;
  padding-left: 0px;
  border-radius: inherit;
  width: 46px;
}
.notifyedListTharaText {
  font: menu !important;
  text-align: initial;
  padding-top: 3px;
  margin-bottom: 14px !important;
}
}
