.how-it-works-flow-header-text {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: initial;
  font-size: 30px;
  font-weight: 700;
}
.backTohome-howitworks {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 92%;
  margin-top: 2rem;
  padding: 1rem;
}

.how-it-works-flow-Card {
  border: 1px solid #2e483f;
  border-radius: 20px;
  height: auto;
}
.how-it-works-flow-desitc-labeltext {
  text-align: initial;
  font-weight: 700 !important;
  font-size: 25px;
  margin-top: 20px;
}
.how-it-works-flow-desitc-subtext {
  text-align: initial;
  font-size: 25px;
  line-height: 1.5;
  width: 84%;
}
.how-it-works-grid-container-img {
  margin-left: -24px;
  display: flex;
  width: 70%;
  justify-content: start;
}
.how-it-works-grid-container-img-mobile {
  display: none;
}
.how-it-works-desi-travel-img {
  width: 100% !important;
  margin-bottom: 20px;
  margin-top: 15px;
}
.how-it-works-sign-login {
  background: #2e483f;
  height: 70px;
}

.how-it-workscontainer {
  height: 5px;
  width: 5px;
  padding-top: 10px;
}

.how-it-worksinner {
  height: 50px;
  width: 50px;
  background: #1ec28b;
  color: white;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  justify-content: center;
  display: flex;
}
.how-it-works-number {
  padding-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
}
.how-it-works-signcontainer {
  column-gap: 75px;
  align-items: baseline;
  display: flex !important;
}
.how-it-works-signText {
  text-align: initial;
  align-items: center;
  display: flex;
  font-size: 25px;
  color: white;
}
.how-it-works-homepagecompanionText {
  text-align: initial;
  align-items: center;
  display: flex;
  font-size: 25px;
  color: white;
}

.how-it-works-sign up {
  text-align: initial !important;
  font-size: 25px;
  margin-top: 20px;
  font-weight: 500 !important;
}
.how-it-works-content {
  font-weight: 300;
  text-align: initial;
  font-size: 25px;
  line-height: 1.5;
  width: 80%;
}
.how-it-works-grid {
  justify-content: start;
  display: flex;
}
.how-it-works-sign up-img {
  width: 70% !important;
  margin-bottom: 50px;
  margin-top: 15px;
}
.how-it-works-signup-register {
  border: 1px solid #2e483f;
  border-radius: 20px;
  height: auto;
}
.how-it-works-login-img {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
  margin-left: -117px;
}
.how-it-works-flow-signup {
  display: flex;
  text-align: initial !important;
  font-size: 25px;
  margin-top: 20px;
  justify-content: start;
  font-weight: 700 !important;
}
.how-it-works-flow-signup-subtext {
  text-align: initial;
  font-weight: 400 !important;
  font-size: 25px;
  line-height: 1.5;
  width: 76%;
}
.how-it-works-login {
  font-weight: 700 !important;
  text-align: initial;
  font-size: 25px;
  margin-top: 20px;
}
.how-it-works-login-content {
  text-align: initial;
  font-weight: 400 !important;
  font-size: 25px;
  line-height: 1.5;
  width: 85%;
}
.how-it-works-para {
  font-weight: 400 !important;
  text-align: initial;
  font-size: 25px;
  line-height: 1.5;
  width: 92%;
}
.how-it-works-desi-travel-img2 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
}
.how-it-works-desi-travel-img4 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
}
.how-it-works-desi-travel-img5 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
  margin-left: 70px;
}
.how-it-works-desi-travel-img6 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
  margin-left: -18px;
}
.how-it-works-desi-travel-block {
  display: flex;
  /* float: right; */
  width: 70%;
}
.how-it-works-desi-travel-img7a {
  width: 70%;
  margin-bottom: 20px;
  margin-top: 15px;
}
.how-it-works-desi-travel-img7b {
  width: 70%;
  margin-bottom: 20px;
  /* margin-left: -30px; */
  margin-top: 15px;
  /* float: left; */
  /* display: flex; */
}
.how-it-works-login-upcoming {
  font-weight: 700 !important;
  text-align: initial;
  font-size: 25px;
  margin-top: 20px;
}
.how-it-works-login-upcoming-content {
  font-weight: 400 !important;
  text-align: initial;
  font-size: 27px;
  line-height: 1.5;
  width: 95%;
}
.how-it-works-login-past-trips {
  font-weight: 700 !important;
  text-align: initial;
  font-size: 25px;
  margin-top: 20px;
}
.how-it-works-login-past-trips-content {
  font-weight: 400 !important;
  text-align: initial;
  font-size: 27px;
  line-height: 1.5;
  width: 95%;
}
.how-it-works-sentlist {
  font-weight: 700 !important;
  text-align: initial;
  font-size: 25px;
  margin-top: 20px;
}
.how-it-works-sentlist-content {
  font-weight: 400 !important;
  text-align: initial;
  font-size: 27px;
  line-height: 1.5;
  width: 95%;
}
.how-it-works-desi-travel-img8 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
}
.how-it-works-receivedlist {
  font-weight: 700 !important;
  text-align: initial;
  font-size: 25px;
  margin-top: 20px;
}
.how-it-works-receivedlist-content {
  font-weight: 400 !important;
  text-align: initial;
  font-size: 25px;
  line-height: 1.5;
  width: 76%;
}
.how-it-works-desi-travel-img9 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
  margin-left: 70px;
}
.how-it-works-connectedlist {
  font-weight: 700 !important;
  text-align: initial;
  font-size: 25px;
  margin-top: 20px;
}
.how-it-works-connectedlist-content {
  font-weight: 400 !important;
  text-align: initial;
  font-size: 25px;
  line-height: 1.5;
  width: 76%;
}
.how-it-works-desi-travel-img10 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
  margin-left: -117px;
}
.how-it-works-search-content {
  font-weight: 400 !important;
  text-align: initial;
  font-size: 25px;
  line-height: 1.5;
  width: 76%;
}
.how-it-works-desi-travel-img11 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
}
.how-it-works-notification-content {
  font-weight: 400 !important;
  text-align: initial;
  font-size: 25px;
  line-height: 1.5;
  width: 76%;
}
.how-it-works-desi-travel-img12 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
}
.how-it-works-logout-content {
  text-align: initial;
  font-weight: 400 !important;
  font-size: 25px;
  line-height: 1.5;
  width: 76%;
}
.how-it-works-desi-travel-img13 {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .how-it-works-grid-container-img {
    justify-content: end;
    display: none;
  }
  .how-it-works-grid-container-img-mobile {
    display: block;
  }

  .how-it-works-flow-header-text {
    margin-top: 36px;
    margin-bottom: 30px;
    text-align: initial;
    font-size: 24px;
    font-weight: 700;
  }

  .how-it-works-flow-Card {
    width: 100% !important;
    border: 1px solid #2e483f;
    border-radius: 20px;
    height: auto;
  }
  .how-it-works-flow-desitc-labeltext {
    text-align: initial;
    font-weight: 700;
    font-size: 15px;
    margin-top: 20px;
  }
  .how-it-works-flow-desitc-subtext {
    font-weight: 500;
    text-align: initial;
    font-size: 13px;
    line-height: 1.5;
    width: 100%;
    word-spacing: 0px;
  }

  .how-it-works-sign-login {
    background: #2e483f;
    height: 50px;
  }

  .how-it-workscontainer {
    height: 5px;
    width: 5px;
    padding-top: 10px;
  }

  .how-it-worksinner {
    height: 30px;
    width: 30px;
    background: #1ec28b;
    color: white;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    justify-content: center;
    display: flex;
  }
  .how-it-works-number {
    padding-top: 5px;
    font-size: 19px;
    font-weight: 600;
  }
  .how-it-works-signcontainer {
    column-gap: 40px;
    align-items: baseline;
    display: flex !important;
  }
  .how-it-works-signText {
    text-align: initial;
    align-items: center;
    display: flex;
    font-size: 15px;
    color: white;
  }
  .how-it-works-homepagecompanionText {
    text-align: initial;
    align-items: center;
    display: flex;
    font-size: 13px;
    color: white;
  }
  .how-it-works-flow-signup {
    display: flex;
    text-align: initial;
    font-size: 14px;
    margin-top: 20px;
    font-weight: 700 !important;
  }
  .how-it-works-flow-signup-subtext {
    word-spacing: 0px;
    text-align: initial;
    font-weight: 500 !important;
    font-size: 13px;
    line-height: 1.5;
    width: 100%;
  }
  .how-it-works-login-img {
    justify-content: end;
    display: block;
    width: 70%;
    margin-bottom: 20px;
    margin-top: 15px;
    /* margin-left: -117px; */
  }
  .how-it-works-para {
    font-weight: 500 !important;
    text-align: initial;
    font-size: 13px;
    line-height: 1.5;
    width: 100%;
    word-spacing: 0px;
  }
  .how-it-works-desi-travel-img-mv1 {
    width: 100%;
    margin-bottom: 13px;
    margin-top: 15px;
  }
  .how-it-works-desi-travel-img-mv2 {
    width: 100%;
    margin-bottom: 10px;
    margin-top: -22px;
  }
  .how-it-works-login-img-mv3 {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .how-it-works-desi-travel-img-mv4 {
    width: 100%;
    margin-top: 15px;
  }
  .how-it-works-desi-travel-img-mv5 {
    width: 100%;
    margin-top: 12px;
  }
  .how-it-works-desi-travel-img-mv6 {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 7px;
  }
  .how-it-works-desi-travel-img-mv7 {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .how-it-works-desi-travel-img-mv8 {
    width: 100%;
    margin-top: 60px;
  }
  .how-it-works-desi-travel-img-mv9 {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 5px;
  }
  .how-it-works-desi-travel-img-mv10 {
    width: 100%;
    margin-top: 65px;
  }
  .how-it-works-desi-travel-img-mv11 {
    width: 80%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .how-it-works-desi-travel-img-mv12 {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .how-it-works-desi-travel-img-mv13 {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .how-it-works-login {
    font-weight: 700 !important;
    text-align: initial;
    font-size: 15px;
    margin-top: 6px;
  }
  .how-it-works-login-content {
    word-spacing: 0px;
    text-align: initial;
    font-weight: 500 !important;
    font-size: 13px;
    line-height: 1.5;
    width: 100%;
  }
  .how-it-works-login-upcoming {
    font-weight: 700 !important;
    text-align: initial;
    font-size: 13px;
    margin-top: 20px;
  }
  .how-it-works-login-upcoming-content {
    text-align: initial;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 1.5;
    word-spacing: 1px;
    width: 100%;
  }
  .how-it-works-login-past-trips {
    font-weight: 700 !important;
    text-align: initial;
    font-size: 13px;
    margin-top: 20px;
  }
  .how-it-works-login-past-trips-content {
    text-align: initial;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 1.5;
    word-spacing: 0px;
    width: 100%;
  }
  .how-it-works-sentlist {
    font-weight: 500 !important;
    text-align: initial;
    font-size: 20px;
    margin-top: 20px;
  }
  .how-it-works-sentlist-content {
    font-weight: 500 !important;
    text-align: initial;
    font-size: 14px;
    line-height: 1.5;
    word-spacing: 0px;
    width: 100%;
  }
  .how-it-works-receivedlist {
    font-weight: 500 !important;
    text-align: initial;
    font-size: 20px;
    margin-top: 20px;
  }
  .how-it-works-receivedlist-content {
    font-weight: 500 !important;
    text-align: initial;
    font-size: 14px;
    line-height: 1.5;
    word-spacing: 0px;
    width: 100%;
  }

  .how-it-works-connectedlist {
    font-weight: 700 !important;
    text-align: initial;
    font-size: 18px;
    margin-top: 20px;
  }
  .how-it-works-connectedlist-content {
    font-weight: 500 !important;
    text-align: initial;
    font-size: 14px;
    line-height: 1.5;
    word-spacing: 0px;
    width: 100%;
  }
  .how-it-works-search-content {
    font-weight: 500 !important;
    text-align: initial;
    font-size: 14px;
    line-height: 1.5;
    word-spacing: 0px;
    width: 100%;
  }
  .how-it-works-notification-content {
    font-weight: 500 !important;
    text-align: initial;
    font-size: 14px;
    line-height: 1.5;
    word-spacing: 0px;
    width: 100%;
  }
  .how-it-works-logout-content {
    font-weight: 500 !important;
    text-align: initial;
    font-size: 14px;
    line-height: 1.5;
    word-spacing: 0px;
    width: 100%;
  }
}