.studentpro-studentMainHeading {
  display: flex;
  font-size: 40px;
  font-family: sans-serif !important;
  font-weight: 700;
}
.studentpro-hrStudent {
  border: 1px solid rgb(30, 194, 139) !important;
}

.studentpro-hrDiv {
  width: 30%;
  margin-top: -37px;
}
.studentpro-makeMoneyText {
  width: 100%;
  font-size: 33px;
  display: flex;
  text-align: initial;
  line-height: 2;
  font-family: sans-serif;
  margin-top: 10px;
}
.studentpro-homeheading {
  text-align: initial;
}
.studentpro-fortImage {
  width: 100%;
}
.studentpro-globeFlightImage {
  width: 100%;
}
.studentpro-itTechImage {
  /* background-image: url('../../src/StudentProfile/Infodrive_ad.png'); */
  margin-top: 40px;
  width: 100%;
  
}
.studentpro-itServiceText {
  width: 100%;
  display: flex;
  text-align: initial;
  line-height: 2;
  font-size: 28px;
}
.studentpro-itServiceSecondText {
  width: 100%;
  display: flex;
  text-align: initial;
  line-height: 2;
  font-size: 28px;
}
.studentpro-ruReadyText {
  width: 100%;
}
.studentpro-addYourTrip {
  text-align: initial;
  width: 100%;
  font-weight: 600;
  font-size: 39px;
  line-height: 1.5;
  margin-top: 25px;
}
.studentpro-createBtn {
  display: flex;
  border: 1px solid #ff8d8d;
  color: white;
  background: #ff8d8d;
  width: 30%;
  height: 60px;
  margin-top: 40px;
  align-items: center;
  border-radius: 4px;
  font-size: 23px;
  font-weight: 600;
  cursor: pointer;
}
.studentpro-ArrowCircleRightRoundedIcon {
  font-size: 68px !important;
  display: flex;
  margin: auto;
}
.student-ads-image-desktopversion {
  display: block;
}

.student-ads-image-Mobileversion{
  display: none !important; 
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .studentpro-studentMainHeading {
    display: flex;
    font-size: 14px;
    font-family: sans-serif !important;
    font-weight: 700;
  }

  .studentpro-hrStudent {
    border: 1px solid rgb(30, 194, 139) !important;
  }

  .studentpro-hrDiv {
    width: 48%;
    margin-top: -42px;
    margin-left: 5px;
  }
  .studentpro-makeMoneyText {
    width: 100%;
    font-size: 17px;
    display: flex;
    text-align: initial;
    line-height: 1.5;
    font-family: sans-serif;
    margin-top: -17px;
  }
  .studentpro-homeheading {
    text-align: initial;
  }
  .studentpro-fortImage {
    width: 100%;
  }
  .studentpro-globeFlightImage {
    width: 100%;
  }
  .studentpro-itServiceText {
    width: 100%;
    display: flex;
    text-align: initial;
    line-height: 2;
    font-size: 17px;
}
.studentpro-itServiceSecondText {
  width: 100%;
  display: flex;
  text-align: initial;
  line-height: 2;
  font-size: 17px;
}
.studentpro-ruReadyText {
  width: 100%;
}
.studentpro-addYourTrip {
  text-align: initial;
  width: 100%;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.5;
}
.studentpro-createBtn {
  display: flex;
  border: 1px solid #ff8d8d;
  color: white;
  background: #ff8d8d;
  width: 72%;
  height: 39px;
  margin-top: 20px;
  align-items: center;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 25px;
  cursor: pointer;
}
.studentpro-ArrowCircleRightRoundedIcon {
  font-size: 68px !important;
  display: flex;
  margin: auto;
}
.studentpro-itTechImage {
  /* background-image: url('../../src/StudentProfile/Infodrive_ad.png'); */
  margin-top: 40px;
  width: 100%;
  margin-left: 10px;
}
.student-ads-image-desktopversion {
  display: none !important;
}

.student-ads-image-Mobileversion{
  display: block !important;
}
}
@media screen and (min-width: 360px) and (max-width: 480px) {

  .studentpro-hrDiv {
    
    width: 40%;
    margin-top: -25px;
    margin-left: 2px;

  }
}