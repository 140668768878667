.registeredusers-heading {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 30px;
  display: flex;
  justify-content: start;
}
.registeredusers-head {
  color: #1ec28b;
  font-weight: 600 !important;
}
.registeredusers-box {
  width: 100%;
    background-color: white;
    height: 100%;
}
.registeredusers-subhead {
  font-size: 20px;
  text-align: justify;
  margin-top: 20px;
  height: 100%;
  width: 100%;
  font-weight: 600;
  padding-left: 47px;
  padding-top: 30px;
}
.registeredusers-card1 {
  background-color: #d2f2e7 !important;
  width: 85%;
  height: 100%;
  margin-left: 48px;
  margin-top: 20px;
}
.registeredusers-main-heads {
  display: flex;
  height: 100%;
  width: 100%;
}

.registeredusers-textfield-one {
  background-color: white;
  margin-left: 75% !important;
  border-radius: 3px !important;
  width: 33% !important;
  margin-top: -40px !important;
  color: #1ec28b;
  font-weight: 600;
  border-radius: 3px !important;
  height: 32px;
  border-color: #1ec28b;
  margin-top: -10px !important;
}
.registeredusers-card2 {
  height: 100px;
  background-color: white !important;
  color: #595959 !important;
  margin-top: 20px;
  padding-top: 30px;
  width: 45%;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
  border-radius: 10px !important;
  font-family: sans-serif;
}
.registeredusers-count {
  font-weight: bolder;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: xx-large;
  margin-bottom: 20px;
}
.registeredusers-card3 {
    background-color: #ffffff !important;
    width: 60%;
    border-radius: 5px !important;
    margin-bottom: 20px;
    /* height: 275px; */
}
.registeredusers-card-three{
    width: 100%;
    border-radius: 5px !important;
    margin-bottom: 20px; 
    display: flex;
    justify-content: center;
}
.registeredusers-places {
  display: flex;
  margin-bottom: 1rem;
}
.registeredusers-all-countries {
  font-size: medium;
  font-weight: 600;
  color: #1ec28b;
  margin-top: 20px;
  margin-left: -9px;
}
.registeredusers-second-head {
  margin-top: 30px;
  margin-right: 57%;
  font-weight: 600;
  font-size: large;
}
.registeredusers-textfield-two {
  margin-left: 70% !important;
  width: 22% !important;
  margin-top: -20px !important;
}
.registeredusers-bargraph-card {
  margin-top: 35px;
  border: 3px solid lightgray;
  width: 189%;
  margin-left: 14%;
  height: 100%;
}
.registeredusers-custom-date-picker {
  margin-top: 15%;
  padding-right: 69%;
}
.registeredusers-date-picker {
  margin-top: 10% !important;
  padding-left: 35%;
  width: 132%;
  margin-left: -372%;
  background-color: whitesmoke;
  border: none;
}
.registeredusers-date-picker-two {
  width: 224% !important;
  padding-left: 42%;
  margin-top: 16%;
  background-color: whitesmoke;
  margin-left: 10%;
  border: none;
}
.anticon svg {
  margin-left: -60px;
}
.ant-picker .ant-picker-input {
  font-size: inherit;
}
.registeredusers-option {
  margin-top: -38% !important;
  margin-left: -133%;
}
.registeredusers-datepicker-name {
  margin-top: -65%;
  margin-right: -15%;
  /* background-color: whitesmoke; */
}
.registeredusers-option-name {
  margin-top: 10%;
}
.registeredusers-showresults-btn {
  background: #1ec28b !important;
  color: white !important;
  width: 118%;
  height: 30px;
  margin-top: -40% !important;
  margin-left: -152% !important;
  font-size: smaller !important;
  font-weight: 600 !important;
}
.registeredusers-bargraph {
  margin-top: 20%;
  margin-left: 20%;
}
.foreignObject {
  border-radius: 3px solid whitesmoke;
}
.css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
  font-weight: 100;
  color: black;
}
.registeredusers-totals {
  font-weight: 900;
  font-size: x-large;
  margin-bottom: -33px;
  padding-left: 21px;
}
.registeredusers-totals-one {
  font-weight: 600;
    font-size: x-large;
    color: black;
    /* margin-top: -5px; */
    /* margin-left: 20%; */
  }
.registeredusers-users {
  display: flex;
    /* padding-left: 141px; */
    margin-top: -20px;
    color: black;
    font-weight: 100;
}
.registeredusers-users-one {
  display: flex;
  padding-left: 2px;
  margin-top: 55px;
}
.registeredusers-all-countries-heading {
  display: flex;
  justify-content: center;
  background-color: bisque;
}
.registeredusers-all-countries-headings{
  margin-top: 10px;
  color: black;
  font-size: x-large;
  font-weight: 600;
  margin-left: -325px;
}
.registeredusers-dropdown {
  margin-top: -30px !important;
}
.registeredusers-dropdown-one {
  margin-left: 283px;
  width: 50%;
  margin-top: -3%;
}
.registeredusers-countrynames-lists {
  /* padding-left: 45%; */
    font-size: x-large;
    font-weight: 600;
    margin-top: -20px;
    color: black;
    height: 15px;
}
.registeredusers-select-head {
    height: 38px;
    border-radius: 4px;
    border-color: #1ec28b;
    color: #1ec28b;
    font-weight: 600;
}
.registeredusers-select-card {
  margin-top: 15%;
}
.registeredusers-select-card-one {
  margin-top: 0;
}
.registeredusers-topcountriestotals-one {
  margin-top: 2px;
  /* display: flex; */
  margin-left: 25px;
  font-weight: 600;
  font-size: xx-large;
  color: black;
}
.registeredusers-topcountries-users {
  margin-top: 14px;
  font-size: small;
  margin-left: 15px;
}
.registeredusers-topcountries-names {
  margin-top: 2px;
  margin-top: 2px;
    font-size: 19px;
    font-weight: bolder;
}
.registeredusers-topcountries-cardone {
  width: 136%;
  height: 108px;
  margin-left: -227%;
  color: black;
}
.registeredusers-countrynames-lists-card {
  margin-top: 10px;
  font-size: small;
  font-weight: 600;
  margin-left: 12px;
}


.registeredusers-leastcountries{
  /* overflow-y: hidden; */
  display: flex;
  margin-left: 121px
}
.registeredusers-topcountries{
  /* overflow-y: hidden; */
  display: flex;
  margin-left: 251px;
  width: 50%;
  margin-top: -294px;
}
.registeredusers-regcountries{
  /* overflow-y: scroll; */
  height: 267px;
}
.ant-picker .ant-picker-input >input:placeholder-shown {
  font-size: small;
    height: 15px;
    color:black !important;
    text-align: center;
}
.registeredusers-option-heading{
  font-size: medium;
  color: #1ec28b;
  margin-top: 15px;
  font-weight: bold;
}
.All-countryCount{
  font-size: 24px;
  margin-right: 2rem;
  font-weight: bold;
  width: 10%;
}
.registeredusers-places-last{
  width: 50%;
  margin-top: 50%;
}
.registeredusers-topcountriestotals-one-last{
  width: 50%;
}
.registeredusers-topcountries-cardone-last{
  width: 116px;
  height: 110px;
  background-color: whitesmoke !important;
}
.registeredusers-topcountries-last{
  display: flex;
  width: 200px;
  height: 185px;
  margin-left: 135px;
  margin-top: -23px;
}
.registeredusers-topcountries-least{
  margin-top: -164px;
  display: flex;
  margin-left: 250px;
}
.registeredusers-places-reg{
  margin-top: 20px !important;
  margin-left: 40px !important;
  margin-bottom: 20px !important;
}
.registeredusers-totals-one-reg{
  margin-top: 1px;
}
.registeredusers-users-reg{
  margin-top: 1px;
}
.registeredusers-countrynames-lists-reg{
  margin-top: 1px;
}
.registeredusers-names-reg{
  margin-top: 1px;
}
.registeredusers-topcountries-cards-num{
  margin-left: -147px;
  width: 17px;
  height: 17px;
  background-color: #1ec28b;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
}
.registeredusers-topcountries-cards-lastnumber{
  margin-left: -15px;
  width: 17px;
  height: 17px;
  background-color: #1ec28b;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: -6px;
}
.registeredusers-topcountries-cards-leastnumber{
  margin-left: -147px;
    width: 17px;
    height: 17px;
    background-color: #1ec28b;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    font-weight: 600 /* Make the text bold */
}
.underline {
  text-decoration: underline;
  text-decoration-color: transparent; /* Set to transparent to hide the underline */
  position: relative;
  display: inline-block;
}

.underline::after {
  content: "";
  position: absolute;
  bottom: -2px; /* Adjust the distance from the text as needed */
  left: 0;
  width: 530px; /* Adjust the width of the underline as needed */
  height: 1px; /* Adjust the thickness of the underline as needed */
  background-color: #1ec28b; /* Adjust the color of the underline */
  margin-left: -150px;
  margin-bottom: -18px;
}
.select option {
  background-color: white;
}
.custom-background {
  background-color: white;
}
.registeredusers-format{
  margin-top: 30%;
}
.number-1 {
  background-color: #1ec28b;
  color: white;
  margin-left: -173px;
  margin-bottom: -5%;
}

.number-2 {
  background-color: #1ec28b;
  color: white;
  margin-left: -177px;
  margin-bottom: -3px;
}

.number-3 {
  background-color: #1ec28b;
  color: white;
  margin-left: -147px;
  margin-bottom: -5px;
}
.number-4 {
  background-color: #1ec28b;
color: white;
}

.number-5 {
  background-color: #1ec28b;
  color: white;
}

.card-1 {
  background-color: whitesmoke !important;
  width: 104%;
  margin-left: -171%;
  height: 135px;
  margin-top: 3px
}

.card-2 {
  background-color: whitesmoke !important;
    margin-right: -26px;
    width: 151%;
    height: 134px;
    margin-top: 2px;
}

.card-3 {
  background-color: whitesmoke !important;
    margin-right: -26px;
    width: 162%;
    height: 132px;
    margin-top: 3px;
}
/* Unique class for alignment */
.align-number-1 {
background-color: #1ec28b;
margin-left: -175px;
    margin-bottom: -3px;
}

.align-number-2 {
background-color: #1ec28b;
margin-left: -154px;
    margin-bottom: -2px
}
.align-number-3 {
background-color: #1ec28b;
margin-left: -142px;
    margin-bottom: -2px
}

/* Add similar rules for other index values as needed */

.num-1 {
  background-color: #1ec28b;
  color: white;
  margin-left: -137px;
  margin-bottom: -3px;
}

.num-2 {
  /* CSS styles for the second element */
  color: blue;
}

.num-3 {
  /* CSS styles for the third element */
  color: green;
}
.Card-1 {
  /* CSS styles for the first card */
  background-color: whitesmoke !important;
}

.Card-2 {
  /* CSS styles for the second card */
  background-color: whitesmoke;
}
.Card-3 {
  background-color: whitesmoke;
}
.customcard-1 {
  background-color: whitesmoke !important;
  margin-left: -259%;
  height: 88%;
  margin-top: 2px;
}
.customcard-2 {
  background-color: whitesmoke !important;
    margin-left: -175%;
    width: 118%;
    height: 131px;
  }
  .customcard-3 {
    background-color: whitesmoke !important;
    margin-left: -141%;
    width: 93px;
    height: 130px;
    }

    .cardFields{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95% !important;
      padding: 20px;

    }

    .registeredusers-textfield {
      background-color: white;
      border-radius: 5px;
      color: white;
      width: 80% !important;
    }

    .searchField-registeredUsers-con{
      width: 70%;
      height: 37px;
      background-color: white;
      border:1.5px solid #c0c2c1;
      border-radius: 8px;
      position: relative;
      margin-left: 1rem;
    }
    .registeredUsers-search-cancel-icon{
      position: absolute;
      top: 30%;
      right: 3%;
      color: #949494;
    }
    .registeredUsers-search-cancel-icon:hover{
      color: black;
    }
   .searchField-registeredUsers{
    width: 80%;
    position: absolute;
    font-size: 16px;
    top: 0%;
    bottom: 0%;
    border: none;
    left: 10%;
    outline: none;
    }

    .registeredUsers-search-icon{
      position: absolute;
      top: 30%;
      left: 3%;
    }

    .registeredusers-search-place {
      width: 100% !important;
      display: flex;
      justify-content: start;
    }

    .registerCounts-Card{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .All-countries-list{
      display: flex;
    }

    .Users-in{
      width: 80%;
      font-size: 24px;
      color: #5a5a5a;
    }
    .Users-in-text{
      font-size: 14px;
    }

    .Top5-Grid{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width:100%;
    }
    .countbox-con{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      /* background-color: #1ec28b; */
    }
    .count-box{
      display: flex;
      flex-direction: column;
     align-items: center;
     justify-content: center;
     background-color: #f7f7f7;
     width: 6rem;
     height: 6rem;
     margin: 13px;
     /* padding: 3px; */
     border-radius: 2px;
    }

    .badge-register-user .ant-badge-count{
     font-weight: bold;
    }

    .Country-name-box{
      width: 80%;
      font-size: 20px;
      color: #5a5a5a;
    }

    .countbox-count{
      font-size: 26px;
      font-weight: bold;
    }
    .countbox-usersin{
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 3px;
    }

    .countbox-cName{
      color: #5f5f5f;
      font-size: 20px;
    }
    .Heading-Select{
      padding: 8px;
      border: none;
      border-bottom: 1.5px solid #7cdcbb;
    }

    .Country_data{
      display: flex;
      margin-left: 2rem;
      margin-bottom: 0.7rem;
    }

    .Country_data_card{
      padding:1rem 2rem 1rem 2rem;
      margin: 2rem;
    }

    .Country_data_heading{
      font-size: 24px;
      display: flex;
      justify-content: start;
      margin-bottom: 0.8rem;
      font-weight: bold;
    }

    .Country_data_key{
      width: 45%;
      display: flex;
      justify-content: start;
      font-size: 14px;
    }

    .Country_data_value{
      width: 30%;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }

    .squareBar{
      width: 63%;
      height: 320px;
      border: 1.5px solid #bfbfbf;
      position: absolute;
      left: 23%;
      bottom: 11.5%;
    }
    .bottom-label-reports{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 3%;
      left: 45%;
      font-weight: bolder;
    }

    .left-label-reports{
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-transform: rotate(-90deg);
      position: absolute;
      left: 5%;
      top: 54%;
      font-weight: bolder;
    }

    .tooltip_custom-register{
      background-color: white;
      box-shadow: rgba(150, 120, 120, 0.5) 0px 2px 8px 0px;
      padding: 0.2rem 0.5rem 0.2rem 0.5rem;
      border-radius: 6px;
      border: 1px solid #c7c7c7;
    }

    .gender_count-register{
      color: #949494;
    }  

      .users-text{
        color:#ababab !important;
        font-size: 10px;
      }

      .recharts-cartesian-axis-tick-value tspan{
           color: red !important;
      }
    

      .registeredusers-select-head {
padding-left: 12px !important;     
 }

 


