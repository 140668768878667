/* $white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2; */
.cursor_point {
 
  color:#FFF;
  /* font-family:Verdana, Geneva, sans-serif; */
  cursor:pointer;
}
.google-btn {
  width: 209px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 25%);
  margin-top: 23px;
}
.google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.google-icon {
  position: absolute;
  width: 38px;
  height: 40px;
  display: flex;
  margin: auto;
}
.btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn :active {
  background: #1669f2;
}

.login-or-text {
  margin-top: 18px;
  margin-bottom: 18px;
}

.login-google-sign {
  background: #4285f4 !important;
  color: white !important;
}

.mainLogin {
  background-color: aliceblue !important;
  height: 100% !important;
}
.loginsection {
  background-color: white;
  margin-top: 20px;
  border-radius: 50px;
  border-color: #1ec28b !important ;
  width: 100%;
  margin-bottom: 40px;
  height: 93%;
}
.tagLine {
  font-size: 40px;
  font-weight: 500;
  margin-top: 10px;
}
.emailMainGrid {
  width: 100%;
  margin-top: 30px !important;
}
.loginEmailError {
  margin-right: 360px;
  color: red;
}
.passwordDanger {
  margin-right: 200px;
  color: red;
}

.loginBtn {
  border: none;
  border-radius: 30px;
  margin-top: 20px;
  height: 50px;
  font-size: 20px;
  width: 180px;
  background-color: #1ec28b;
  color: #ffffff;
}
.notRegisterText {
  font-size: 18px;
  margin-top: 45px;
  margin-bottom: 15px;
}
.createText {
  color: #4f8df9;
  font-weight: 500px;
  margin-left: 3px;
}
.travelCompanionImg {
  width: 100%;
  margin-top: 70px;
}
.loginsecondimg {
  width: 100%;
  height: 50%;
}
.passwordMainGrid {
  margin-top: 30px !important;
}

.googlelogin {
  width: 13.5rem !important;
  height: 2.6rem !important;
  color: #004170 !important;
  text-decoration: solid;
  padding-left: 1rem !important;
}
#loginside {
  margin-left: 2.2rem;
}

.forget-clr:active {
  color: #4f8df9;
}

.hideshowPassword {
  margin-left: -58px;
  margin-bottom: -10px;
}

.login-socialIcons {
  justify-content: center;
}
.emailLabelpassword {
  display: flex;
  margin-top: 10px;
}
.emailLabelLogin {
  display: flex;
}
.emailLoginInput {
  width: 100% !important;
  height: 45px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
}
.passwordLoginInput {
  width: 100% !important;
  height: 45px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
}
.login-emailMainGrid {
  width: 80% !important;
  margin: auto !important;
}
.forgetPasswordText {
  margin-top: 20px;
  color: #4f8df9;
  display: flex;
  justify-content: end;
}

.login-signing-confrim {
  font: menu;
  font-size: small;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .tagLine {
    font-size: 30px;
    font-weight: 500;
    margin-top: 10px;
  }
  .login-socialIcons {
    justify-content: center;
  }
  .login-emailMainGrid {
    width: 90% !important;
    margin: auto !important;
  }
  .notRegisterText {
    font-size: 15px;
    margin-top: 45px;
    margin-bottom: 15px;
  }
}
