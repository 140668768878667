/* .modalBackground {
  width: 100vw;
  height: 60vh;
  background-color: rgba(226, 214, 214, 0.447);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 500px;
  height: 500px;
  margin-top: 260px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: -14px;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
} */
.pastTripPopup-modalBackground {
  width: 98vw;
  height: 41rem;
  background-color: rgba(226, 214, 214, 0.447);
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -359px;
  margin-top: -708px;
  z-index: 2;
  /* width: 98vw;
  height: 85rem;
  background-color: rgba(226, 214, 214, 0.447);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -580px;
  margin-top: -708px;
  z-index: 2; */
}

.pastTripPopup-modalContainer {
  width: 580px;
  /* height: 33%; */
  height: auto;
  /* margin-top: -360px; */
  border-radius: 12px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.pastTripPopup-modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.pastTripPopup-titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.pastTripPopup-titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 23px;
  color: pink;
  cursor: pointer;
  margin-top: -13px;
}

.pastTripPopup-modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}
.past-trip-popup-image-add-icon {
  cursor: pointer;
  color: #1ec28b;
  justify-content: center;
  margin-left: -12px;
  margin-top: 20px;
}
.past-trip-popup-add-photo {
  /* font-family: "Inter"; */
  font-weight: 600;
  margin-top: 45px;
  font-size: 18px;
}
.past-trip-popup-drag-drop {
  font-size: 10px;
}
.error-message{
  text-align: start;
}
.pastTripPopup-modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pastTripPopup-modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: rgb(32, 90, 197);
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#pastTripPopup-cancelBtn {
  background-color: crimson;
}

.pastTripPopupHeading {
  font-family: sans-serif;
  margin-bottom: 14px;
  margin-top: -48px;
  font-size: 17px;
  font-weight: 700;
  margin-left: 0px;
}

.pastTripPopuphrlines {
  background: #1ec28b;
  width: 100%;
  height: 1px;
  margin-top: 8px;
}

.pastTripPopupPageContainer {
  display: contents !important;
}

.pastTripPopupUpload {
  width: 100%;
  height: 125px !important;
  border-radius: 10px;
  border: 1px solid #1ec28b;
}

.pastTripPopupUploadinnerCard {
  width: 96%;
  height: 109px !important;
  border-radius: 10px;
  margin-top: 7px;
  margin-left: 10px;
  /* border: 1px solid #1ec28b; */
  /* background: rgb(30 194 139 / 60%) !important; */
  background: rgba(30, 194, 139, 0.1) !important;
}

.pastTripPopupUpcontainer {
  height: 5px;
  width: 5px;
  /* margin-left: 220px; */
  margin: 0 auto !important;
  /* margin-top: 2px; */
}

.pastTripPopupinner {
  height: 80px;
  width: 80px;
  background: white;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-left: 210px;
  /* margin-top: -35px; */
  border: 8px solid grey !important;
}

.pastTripPopupIcon {
  margin-left: 43% !important;
  margin-top: 10px !important;
}

.pastTripPopupText {
  /* text-align: center; */
  font: icon;
  font-weight: 600;
  font-size: 19px;
  color: black;
  margin-left: 37%;
  width: 110px;
}

.pastTripPopupSubText {
  text-align: center;
  font: small-caption;
  font-size: 10px;
}

.pastTripPopupdescription {
  width: 100%;
  height: 50px !important;
  border-radius: 10px;
  margin-top: 15px;
  /* margin-left: -22px; */
  border: 1px solid #1ec28b;
  font-size: 15px;
  /* padding-left: 20px; */
  /* padding-top: 8px; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.pastTripPopupCaptchaMain {
  justify-content: space-evenly;
}

.pastTripPopupCaptchaText {
  margin: 17px !important;
}

.pastTripPopupCaptcha {
  display: flex;
  justify-content: center;
  margin-top: 30px !important;
}

.pastTripPopupBox {
  display: flex;
  margin-top: 30px;
}

.pastTripPopupBoxText {
  /* font-size: 11px; */
  font-size: 12px;
  margin-top: 13px;
  margin-left: -21px;
}

.pastTripPopupCheckBox {
  /* margin-top: 124px; */
  color: grey;
  margin-bottom: 55px;
  margin-left: 6px;
}

.pastTripPopupBtn {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 8px !important;
  font-size: 15px !important;
  height: 40px;
  margin-bottom: 20px;
  margin-left: 20px !important;
  width: 70% !important;
  margin-top: -65px !important;
}
.past-trip-popup-img{
  border-radius: 47px;
  margin-top: -15px;
}
.past-trips-img-div{
  margin-left: 95px;
  margin-bottom: 5px;
  margin-top: 4px;
  /* display: flex; */
  /* justify-content: end; */
  font-size: 16px;
}
.App {
  font-family: sans-serif;
  /* text-align: center; */
  /* background-color: #f6f9fe; */
  /* height: 100vh; */
}
.image-item {
  display: flex;
  margin: -79px 37%;
  height: auto !important;
  width: 100% !important;
}
.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
button {
  height: 40px;
  /* display: none; */
}

.hLCrav .rnc {
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  max-width: 255px;
  background-color: white !important;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
}

.hLCrav .rnc-canvas {
  box-sizing: border-box;
  background-color: lightgrey !important;
  /* border-radius: 4px; */
}
.hLCrav .rnc-button {
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  /* background: #1ec28b !important; */
  border: 1px solid #1ec28b !important;
  color: #1ec28b !important;
  border: none;
  padding: 0;
  width: 25px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
}

.hLCrav .rnc-input {
  border: 1px solid #1ec28b !important;
  padding: 0 11px;
  height: 32px;
  border-radius: 6px;
  font-size: 14px;
  color: #46474a;
}

/* =============pasttrip popup info css=================== */

.pastTripPopupinfo-modalBackground {
  width: 98vw;
  height: 41rem;
  background-color: rgba(226, 214, 214, 0.447);
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -359px;
  margin-top: -708px;
  z-index: 2;
  /* width: 98vw;
  height: 85rem;
  background-color: rgba(226, 214, 214, 0.447);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -580px;
  margin-top: -708px;
  z-index: 2; */
}

.pastTripPopupinfo-modalContainer {
  width: 580px;
  /* height: 33%; */
  height: 500px;
  /* margin-top: -360px; */
  border-radius: 12px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.pastTripPopupinfo-modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.pastTripPopupinfo-titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.pastTripPopupinfo-titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 23px;
  color: pink;
  cursor: pointer;
  margin-top: -13px;
}

.pastTripPopupinfo-modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.pastTripPopupinfo-modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pastTripPopupinfo-modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: rgb(32, 90, 197);
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#pastTripPopupinfo-cancelBtn {
  background-color: crimson;
}

.pastTripPopupinfo-text {
  font-size: 22px;
  font-weight: 600;
  transform: translateX(65px);
}

.pastTripPopupinfo-hrlines {
  background: #1ec28b;
  width: 37rem;
  height: 1px;
}

.pastTripPopupinfo-imagebox {
  width: 100%;
  height: 262px;
  border-radius: 10px;
  border: 1px solid #1ec28b;
  margin-top: 40px;
}

.pastTripPopupinfo-imageboxinner {
  border: 14px solid white;
  border-radius: inherit;
}

.pastTripPopupinfo-image {
  border-radius: inherit;
  width: 100%;
  height: 230px;
}

.pastTripPopupinfo-description {
  width: 100%;
  height: 110px;
  border-radius: 10px;
  border: 1px solid #1ec28b;
  margin-top: 10px;
  text-align: initial;
}
.pastTripPopupinfo-descriptioninner {
  border: 9px solid white;
  border-radius: inherit;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .pastTripPopup-modalBackground {
    width: 98vw;
    height: 71rem;
    background-color: rgba(226, 214, 214, 0.447);
    /* position: sticky; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    margin-top: -1162px;
    z-index: 2;
    /* width: 98vw;
    height: 85rem;
    background-color: rgba(226, 214, 214, 0.447);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -580px;
    margin-top: -708px;
    z-index: 2; */
  }

  .pastTripPopup-modalContainer {
    width: 82%;
    height: auto;
    /* margin-top: -360px; */
    border-radius: 12px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }

  .pastTripPopup-modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }

  .pastTripPopup-titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }

  .pastTripPopup-titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 23px;
    color: pink;
    cursor: pointer;
    margin-top: -13px;
  }

  .pastTripPopup-modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }

  .pastTripPopup-modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pastTripPopup-modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: rgb(32, 90, 197);
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }

  #pastTripPopup-cancelBtn {
    background-color: crimson;
  }

  .pastTripPopupUploadinnerCard {
    width: 90%;
    height: 109px !important;
    border-radius: 10px;
    margin-top: 7px;
    margin-left: 10px;
    /* border: 1px solid #1ec28b; */
    /* background: rgb(30 194 139 / 60%) !important; */
    background: rgba(30, 194, 139, 0.1) !important;
  }

  .pastTripPopupinner {
    height: 60px;
    width: 60px;
    background: white;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    margin-left: 0px;
    /* margin-top: -35px; */
    border: 8px solid grey !important;
  }

  .pastTripPopupCaptcha {
    display: block;
    justify-content: center;
    margin-top: 0px !important;
  }

  .pastTripPopupCaptchaText {
    margin: 17px !important;
    display: flex;
    font-weight: bold !important;
  }

  .pastTripPopupBoxText {
    /* font-size: 11px; */
    font-size: 9px;
    margin-top: 13px;
    /* margin-left: -21px; */
    display: flex;
    text-align: initial;
  }
  .pastTripPopupBtn {
    background-color: #1ec28b !important;
    color: white !important;
    border-radius: 8px !important;
    font-size: 15px !important;
    height: 40px;
    margin-left: 0px !important;
    width: 64% !important;
    margin-top: -45px !important;
  }
  /* =============pasttrip popup info css=================== */

  .pastTripPopupinfo-modalBackground {
    width: 98vw;
    /* height: 71rem; */
    height: 43rem;
    background-color: rgba(226, 214, 214, 0.447);
    /* position: sticky; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    margin-top: -908px;
    z-index: 2;
  }

  .pastTripPopupinfo-modalContainer {
    /* width: 80%;
    height: 500px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-top: -460px; */
    width: 82%;
    height: auto;
    /* margin-top: -360px; */
    border-radius: 12px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }

  .pastTripPopupinfo-modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }

  .pastTripPopupinfo-titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }

  .pastTripPopupinfo-titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 23px;
    color: pink;
    cursor: pointer;
    margin-top: -13px;
  }

  .pastTripPopupinfo-modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }

  .pastTripPopupinfo-modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pastTripPopupinfo-modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: rgb(32, 90, 197);
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }

  #pastTripPopupinfo-cancelBtn {
    background-color: crimson;
  }

  .pastTripPopupinfo-text {
    font-size: 20px;
    font-weight: 600;
    transform: translateX(30px);
  }

  .pastTripPopupinfo-hrlines {
    background: #1ec28b;
    width: 100%;
    height: 1px;
  }
}
