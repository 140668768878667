.navbarImage {
  width: 374px;
  height: 80px;
}
.home-explore{
  margin: 2rem;
  color: #174a39;
  font-weight: bold;
  /* padding:; */
  cursor: pointer;
  /* word-spacing: 1cm; */
  border-radius: 2px;
  letter-spacing: 0.1cm;
  width: 20%;
  font-size: 1rem;
  background-color: transparent;
  outline: none;
  border: 1.5px solid #92b0a6;
}
.homeheading {
  text-align: initial;
  font-style: sans-serif;
  line-height: 1.5;
}

.homePageCard {
  display: inline-block;
  width: 32% !important;
  margin-bottom: 30px;
  height: auto;
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 60px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
  /* padding-right: 10px; */
}

.howItWorksContainer {
  background: rgb(30 194 139 / 10%);
  margin-top: 50px;
}

.postURTripContainer {
  /* background: rgb(30 194 139 / 10%); */
  margin-top: 50px !important;
  margin-bottom: 50px;
}

.homelandingImage {
  height: 400px;
  width: 100%;
}

/* .homepageCompanionHeading {
  background: rgb(30 194 139 / 10%);
} */

.faqHomePageBtn {
  background-color: #ff8d8d !important;
  font-size: 15px;
  width: 240px !important;
  color: white !important;

  height: 45px;
}

.faqBtnContainer {
  padding: 30px;
}

.faqHomePageLink {
  font-size: 20px;
  color: white !important;
  text-decoration: none;
}

.homepage-howit-works-main {
  margin-top: 100px;
}

.companionSelfieContainer {
  /* background: rgb(30 194 139 / 10%); */
  height: 80%;
  padding: 35px;
  width: 100%;
  /* display: flex; */
}

.slider-backward-arrow {
  color: #1ec28b;
  position: absolute;
  margin-top: 270px !important;
  font-size: 2rem !important;
  display: flex;
  transform: translate(586px, 10px);
  z-index: 1;
  margin-left: 190px;

}

.routeArrowContainer {
  display: flex;
  flex-direction: row;
  column-gap: 75px;
}

.homepagePrevIcon {
  margin-top: 130px !important;
}

.routeMainContainer {
  width: 100%;
  line-height: 2;
  text-align: initial;
  font-weight: 600;
}

.slider-forward-arrow {
  color: #1ec28b;
  position: absolute;
  margin-top: -91px !important;
  right: -384px;
  font-size: 2rem !important;
  transform: translate(-676px, -39px);
  z-index: 1;
}

.csleftIcon {
  font-size: 35px !important;
}

.homepageIMGContainer {
  height: 200px;
  width: 100%;
}

.csrightIcon {
  font-size: 35px !important;
}

.latestpostsIMG {
  height: 159px;
  /* margin-left: 8px; */
  width: 160px;
  padding-top: 15px;
  /* padding-left: 10px; */
  border-radius: inherit;
}

.howWorkHeading {
  color: #000000;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 10px;
}

.hrhowworkLine {
  background: #1ec28b;
  width: 30%;
  height: 3px;
  margin: auto;
}

.companion-selfie-Heading {
  color: #000000;
  font-size: 40px;
  text-align: center;
  font-weight: 700;
}

.hrcompanion-selfie {
  background: #1ec28b;
  width: 25%;
  height: 3px;
  margin: auto;
  margin-bottom: 60px;
}

.howWorkImage {
  height: auto;
  width: 100%;
  margin-top: 45px;
}

.latestPostsCard {
  width: 45% !important;
  height: auto;
  margin-bottom: 90px !important;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  box-shadow: 0px 10px 1px -1px #164938 !important;
}

.companionSelfieIMG {
  /* height: 90%;
  width: 100%; */
  height: 350px;
  width: 500px;
  margin-bottom: 24px;
  object-fit: contain;
  object-position: center;
}

.latestPostContainer {
  /* columns: 2 auto !important; */
  display: contents;
  margin-top: 45px !important;
  margin-bottom: 70px !important;
}

.homePageContent {
  text-align: left;
  /* font-size: 15px; */
  /* margin-right: 241px; */
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: sans-serif;
  /* width: 95%; */
}

.latestPostBold {
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 1.5;
}

.latestpostsImage {
  height: 190px;
  width: 200px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin: auto;
}
.companionSelfieMainContainer {
  margin-top: 100px;
}
.homePageMoreinfoBtn {
  background-color: #ff8d8d !important;
  color: white !important;
  border: none !important;
  /* width: 120px !important; */
  height: 40px !important;
  font-size: 15px !important;
  margin-top: 16px !important;
  border-radius: 9px !important;
  margin-bottom: 50px !important;
}

.latestPostsection {
  margin-top: 70px !important;
  background: rgb(30 194 139 / 10%);
  height: 100%;
}

.latestPostConnect {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 10px !important;
  border: none;
  font-size: 11px !important;
  height: 35px;
  width: 105px;
  margin-top: 70px !important;
  margin-bottom: 35px !important;
}

.latestPostHeading {
  color: #000000;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin-top: 100px;
}

.hrlatestPostsectionLine {
  background: #1ec28b;
  width: 28%;
  height: 3px;
  margin-bottom: 75px !important;
  margin-top: 10px !important;
  margin: auto;
}

.container {
  height: 400px;
  width: 400px;
}

.inner {
  height: 189px;
  width: 200px;
  /* background: #1ec; */
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-left: 21%;
  margin-top: 1%;
}

.Cards {
  display: inline-block;
  width: 60% !important;
  margin-bottom: 85px;
  height: 700px !important;
  /* box-shadow: 0px 15px 1px -1px rgb(0 0 0 / 20%) */
  box-shadow: 0px 10px 1px -1px #164938 !important;
}

.contentCat {
  font-size: x-large;
  margin-right: 126px;
  font-family: sans-serif;
}

.contentJohn {
  font-size: x-large;
  margin-right: 161px;
  font-family: sans-serif;
}

.contentPraveen {
  font-size: x-large;
  margin-right: 170px !important;
  font-family: sans-serif;
}

.contentVinsent {
  font-size: x-large;
  margin-right: 214px;
  font-family: sans-serif;
}

.contentplace {
  font-size: 15px;
  margin-right: 241px;
  font-family: sans-serif;
  width: 344px;
  margin-top: -24px !important;
}

img.imgRes {
  /* padding: 18px; */
  height: 159px;
  margin-left: 8px;
  /* padding: 0px; */
  padding-top: 15px;
  padding-left: 10px;
  /* padding-bottom: 0px; */
}

.crd {
  margin-top: -183px;
  /* font-size: x-large; */
  display: flex;
}

.cardPara {
  font-size: 16px;
  text-align: start;
  margin-left: 25px;
  font-weight: 400;
  font-family: sans-serif;
  line-height: 1.5;
}

.cardButton {
  background-color: #1ec28b !important;
  color: white !important;
  border: none !important;
  width: 120px !important;
  height: 40px !important;
  font-size: 15px !important;
  margin-top: 16px !important;
  border-radius: 9px !important;
}

.cardButtonText {
  color: white !important;
  text-decoration: none !important;
}

.homeexploreMore {
  margin-left: -11px !important;
  margin-top: 7px !important;
  font-size: 30px;
  font-family: sans-serif;
}

.exploreSection {
  margin-top: 75px !important;
  text-align: "center";
}

.groceryIMG {
  margin-bottom: 50px !important;
  margin-top: 90px !important;
  width: 90% !important;
}

.exploreSectionHeading {
  color: #000000;
  font-size: 40px;
  font-weight: 600;
}

.hrexploreLine {
  /* background: rgb(30 194 139 / 60%);  */
  background: #1ec28b;
  width: 44%;
  height: 3px;
  margin-top: 17px;
  margin-left: 28%;
}

.homeheadingText {
  color: #000000;
  font-size: 45px;
  font-weight: 700;
}

.homelandingContent {
  width: 70%;
  font-size: 25px;
  font-family: sans-serif;
  line-height: 1.75;
  text-align: initial;
}

.whatsOnURMind {
  width: 100%;
  line-height: 2;
  display: block;
  text-align: justify;
}

.homepage-ads-image {
  width: 100%;
  margin-top: 25px;
}

.homepage-campanion-selfie-grid {
  width: 100%;
  margin-top: 20px !important;
  display: flex !important;
}

.homepage-campanion-details-heading {
  font-size: 30px;
  width: 100%;
  font-weight: 700;
  text-align: initial !important;
  line-height: 1.75;
  margin-bottom: 10px;
}

.homepage-campanion-double-quotes-icon {
  transform: rotate(180deg) !important;
  font-size: 54px !important;
  color: #ff8d8d !important;
}

.home-latest-card-places {
  text-align: initial;
  line-height: 2;
  font-weight: 600 !important;
}

/* ===============ads card after search ========== */

.homepage-ads-image-desktopversion {
  display: block;
}

.homepage-ads-image-Mobileversion{
  display: none !important; 
}
.homepage-ads-image-img-one-container {
  position: relative;
}
.homepage-ads-image-img-one-btn {
  width: 24%;
  height: 56px;
  top: 84%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -42%);
  background-color: rgb(255, 141, 141);
  color: white;
  font-size: 19px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.home-footer-ads-img-one {
  margin-bottom: 50px !important;
  margin-top: 90px !important;
  width: 90% !important;
  height: 78%;
}
.home-footer-ads-img-one-container {
  position: relative;
}
.home-footer-ads-img-one-btn {
  position: absolute;
  top: 73%;
  left: 50%;
  transform: translate(-50%, -42%);
  background-color: rgb(255, 141, 141);
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.home-footer-ads-btn {
  background-color: rgb(255, 141, 141);
  color: white;
  display: flex;
  margin-top: 30px !important;
  margin: auto;
  align-items: center;
  border: none;
  border-radius: 5px;
  width: 150px;
}
.home-footer-ads-box {
  background-color: white !important;
  border: 10px solid #1ec28b !important;
}

.home-footer-ads-horizontal-line {
  color: rgba(0, 0, 0, 80%);
}
.home-footer-ads-horizontal-text {
  text-align: center;
  border-bottom: 1px solid black;
  line-height: 0;
  width: 50%;
  margin: auto;
  margin-top: 25px !important;
}
.home-footer-ads-horizontal-text span {
  background: #fff;
  padding: 0 10px;
  font-size: 20px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .faqHomePageBtn {
    background-color: #ff8d8d !important;
    font-size: 15px;
    width: 115px !important;
    color: white !important;
  
    height: 45px;
  }

  .homeheading {
    text-align: initial;
    width: 100%;
    line-height: 1.5;
    margin-top: -25px;
    background-image: url("../Pages/HomePage/Homepagecircle.png");
    background-position-y: -45px !important;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-x: 182px;
    background-position: right;
    background-size: 200px;
    height: 150px;
  }

  .home-explore{
    margin-bottom: 1rem;
    color: #174a39;
    font-weight: bold;
    /* padding:; */
    /* word-spacing: 1cm; */

    border-radius: 2px;
    letter-spacing: 0.1cm;
    width: 60%;
    font-size: 1rem;
    background-color: transparent;
    outline: none;
    border: 1.5px solid #92b0a6;
  }

  .homeheadingText {
    color: #000000;
    font-size: 20px;
    font-weight: 700;
  }

  .homelandingContent {
    display: none;
  }

  .homelandingImage {
    display: none;
  }

  .homepage-searchbar-main {
    margin-top: -50px;
    background-image: url("../Pages/HomePage/CircleBelowSearchBar.png");
    background-repeat: no-repeat;
    background-size: 118px;
    background-position: bottom;
    background-position-x: inherit;
    background-position-y: 340px;
  }

  .homepage-ads-image {
    width: 100%;
    margin-top: 25px;
  }

  .homepageIMGContainer {
    height: 150px;
    width: 100%;
  }

  .latestPostContainer {
    display: block;
    margin-bottom: 15px !important;
  }

  .latestPostHeading {
    color: #000000;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
  }

  .latestPostsection {
    margin-top: 20px !important;
    background: rgb(30 194 139 / 10%);
    height: 100%;
  }

  .hrlatestPostsectionLine {
    background: #1ec28b;
    width: 35%;
    height: 2px;
    margin-bottom: 75px !important;
    margin-top: 10px !important;
    margin: auto;
  }

  .latestPostsCard {
    width: 100% !important;
    margin: auto !important;
    height: auto !important;
    margin-bottom: 50px !important;
    box-shadow: 0px 10px 1px -1px #164938 !important;
  }

  .latestpostsImage {
    height: 150px;
    width: 150px;
    margin: auto;
    background: rgb(30 194 139 / 60%);
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
  }

  .latestpostsIMG {
    height: 120px;
    width: 120px;
    padding-top: 15px;
    border-radius: inherit;
  }

  .exploreSection {
    margin-top: 50px !important;
    text-align: "center";
  }

  .exploreSectionHeading {
    color: #000000;
    font-size: 30px;
    font-weight: 600;
  }

  .hrexploreLine {
    background: #1ec28b;
    width: 50%;
    height: 2px;
    margin-top: 15px !important;
    margin: auto;
  }

  .howWorkImage {
    height: auto;
    width: 100%;
    margin-top: 45px;
  }

  .howWorkHeading {
    color: #000000;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
  }

  .hrhowworkLine {
    background: #1ec28b;
    height: 2px;
    margin: auto;
    width: 120px;
  }

  .companion-selfie-Heading {
    font-weight: 600;
    margin: auto;
    text-align: center;
    margin-top: 25px;
    font-size: 30px;
  }

  .hrcompanion-selfie {
    background: #1ec28b;
    width: 150px;
    height: 2px;
    margin: auto;
    margin-top: 10px;
  }

  .homepage-campanion-details-heading {
    font-size: 15px;
    width: 100%;
    font-weight: 600;
    text-align: initial !important;
    line-height: 1.75;
    margin-bottom: 10px;
  }

  .routeMainContainer {
    width: 100%;
    line-height: 1.75;
    text-align: initial;
    font-weight: 500;
  }

  .homepage-campanion-double-quotes-icon {
    transform: rotate(180deg) !important;
    font-size: 30px !important;
    color: #ff8d8d !important;
  }

  .whatsOnURMind {
    width: 100%;
    line-height: 1.75;
    display: block;
    text-align: justify;
  }

  .slider-forward-arrow {
    color: #1ec28b;
    position: initial !important;
    margin-top: 0px !important;
    font-size: 2rem !important;
    transform: translate(0px, 0px) !important;
    z-index: 1 !important;
    text-align: initial !important;
  }

  .slider-backward-arrow {
    color: #1ec28b;
    /* margin-top: 47 7 !important; */
    /* margin-top: 450 !important; */

    font-size: 2rem !important;
    display: flex !important;
    transform: translate(0px, 0px) !important;
    z-index: 1 !important;
    /* margin-top: 411px!important; */
    position: absolute;
    margin-left: 150px;

  }

  .csleftIcon {
    font-size: 30px !important;
    /* margin-top: 208px; */
    margin-top: 237px;
  }

  .csrightIcon {
    font-size: 30px !important;
    margin-left: 97px;;
  }

  .groceryIMG {
    margin-bottom: 25px !important;
    margin-top: 25px !important;
    width: 100% !important;
  }

  /* ===============ads card after search ========== */

  .homepage-ads-image-desktopversion {
    display: none !important;
  }

  .homepage-ads-image-Mobileversion{
    display: block !important;
  }

  .home-footer-ads-img-one {
    margin-bottom: 25px !important;
    margin-top: 25px !important;
    width: 100% !important;
  }
  .home-footer-ads-img-one-btn {
    position: absolute;
    top: 73%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 141, 141);
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .home-footer-ads-box {
    background-color: white !important;
    width: 90% !important;
    border: 10px solid #1ec28b !important;
  }
  .companionSelfieIMG {
    /* height: 90%;
    width: 100%; */

    height: 300px;
    width: 285px;
    margin-bottom: 24px;
    object-fit: contain;
    object-position: center;
    margin-left: 1px;
    
  }
}

@media screen and (min-width: 360px) and (max-width: 480px) {
  .csleftIcon {
    font-size: 30px !important;
    /* margin-top: 233px; */
    margin-top: 237px;
}
.companionSelfieIMG {
  /* height: 90%;
  width: 100%; */
  
    height: 300px;
    width: 320px;
    margin-bottom: 24px;
    object-fit: contain;
    object-position: center;
   
  
  
}
.slider-backward-arrow {
  color: #1ec28b;
  font-size: 2rem !important;
  display: flex !important;
  transform: translate(0px, 0px) !important;
  z-index: 1 !important;
  /* margin-top: 359px!important; */
  position: absolute;
}
}



/* this is paritially given media queries */
@media screen and (min-width: 400px) and (max-width: 700px) {

  .csleftIcon {
    font-size: 30px !important;
    /* margin-top: 233px; */
    margin-top: 238px;
}
}