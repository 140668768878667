.links-main {
  margin-left: auto;
  word-spacing: 5px;
  display: flex;
  align-items: center;
}

.create-links {
  text-decoration: none;
  color: black;
  font-size: 20px;
}
.nav-links-button-login {
  background-color: #1ec28b !important;
  color: #ffffff !important;
  text-decoration: none !important;
}
.nav-links-notofication-icon {
  background-color: #ffffff !important;
  color: #1ec28b !important;
  vertical-align: top;
}
.connect-links {
  text-decoration: none;
  color: black;
  font-size: 20px;
}
.drawer-icon {
  margin-left: auto;
  margin-right: -40px;
}
.nav-logo-desktop {
  display: none;
}
.navbarImage {
  width: 100%;
  height: 40px;
  display: flex;
}
.nav-item-header-icon-container {
  height: 5px;
  display: inline-block;
}
.nav-item-header-icon-inner {
  height: 40px;
  width: 40px;
  margin-top: -18px;
  background: #1ec28b;
  border-radius: 100px;
  -webkit-border-radius: 100px;
}
.nav-item-header-icon-Img {
  height: 32px;
  padding-top: 4px;
  border-radius: inherit;
  width: 85%;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .main-header-container{
    position: fixed;
    top: 0px;
    background-color: white;
    z-index: 20;
  }
  .navbarImage {
    height: 30px;
    width: 85%;
    display: block;
  }
  .nav-links-button-login {
    background-color: #1ec28b !important;
    color: #ffffff !important;
    text-decoration: none !important;
    height: 25px;
  }
  .nav-logo-desktop {
    display: flex;
    font-size: 15px;
  }
}
