
/*-------------------------------------- new css----------------------------------------------- */
.cursor_point {
 
  color:#FFF;
  /* font-family:Verdana, Geneva, sans-serif; */
  cursor:pointer;
}
.mainSignup {
  background-color: aliceblue !important;
  width: 100% !important;
  height: 100% !important;
}
.signupFirstimage {
  width: 87%;
  margin-top: 142px;
}

.signupPageSecondimage {
  width: 100%;
}
.planForYouHeading {
  font-family: sans-serif;
  font-style: italic;
  line-height: 1.5;
  margin-top: 90px;
}
.signupPageContainer-main {
  border: 1px solid #1ec28b;
  background-color: white;
  margin-top: 30px;
  border-radius: 25px;
  text-align: center;
  width: 100%;
}
.signupPageContainer-sub {
  width: 80% !important;
  margin: auto !important;
}
.signup-fullNamelabel {
  display: flex;
}
.signup-fullNameInput {
  width: 100% !important;
  height: 45px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
}

.signup-ageGroupLabel {
  display: flex;
  margin-top: 15px !important;
}
.signup-ageInput {
  width: 100% !important;
  height: 45px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
  text-align: initial !important;
}

.signup-genderLabel {
  display: flex;
}
.signup-genderInput {
  width: 100% !important;
  height: 45px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
  text-align: initial !important;
}
.signup-mobileLabel {
  display: flex;
}
.signup-countryCodeInput {
  width: 20%;
  margin-top: 5px !important;
}
.signup-mobileInput {
  width: 80%;
  margin-top: 5px !important;
}
.signup-countryLabel {
  display: flex;
}
.signup-countryInput {
  width: 100% !important;
  height: 45px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
}
.signup-stateLabel {
  display: flex;
}
.signup-stateInput {
  width: 100% !important;
  height: 45px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
}
.signup-emailIdLabel {
  display: flex;
}
.signup-emailIdInput {
  width: 100% !important;
  height: 45px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
}
.signup-passwordLabel {
  display: flex;
  margin-top: 5px !important;
}
.signup-passwordInput {
  width: 100% !important;
  height: 45px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
}
.signup-confirmPasswordLabel {
  display: flex;
  margin-top: 15px !important;
}
.signup-confirmPasswordInput {
  width: 100% !important;
  height: 45px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
}
.agreement {
  margin-top: 30px;
  font-size: 16px;
}
.signup-Error {
  color: #d32f2f;
  text-align: initial;
  font-size: smaller;
}
.signup-registerBtn {
  margin-top: 40px;
  font-size: 20px;
  border: none;
  border-radius: 35px;
  height: 45px;
  font-size: 20px;
  width: 230px;
  background-color: #1ec28b;
  color: #ffffff;
}
.socialIcons {
  display: flex;
  justify-content: center;
}

.signup-google-btn {
  width: 200px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 0px;
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 25%);
  margin-top: 10px;
  margin-bottom: 10px;
}
  .signup-google-icon-wrapper {
    /* position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff; */
    position: absolute;
    margin-top: 0px;
    margin-left: 1px;
    width: 40px;
    height: 42px;
    border-radius: 2px;
    background-color: #fff;
  }
  .signup-google-icon {
    /* position: absolute;
    width: 38px;
    height: 40px;
    display: flex;
    margin: auto; */
    position: absolute;
    width: 25px;
    height: 40px;
    display: flex;
    margin: auto;
    /* padding-right: 9px; */
    padding-left: 7px;
  }
  .signup-btn-text {
    float: right;
    margin: 11px 15px 0 0;
    color:#fff;
    font-size: 14px;
    letter-spacing: 0.2px;
    /* font-family: "Roboto"; */
    font-family: Roboto, sans-serif;
  }
.signup-google-btn:hover {
    box-shadow: 0 0 6px #4285f4;
  }
  .signup-google-btn :active {
    background: #1669F2;
  }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .signupFirstimage {
    width: 87%;
    margin-top: 50px;
  }
  .signupPageContainer-sub {
    width: 95% !important;
    margin: auto !important;
  }
  .agreement {
    margin-top: 30px;
    font-size: 15px;
  }
  .signup-registerBtn {
    margin-top: 40px;
    font-size: 15px;
    border: none;
    border-radius: 35px;
    height: 40px;
    width: 35%;
    background-color: #1ec28b;
    color: #ffffff;
  }
}