.faq-Heading {
  font-size: 60px;
  margin-top: 0px !important;
  color: rgba(0, 0, 0, 80%);
  font-weight: 600;
}
.faq-helpText {
  font-size: 35px;
  margin-bottom: -40px;
}

.faq-searchContainer {
  width: 35%;
  display: block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  margin: auto;
}

.FAQsearchIcon {
  color: #1ec28b;
  margin-left: 165px;
  font-size: 33px !important;
  position: absolute;
  margin-top: -42px;

  cursor: pointer;
}

#filter {
  height: 20px;
}

.FAQhr {
  width: 95%;
  border-color: #1ec28b !important;
}
.sideMenu {
  height: auto;
  display: flex;
  flex-direction: column;
  /* margin-left: 100px; */
  line-height: 3;
  width: 330px;
}
/* #filter {
  height: 10px !important;

} */

.FAQAccountSettings {
  font-size: 23px;
  font-weight: 600;
  color: rgba(0, 0, 0, 80%);
  height: auto;
  /* margin-left: 7px; */
}

.contactIcon {
  display: flex;
  justify-content: end;
  /* margin-right: 70px; */
  /* padding-right: 21px; */
}

.connecttedIcon {
  display: flex;
  justify-content: end;
  /* margin-right: 70px; */
  /* padding-right: 87px; */
}
.gettingStarted {
  display: flex;
  justify-content: end;
  /* margin-right: 70px; */
  /* padding-right: 45px; */
}
.gettingStartedIcon {
  color: #1ec28b !important;
  font-size: 25px;
  margin-top: 21px;
}
.payment {
  display: flex;
  justify-content: end;
  /* margin-right: 70px; */
  /* padding-right: 109px; */
}

div:focus {
  background-color: rgb(30, 194, 139, 5%);
}

.tabsColor {
  display: flex !important;
  flex-direction: column !important;
  align-items: baseline !important;
}

.searchLabel .MuiOutlinedInput-notchedOutline {
  border-color: #1ec28b !important;
  border-width: 1px !important;
}

/* ---------tabpanel css -------- */

.tabPanel-Box {
  display: flex;
  margin-top: 50px;
}

.tabPanel-Account {
  color: #000000 !important;
}

.tabpanel-Accounttext {
  display: flex;
  column-gap: 10px;
  width: max-content;
}

.tabpanel-ggProfile {
  color: #1ec28b !important;
  font-size: 25px;
  margin-top: 8px;
}

.tabpanel-Connecttext {
  display: flex;
  column-gap: 10px;
  width: -webkit-fill-available;
}
.tabPanel-connectedFilled {
  color: #1ec28b !important;
  font-size: 25px;
  margin-top: 8px;
}

.tabPanel-hourGlassStart {
  color: #1ec28b !important;
  font-size: 22px;
  margin-top: 8px;
}

.tabPanel-hourGlassStart {
  color: #1ec28b !important;
  font-size: 22px;
  margin-top: 8px;
}
.tabpanel-hourGlassStarttext {
  display: flex;
  grid-column-gap: 10px;
  column-gap: 14px;
  width: 97%;
}

.tabpanel-mobnddesk {
  display: contents;
}

.tabPanel-accordion {
  background-color: rgb(30, 194, 139, 5%);
  width: 53rem;
  height: auto;
  padding: 30px;
  margin-bottom: 50px;
  margin-top: -10px;
}

.tabPanel-desktop-view {
  display: block;
}

.tabPanel-mobile-view {
  display: none;
}
/* .css-heg063-MuiTabs-flexContainer {
  display: grid !important;
} */

/* .css-heg063-MuiTabs-flexContainer {
  display: grid !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  margin: 0px !important;
} */

@media screen and (min-width: 320px) and (max-width: 480px) {
  .faq-Heading {
    font-size: 16px;
    color: rgba(0, 0, 0, 80%);
    font-weight: 600;
  }

  .faq-helpText {
    font-size: 14px;
    margin-bottom: -50px;
  }
  .faq-searchContainer {
    display: block;
    width: 65%;
    margin-top: -75px;
    margin-bottom: 65px;
    margin: auto;
    flex-wrap: wrap;
  }

  .FAQsearchIcon {
    color: #1ec28b;
    margin-left: 79px;
    font-size: 33px !important;
    position: absolute;
    margin-top: -42px;
  }

  .tabpanel-mobnddesk {
    display: block !important;
  }

  .tabPanel-Box {
    display: block;
    margin-top: 35px;
  }

  .tabPAnel-searchContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: -75px;
    margin-bottom: 65px;
  }

  .tabpanel-ggProfile {
    color: #1ec28b !important;
    font-size: 12px;
    margin-top: 11px;
  }
  .tabPanel-connectedFilled {
    color: #1ec28b !important;
    font-size: 12px;
    margin-top: 6px;
  }
  .tabPanel-hourGlassStart {
    color: #1ec28b !important;
    font-size: 12px;
    margin-top: 11px;
  }

  .tabpanel-Accounttext {
    display: flex;
    column-gap: 2px;
    width: max-content;
  }

  .tabpanel-Connecttext {
    display: flex;
    grid-column-gap: 10px;
    column-gap: 2px;
    width: -webkit-fill-available;
  }

  .tabpanel-Accountsettingstext {
    width: min-content;
    font-size: 8px;
  }

  .tabPanel-accordion {
    background-color: rgb(30, 194, 139, 5%);
    width: 100%;
    height: auto;
    padding: 7px;
    margin-bottom: 50px;
    margin-top: 0px;
    letter-spacing: 1px !important;
  }

  .tabPanel-mobile-view {
    display: block;
  }

  .tabPanel-desktop-view {
    display: none;
  }

  /* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    margin: -8px !important;
  }
  .css-heg063-MuiTabs-flexContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: block !important;
  } */
}
