.upcoming-dashboard-main {
  display: flex;
}

.upcoming-formobanddesk {
  display: contents;
}
.upcoming-dashbox {
  width: 700px;
  height: auto;
  background-color: aliceblue;
  margin-top: -46px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.upcoming-search-btn {
  border: 2px solid #1ec28b;
  width: 70%;
  border-radius: 5px;
  height: 50px;
  margin-top: 36px;
}

.upcoming-search-btn-icon {
  margin-left: -40px !important;
  color: #1ec28b !important;
}
.upcoming-text {
  text-align: left;
  margin-left: 15%;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-top: 45px;
}
.upcoming-card-Uptrip {
  display: inline-block;
  width: 95% !important;
  margin-bottom: 30px;
  height: 300px;
  margin-left: 129px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
}
.upcoming-card-Uptripcontainer {
  height: 150px;
  width: 150px;
}
.upcoming-card-Uptrip-inner {
  height: 125px;
  width: 125px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-left: 55%;
  margin-top: 12%;
}
.upcoming-carduptrip-img {
  height: 100px;
  padding-top: 12px;
}
.upcoming-uptrip-name {
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 600;
  margin-top: -16px;
}
.upcoming-uptrip-date {
  font-size: 14px;
  font-family: sans-serif;
  font-weight: 600;
  margin-top: -12px;
}
.upcoming-uptrip-city {
  font-size: 13px;
  font-family: sans-serif;
  font-weight: 600;
  margin-top: -12px;
}
/* Recent Invites */
.upcomingtrip-text {
  text-align: left;
  margin-left: 15px;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-top: 45px;
  padding-top: 35px;
}
.upcomingCreated-text {
  text-align: left;
  margin-left: 5%;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 18px;
  /* margin-top: 45px; */
  padding-top: 35px;
}
.upcomingContainer,.upcomingTripContainer {
  display: contents;
}
.upcoming-card-up-coming1 {
  display: inline-block;
  width: 40% !important;
  margin-bottom: 30px;
  height: auto;
  margin-left: 55px;
  margin-right: -25px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
  padding-right: 10px;
  /* width: 40% !important;
  margin-bottom: 30px;
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
  padding-right: 10px; */
}
.upcoming-card-up-coming2 {
  display: inline-block;
  width: 110% !important;
  margin-bottom: 30px;
  height: 670px;
  margin-left: 150px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
  padding-right: 10px;
}
.upcoming-card-up-coming3 {
  display: inline-block;
  width: 110% !important;
  margin-bottom: 30px;
  height: 580px;
  margin-left: 50px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
  padding-right: 10px;
}
.upcoming-card-up-coming4 {
  display: inline-block;
  width: 110% !important;
  margin-bottom: 30px;
  height: 580px;
  margin-left: 150px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
  padding-right: 10px;
}
.upcoming-card-recentcontainer {
  height: 150px;
  width: 100%;
}
.upcoming-card-up-coming-inner {
  height: 125px;
  width: 125px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  /* margin-left: 40%; */
  /* margin-top: 12%; */
  margin: auto;
}
.upcoming-cardrecent-img {
  height: 100px;
  width: 100px;
  padding-top: 12px;
  border-radius: inherit;
}
.upcoming-cardrecent-name {
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 600;
  margin-top: -16px;
}
.upcoming-cardrecent-date {
  font-size: 14px;
  font-family: sans-serif;
  font-weight: 600;
  margin-top: -20px;
}
.upcoming-cardrecent-city {
  font-size: 13px;
  font-family: sans-serif;
  font-weight: 600;
  margin-top: -20px;
}
.upcoming-cardrecent-para {
  font-size: 11px;
  margin-top: -18px;
  width: 198px;
}
.upcoming-accept-btn {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 21px !important;
  border: none;
  font-size: 11px !important;
  height: 26px;

  margin-bottom: 20px !important;
}

.upcoming-cancel-btn {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 21px !important;
  border: none;
  font-size: 11px !important;
  height: 26px;
  margin-left: 50px !important;
  margin-bottom: 20px !important;
}
/* Profile  */
.upcoming-card-profile {
  display: inline-block;
  width: 80% !important;
  margin-bottom: 30px;
  height: 250px;
}
.upcoming-card-recentcontainer {
  height: 150px;
  width: 100%;
}
.upcoming-card-profile-inner {
  height: 150px;
  width: 150px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  /* margin-left: 25%; */
  /* margin-top: 5%; */
  /* margin: auto; */
}
.upcoming-cardprofile-img {
  height: 110px;
  padding-top: 20px;
}
.upcoming-profile-name {
  font-family: sans-serif;
  font-size: 25px;
  margin-left: 27px;
  font-weight: 600;
  margin-top: 10px !important;
}
.upcoming-profile-edit-btn {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 5px !important;
  border: none;
  font-size: 11px !important;
  margin-left: 33px !important;
  margin-top: 15px !important;
}

.upcoming-upcoming-delete-dalog {
  display: flex;
  justify-content: center;
  column-gap: 30px;
}

.upcoming-Hrline {
  height: 1px;
  border-width: 0px;
  color: rgb(30, 194, 139);
  background-color: rgb(30, 194, 139);
  width: 70%;
}

.upcoming-yesButton {
  background: #1ec28b !important;
  color: white !important;
  height: 27px !important;
  border-radius: 5px !important;
}
.upcoming-noButton {
  background: #1ec28b !important;
  color: white !important;
  height: 27px !important;
  border-radius: 5px !important;
}

.upcoming-areUSure {
  display: flex;
  justify-content: center;
}

.upcoming-deleteConfirmationIcon {
  margin-top: 1px;
  color: #ff8d8d !important;
  font-size: 27px;
}

.upcoming-buttonsContainer {
  margin-bottom: 30px !important;
  justify-content: center !important;
  display: flex !important;
  column-gap: 100px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .upcoming-dashboard-main {
    display: block;
  }

  .upcoming-sidebarlinks {
    display: none;
  }

  .upcoming-formobanddesk {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .upcoming-dashbox {
    width: 100%;
    height: auto;
    background-color: aliceblue;
    margin-top: -46px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  .upcomingtrip-text {
    text-align: initial;
    margin-left: 16px;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 18px;
    padding-top: 15px;
    display: flex;
  }
  .upcomingCreated-text {
    font-family: sans-serif;
    font-weight: 500;
    font-size: 18px;
    padding-top: 0px;
  }
  .upcomingContainer {
    display: block !important;
  }
  .upcoming-card-up-coming1 {
    display: block;
    margin: auto;
    width: 90% !important;
    margin-bottom: 30px;
    height: auto;
    margin-top: 15px;
    box-shadow: 0px 10px 1px -1px #164938 !important;
  }

  .upcomingContainer,.upcomingTripContainer {
    display: block !important;
  }
}