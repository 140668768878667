.aboutus-aboutusHeadingText {
  font-size: 25px !important;
  display: flex;
}

.aboutus-aboutusHeadingTextSub {
  font-size: 35px;
  font-weight: 700;

}

.aboutus-firstdiv {
  display: flex;
  column-gap: 50px;
}
.aboutus-maindiv {
  display: flex;
  column-gap: 50px;
}
.aboutus-aboutGuideImg {
  width: 100%;
}
.aboutus-aboutPhotoClick {
  width: 100%;
  margin-top: -130px;
}

.aboutus-aboutFlightImg {
  width: 100%;
  margin-top: -70px;
}
.aboutus-contactus-IMG {
  width: 100%;
  margin-top: -70px;
}

.desi-travel-text-container {
  margin-top: 165px !important;
}

.aboutus-travelAboutusContent {
  display: flex;
  text-align: initial;
  font-size: 30px;
  line-height: 2;
  width: 100%;
  margin-top: 95px;
}
.aboutus-travelAboutusContent1 {
  display: flex;
  text-align: initial;
  font-size: 31px;
  line-height: 2;
  width: 100%;
}
.aboutus-travelAboutusContent11 {
  display: flex;
  text-align: initial;
  font-size: 31px;
  line-height: 2;
  width: 100%;
}

.aboutus-desi-travel-aboutus1 {
  display: flex;
  text-align: initial;
  font-size: 13px;
  line-height: 2;
  width: 100%;
  font-weight: 600;
  width: 100%;
}
.aboutus-travelAboutusContent-attempt-text {
  text-align: initial;
  width: 100%;
  font-size: 50px !important;
  line-height: 2;
  font-weight: 600;
}
.aboutus-TCImages {
  display: flex;
}
.aboutus-TCAboutContentText {
  display: flex;
  text-align: initial;
  margin-top: 70px;
  font-size: 29px;
  line-height: 2;
  width: 98%;
}

.aboutus-aboutImageThree,
.aboutus-aboutImageFive {
  margin-top: 80px !important;
}

.aboutus-ImageWidth {
  width: 95%;
}
.aboutus-wercommited-text {
  margin-top: 40px !important;
}
.aboutus-questionImg {
  width: 100%;
}

.aboutus-btn {
  display: flex;
  transform: rotateY(45deg);
}

.aboutus-aboutusContactBtn {
  transform: scale3d(1.5, 1.5, 1.5);
  display: flex;
  position: absolute;
  border-radius: 4px;
  padding: 5px;
  font-weight: bold;
  font-size: 28px !important;
  border: none;
  margin-top: -222px;
  cursor: pointer;
}

.aboutus-leftArrowContactBtnIcon {
  font-size: 33px !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .aboutus-aboutusHeadingText {
    font-size: 9px !important;
    display: flex;
    margin-top: 60px !important;
  }

  .aboutus-aboutusHeadingTextSub {
    font-size: 20px;
    font-weight: 700;
    text-align: initial;
    line-height: 1.5;
    width: 95%;
  }

  .desi-travel-text-container {
    margin-top: 0px !important;
  }

  .aboutus-aboutPhotoClick {
    width: 70%;
    margin-top: 0px;
  }
  .aboutus-firstdiv {
    display: block;
  }
  .aboutus-aboutFlightImg {
    width: 100%;
    height: 218px !important;
  }
  .aboutus-travelAboutusContent {
    display: flex;
    text-align: initial;
    font-size: 17px;
    line-height: 2;
    width: 100%;
  }

  .aboutus-maindiv {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .aboutus-aboutGuideImg {
    width: 70%;
  }
  .aboutus-travelAboutusContent1 {
    display: flex;
    text-align: initial;
    font-size: 17px;
    line-height: 2;
    width: 100%;
  }

  .aboutus-travelAboutusContent11 {
    display: flex;
    text-align: initial;
    font-size: 17px;
    line-height: 2;
    width: 100%;
  }

  .aboutus-travelAboutusContent2 {
    text-align: initial;
    width: 100%;
    word-spacing: 2px;
    font-size: 17px !important;
    line-height: 2;
  }

  .aboutus-travelAboutusContent-attempt-text {
    text-align: justify;
    width: 100%;
    font-size: 16px !important;
    line-height: 2;
    font-weight: 600;
  }

  .aboutus-TCImages {
    display: flex;
  }
  .aboutus-TCAboutContentText {
    display: flex;
    text-align: initial;
    margin-top: 8px;
    font-size: 17px;
    line-height: 2;
    width: 100%;
  }

  .aboutus-aboutImageThree,
  .aboutus-aboutImageFive {
    margin-top: 25px !important;
  }

  .aboutus-ImageWidth {
    width: 95%;
  }

  .aboutus-questionImg {
    width: 100%;
  }

  .aboutus-btn {
    display: flex;
    transform: rotateY(0deg);
  }

  .aboutus-aboutusContactBtn {
    transform: scale3d(0.5, 0.5, 0.5);
    display: flex;
    position: absolute;
    border-radius: 4px;
    padding: 5px;
    font-weight: bold;
    font-size: 22px !important;
    border: none;
    margin-top: -61px;
    height: 40px;
    cursor: pointer;
    /* margin-left: -60px; */
  }
  .aboutus-desi-travel-aboutus1 {
    display: flex;
    text-align: initial;
    font-size: 17px;
    line-height: 2;
    width: 100%;
  }
  .aboutus-leftArrowContactBtnIcon {
    font-size: 33px !important;
  }

  .aboutus-joinourText {
    margin-top: 45px !important;
  }
}
