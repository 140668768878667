.admin-main {
  margin-top: 45px;
}
.admin-companion-heading {
  font-weight: bold;
  width: 25%;
  color: rgb(12 12 12 / 80%);
  margin-top: 33px;
}
.admin-icon {
  width: 10%;
  height: 22px;
}
.statusTextField input {
  caret-color: transparent !important;
  border-color: #c2c2c2 !important;
}
.admin-companion-subheading {
  margin-bottom: 25px;
  margin-top: 5px;
}
.admin-subheading {
  color: #1ec28b;
}
.admin-companion-main {
  display: flex;
  background-color: #f9fbfc;
  /* margin-top: 45px; */
}
.admin-companion-box-main {
  column-gap: 20px;
  display: flex;
  margin: auto;
  justify-content: space-around;
  /* margin-top: 40px !important; */
}
.admin-box {
  border: 2px solid lightgray;
  width: 100% !important;
  height: 100px;
  border-radius: 10px !important;
  color: #1ec28b !important;
  font-weight: bold;
  margin-top: 4rem;
  box-shadow: none !important;
}
.admin-companion-number {
  font-size: 30px;
  margin-top: 10px;
}
.admin-box-color {
  color: grey !important;
  font-weight: bold;
  margin-top: 20px !important;
}

.admin-companion-search {
  width: 50%;
  height: 35px;
}
.admin-companion-srch {
  width: 88% !important;
  margin-top: 35px !important;
  /* border: 1px solid lightgrey !important; */
  border-radius: 8px;
  border-color: #c2c2c2 !important;
}
.admin-companion-stat {
  width: 75%;
  margin-top: 36px;
  height: 35px;
}
.admin-companion-tbl {
  justify-content: center !important;
  display: flex !important;
}
.admin-companion-tblname {
  border-radius: 10px !important;
  border-collapse: collapse;
  width: 97% !important;
  margin-bottom: 35px;
  margin-top: 20px;
  color: lightgrey;
}
.admin-companion-table {
  border: 1px solid lightgrey;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 35px;
  margin-top: 25px;
  border-radius: 20px !important;
  justify-content: center;
  align-items: center;
}
.admin-companion-border {
  border: 1px solid lightgray;
  background: rgb(211 211 211 / 30%);
  border-collapse: collapse;
  height: 35px !important;
}

.admin-companion-border1 {
  border: 1px solid lightgrey !important;
  border-collapse: collapse !important;
}
.admin-companion-border2 {
  border: 1px solid lightgray !important;
  border-collapse: collapse !important;
}

.admin-companion-border3 {
  border: 1px solid lightgray;
  border-collapse: collapse;
}

.admin-companion-icon1 {
  color: white;
  border-radius: 3px;
  width: 25px;
  height: 20px;
  background: rgb(255 191 0 / 50%) !important;
  cursor: pointer;
}
.admin-companion-cmicon {
  column-gap: 10px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 18px;
}
.admin-companion-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 40px !important;
  color: white !important;
}
.admin-companion-img {
  font-size: 10px;
}
.admin-td-image {
  width: 20%;
}
.admin-companion-modal {
  display: flex !important;
  justify-content: center !important;
}
.admin-companion-box {
  background: white !important;
  border-radius: 20px;
  height: 525px !important;
  width: 100%;
  margin-top: 5px;
}
.admin-wrong {
  justify-content: end;
  display: flex;
  font-size: 40px;
  cursor: pointer;
}
.admin-companion-img-start {
  display: flex;
  justify-content: center;
  align-items: center !important;
  width: 100%;
  column-gap: 20px;
  margin-top: 18px;
  margin-bottom: 18px;
}
.admin-companion-img-start1 {
  display: flex;
  align-items: center !important;
  width: 100%;
  column-gap: 20px;
}
.admin-companion-popup-image {
  border: 1px solid lightgrey !important;
  border-radius: 119px;
  height: 40px;
}
.admin-description {
  color: grey;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 30px;
}
.admin-color {
  color: blue;
}
.admin-descr {
  word-wrap: break-word !important;
  font-size: 15px;
  text-align: justify;
}
.admin-popup-start {
  display: flex;
  align-items: center !important;
  width: 40%;
  column-gap: 20px;
  height: 20px;
}
.admin-desc {
  font-weight: bold;
  row-gap: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
}
.admin-name {
  font-weight: bold;
}
.admin-heading1 {
  margin-top: 5px;
  font-weight: bold;
  display: flex;
}
.admin-companion-update {
  width: 85%;
  font-weight: 550;
  font-size: 13px;
  margin-top: 14px;
  text-align: initial;
}
.admin-companion-image {
  border: 1px solid lightgray;
  width: 30%;
  border-radius: 10px;
  height: 42px;
}
.admin-companion-img {
  justify-content: center;
  display: flex;
}
.admin-companion-front-image {
  border: 1px solid lightgrey !important;
  border-radius: 119px;
  height: 40px;
}

.admin-src-image {
  border-radius: 20px;
  border: 1px solid grey;
  margin-top: 10px;
  width: 60%;
  margin-bottom: 20px;
  height: 110px;
}
.admin-mainbtn {
  display: flex !important;
  justify-content: center;
  margin-top: 37px;
  column-gap: 15px;
  margin-bottom: 10px;
}

.admin-button {
  border: 1px solid grey !important;
  background: #1ec28b !important;
  color: white !important;
  width: 92% !important;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 25px;
}
.admin-companion-modalBackground {
  width: 100%;
  height: 55rem;
  background-color: rgba(226, 214, 214, 0.447);
  /* position: sticky; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -609px; */
  z-index: 2;
}
.popUp-parent {
  position: relative;
  padding: 0 !important;
  min-height: 45rem;
}

.popup-back {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-companion-modalContainer {
  width: 30%;
  height: 55% !important;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.admin-modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}
.admin-titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}
.admin-titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 23px;
  color: pink;
  cursor: pointer;
  margin-top: -13px;
}
.admin-modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}
.admin-modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: rgb(32, 90, 197);
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}
#admin-cancelBtn {
  background-color: crimson;
}

.custom-notched-outline .MuiOutlinedInput-notchedOutline {
  border: 1px solid #c2c2c2 !important;
}
