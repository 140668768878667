.footer-ads-img-one {
  margin-bottom: 50px !important;
  margin-top: 90px !important;
  width: 90% !important;
  height: 78%;
}
.footer-ads-img-one-container {
  position: relative;
}
.footer-ads-img-one-btn {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -42%);
  background-color: rgb(255, 141, 141);
  color: white;
  font-size: 16px;
  /* padding: 12px 24px; */
  border: none;
  cursor: pointer;
  border-radius: 5px;
  
  width: 180px;

}
.footer-ads-img-two {
  margin-bottom: 50px !important;
  margin-top: 90px !important;
  width: 90% !important;
}
.footer-ads-img-two-container {
  position: relative;
}
.footer-ads-img-two-btn {
  position: absolute;
  top: 84%;
  left: 50%;
  transform: translate(-50%, -55%);
  background-color: rgb(255, 141, 141);
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.footer-ads-img-three {
  margin-bottom: 50px !important;
  margin-top: 90px !important;
  width: 90% !important;
}
.footer-ads-img-three-container {
  position: relative;
}
.footer-ads-img-three-btn {
  position: absolute;
  top: 86%;
  left: 50%;
  transform: translate(-50%, -48%);
  background-color: rgb(255, 141, 141);
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.footer-ads-btn {
  background-color: rgb(255, 141, 141);
  color: white;
  display: flex;
  margin-top: 30px !important;
  margin: auto;
  align-items: center;
  border: none;
  border-radius: 5px;
  width: 150px;
}
.footer-ads-box {
  background-color: white !important;
  border: 10px solid #1ec28b !important;
}

.footer-ads-horizontal-line {
  color: rgba(0, 0, 0, 80%);
}
.footer-ads-horizontal-text {
  text-align: center;
  border-bottom: 1px solid black;
  line-height: 0;
  width: 50%;
  margin: auto;
  margin-top: 25px !important;
}
.footer-ads-horizontal-text span {
  background: #fff;
  padding: 0 10px;
  font-size: 20px;
}

.footer1-ads-image-desktopversion {
  display: block ;
}

.footer1-ads-image-Mobileversion{
  display: none ;
}
.footer2-ads-image-desktopversion {
  display: block !important;
}

.footer2-ads-image-Mobileversion{
  display: none !important;
}
.footer3-ads-image-desktopversion {
  display: block !important;
}

.footer3-ads-image-Mobileversion{
  display: none !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .footer-ads-img-one {
    margin-bottom: 30px !important;
    margin-top: 25px !important;
    width: 100% !important;
  }
  .footer-ads-img-one-btn {
    position: absolute;
    top: 87%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 141, 141);
    color: white;
    font-size: 16px;
    /* padding: 12px 24px; */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    
    width: 180px;

  }
  .footer-ads-img-two {
    margin-bottom: 25px !important;
    margin-top: 25px !important;
    width: 100% !important;
  }
  .footer-ads-img-two-btn {
    position: absolute;
    top: 84%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 141, 141);
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  .footer-ads-img-three {
    margin-bottom: 25px !important;
    margin-top: 25px !important;
    width: 100% !important;
  }
  .footer-ads-img-three-btn {
    position: absolute;
    top: 86%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 141, 141);
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  .footer-ads-box {
    background-color: white !important;
    width: 90% !important;
    border: 10px solid #1ec28b !important;
  }

  .footer1-ads-image-desktopversion {
    display: none ;
  }
  
  .footer1-ads-image-Mobileversion{
    display: block ;
  }
  .footer2-ads-image-desktopversion {
    display: none !important;
  }
  
  .footer2-ads-image-Mobileversion{
    display: block !important;
  }
  .footer3-ads-image-desktopversion {
    display: none !important;
  }
  
  .footer3-ads-image-Mobileversion{
    display: block !important;
  }

}



/* Trail css */
/* .footer-ads-btn1 {
  background-color: rgb(255, 141, 141);
  color: white;
  display: flex;
  margin-top: 40px !important;
  margin: auto;
  align-items: center;
  border: none;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.footer-ads-btn1:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-footer-ads-btn1 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-footer-ads-btn1 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.footer-ads-btn1:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}  */
