.stayin{
    background: #2196F3 !important;
    color: white !important;
    /* height: 27px !important;
    border-radius: 5px !important; */
    font-size: 12px!important;
    padding: 16px!important;
}
.admin-logOut{
    background: #b3b9b7 !important;
    color: #060606 !important;
    /* height: 27px !important;
    border-radius: 5px !important; */
    font-size: 12px!important;
    padding: 16px!important;
}