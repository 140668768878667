.forgotContainer {
  background-color: aliceblue;
}
.imageDiv {
  background-color: white;
  height: 100%;
  width: 100%;
}
.cursor_point {
  color: #fff;
  /* font-family:Verdana, Geneva, sans-serif; */
  cursor: pointer;
}

.forgotOuterContainer {
  padding-top: 30px;
  padding-bottom: 145px;
  padding-left: 60px;
}

.forgotImage {
  width: 241px;
  display: flex;
}
.forgot-backIcon {
  display: flex;
}
.forgetEmailLabel {
  margin-top: 10px;
  display: flex;
  text-align: initial !important;
  padding-left: 95px;
}
.forgot-inputEmail {
  width: 70%;
  height: 40px;
  margin-top: 10px;
}
.forgot-emailError {
  color: red;
  margin-top: 5px;
  text-align: initial;
  padding-left: 90px;
}
.forgot-resetButton {
  margin-top: 30px;
  font-size: 20px;
  border: none;
  border-radius: 35px;
  height: 42px;
  font-size: 20px;
  width: 30%;
  background-color: #1ec28b;
  color: #ffffff;
  display: flow-root;
  margin-left: 92px;
}

.backLoginText {
  margin-left: 10px;
  margin-bottom: 20px;
  font-weight: 550px;
}
.forgotInnerContainer {
  width: 60%;
  padding: 2px;
  background-color: white;
  height: 360px;
}
.forgetPasswordHeading {
  text-align: initial;
  font-size: 25px;
  font-weight: 600;
}

.pleaseText {
  font-size: 20px;
  font-weight: 500;
}
input.password {
  margin-bottom: 15px;
}

.return-login-btn {
  padding-left: 60px;
}
/* h2.reset-text {
  margin-top: -50px;
}
h1.forget-text {
  margin-top: -50px;
  margin-right: 280px;
} */

@media screen and (min-width: 320px) and (max-width: 480px) {
  .forgotOuterContainer {
    padding-top: 15px;
    padding-bottom: 125px;
    padding-left: 0px;
  }
  .forgotInnerContainer {
    width: 100%;
    padding: 2px;
    background-color: white;
    height: 360px;
  }
  .forgetPasswordHeading {
    text-align: initial;
    font-size: 16px;
    font-weight: 600;
  }

  .pleaseText {
    font-size: 11px;
    font-weight: 500;
    text-align: initial;
    line-height: 1.5;
  }
  .forgetEmailLabel {
    margin-top: 10px;
    display: flex;
    text-align: initial !important;
    padding-left: 5px;
  }
  .forgot-inputEmail {
    width: 95%;
    height: 40px;
    margin-top: 10px;
  }
  .forgot-emailError {
    color: red;
    margin-top: 5px;
    text-align: initial;
    padding-left: 0px;
  }
  .forgot-resetButton {
    margin-top: 30px;
    font-size: 20px;
    border: none;
    border-radius: 35px;
    height: 30px;
    font-size: 15px;
    width: 50%;
    background-color: #1ec28b;
    color: #ffffff;
  }
}
