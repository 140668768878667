.trip-container {
    background-color: #fff;
    border-radius: 5px;
    display: block;
  }
  .trip-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin-left: 1rem !important;
    width: 93%;
    }
    .admindashboard-chart-heading {
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* text-align: justify; */
      /* margin-top: 20px; */
    }
  .trips-export {
    display: inline-block;
    margin: 0 !important;
  }
  .trip-pieChart {
    width: 85%;
    min-height: 200px;
    margin: 20px;
    margin-top: 10px !important;
    padding: 30px;
    background-color: #fff;
    border: 4px solid #cfcfcf;
    border-radius: 1.5px !important;
    position: relative;
    /*background-color: bisque;*/
  }
  .trip-inputfields {
    display: flex;
    justify-self: space-between;
  }
  .trip-select-box {
    width: fit-content;
    padding: 10px;
    border-radius: 5px;
    background-color: #f3f3f3;
  }
  .trip-select {
    border: none !important;
    width: 80px;
    background-color: inherit !important;
    outline: none;
  }
  .trips-btn-container {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 1rem;
   margin-bottom: 2rem;
   cursor: pointer;
  }
  .trips-btn {
    background-color: #1ec28b;
    color: #ffffff;
    width: 150px;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-right: 2.7rem;
    cursor: pointer;
    letter-spacing: 0.1mm;
  }
  .trip-piecharts {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    padding: 0px !important;
  }
  .trip-piecharts p {
    font-size: 26px;
    width: 84px;
    position: absolute;
  
    left: 592px;
  }
  
.labellist-design tspan{
  color: #06a76c !important;
}

.pie_1 .recharts-pie-label-line{
stroke:black !important;
display:none !important;
}
.pie_2 .recharts-pie-label-line{
  stroke:black !important;
  /* display:none !important; */
  }
.pie_1{
  width: 50%;
}

.pie_2{
  width: 50%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.pie_1 .recharts-pie-label-text{
  fill: black !important;
  display: none !important;
}
.pie_1 .recharts-text tspan{
  font-size: 10px !important;
}
.pie_2 .recharts-pie-label-text{
  fill: black !important;
  /* display: none !important; */
}

.pie_2 .recharts-text{
font-size: 10px;
}

.rangePicker-design{
  width: 8%;
  border: none;
  background-color: transparent;
}

.or_seperator{
  font-weight: bold;
}

.picker-con{
  justify-content:space-between;
  display:flex;
  align-items:center;
  width: 100%;
}


.userType-design ul{
  margin-top: 3rem !important;
  margin-left: 3.3rem !important;

}

.label-ll{
  background-color: aqua !important;
}
.userType-design span{
 color: black !important;
}
.noResult{
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-label{
  display: flex;
  align-items: center;
  justify-content: center;
  /*rotate: -90deg !important;*/
  -webkit-transform: rotate(-90deg);
 /* text-orientation: mixed;
  writing-mode: vertical-lr;*/
  position: absolute;
  left: -2%;
  top: 54%;
  /*height: 10rem;
  width: 20rem;*/
  font-weight: bolder;
 /* background-color: #ababab;*/
}
.source-desti-label-left{
  left: -2%;
  top: 60% !important;
}

.source-desti-label-bottom{
  bottom: 13% !important;
  left: 45% !important;
}

.bottom-label{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 12%;
  left: 45%;
  font-weight: bolder;
}

.bottom-label-monthly{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 2%;
  left: 45%;
  font-weight: bolder;
}

.agewise-label{
  bottom: 7% !important;
}


.trip_auto div::before{
  border: none !important;
}

.trip_auto div::after {
  border-bottom: none !important;
}

.auto-back-trip{
  display: flex;
  justify-content:space-around;
  align-items: center;
  background-color: #f3f3f3;
  padding: 0.5rem 0.3rem 0.5rem 0.5rem;
  border-radius: 6px;
}

.prefer-con{
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.prefer-text{
  font-weight: bold;
}
.prefer-option{
  text-align: initial;
  padding: 1rem 0.5rem 1rem 0.5rem;
}

.preoption{
  border-bottom: 1px solid #06a76c;
}

.prefer{
  width: 40% !important;
}


.autoomplte-con{
  display:flex;
  justify-content: space-around;
  width: 50%;
}


/* .Auto_w_reports-trips{
  padding-bottom: 1rem;
} */


.labels{

  background-color: #1ec28b;

}

.tooltip_custom{
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  width: 100px;
  border-radius: 6px;
  border: 1px solid #c7c7c7;
}
.TT-label{
text-align: initial;
color:#ababab !important;
font-size: 13px;
margin-top: 0.5rem; 
margin-bottom: 0.2rem; 
}

.gender_count{
  display: flex;
  flex-direction: column;
}
.TT-left-label{
width: 60%;
}
.tooltip-head{
  text-align: start;
}
.TT-right-label{
  width: 40%;
  }
.tooltip-parent{
  display: flex;
  width: 100%;
  /* background-color: aqua; */
}
.barModal{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 1;
  opacity: 0.8;
}

