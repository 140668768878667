.explore-boxdiv {
  width: 100% !important;
}
.explore-tabName-us {
  font-size: 20px !important;
  color: black !important;
  font-weight: 600 !important;
  font-family: sans-serif !important;
  border-left: 4px solid #1ec28b !important;

}
.explore-tabName-india {
  font-size: 20px !important;
  color: black !important;
  font-weight: 600 !important;
  font-family: sans-serif !important;
  /* border-left: 4px solid #1ec28b !important; */
  height: 35px !important;
}



.explore-tabName {
  font-size: 20px !important;
  color: black !important;
  font-weight: 600 !important;
  font-family: sans-serif !important;
  border-left: 4px solid #1ec28b !important;
  height: 35px !important;
}
.explore-tabPnelContainer {
  background-color: rgb(30 194 139 / 10%) !important;
  margin-top: 25px !important;
}

.explore-MainContainer {
  display: flex;
  justify-content: center;
  column-gap: 62px;
  margin-top: 25px !important;
}

.explore-PostContainer {
  display: contents;
  columns: 2 auto !important;
  margin-top: 45px !important;
  margin-bottom: 70px !important;
}
.explore-PostsCard {
  /* width: 45% !important;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 90px !important;
  display: inline-block;
  box-shadow: 0px 10px 1px -1px #164938 !important; */
  width: 40% !important;
  height: auto;
  /* margin-left: auto; */

  /* margin-right: auto; */
  /* margin-top: 30px; */
  margin-bottom: 90px !important;
  /* display: inline-block; */
  box-shadow: 0px 10px 1px -1px #164938 !important;
  display: flex;
  flex-direction: column;
}
.explore-contentCat {
  font-size: x-large;
  text-align: initial;
  font-family: sans-serif;
}
.explore-contentplace {
  text-align: left;
  font-size: 15px;
  margin-right: 241px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: sans-serif;
  width: 100%;
}
.explore-card-places {
  text-align: initial;
  line-height: 2;
  font-weight: 600 !important;
}
.explore-homepageIMGContainer {
  height: 200px;
  width: 100%;
}
.explore-latestpostsImage {
  height: 175px;
  width: 175px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin: auto;
}
.explore-latestpostsIMG {
  height: 150px;
  width: 150px;
  padding-top: 12px;
  border-radius: inherit;
}
.explore-homePageConnectBtn {
  /* background-color: #ff8d8d !important;
  color: white !important;
  border: none !important;
  height: 40px !important;
  font-size: 15px !important;
  margin-top: 16px !important;
  border-radius: 9px !important;
  margin-bottom: 50px !important; */
  background-color: #ff8d8d !important;
  color: white !important;
  border: none !important;
  width: 29%;
  height: 40px !important;
  font-size: 15px !important;
  margin-top: 16px !important;
  border-radius: 9px !important;
  margin-bottom: 80px !important;
  justify-content: center !important;
  margin: auto !important;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .explore-tabName-us {
    font-size: 12px !important;
    color: black !important;
    font-weight: 500 !important;
    font-family: sans-serif !important;
  }
  .explore-tabName {
    font-size: 12px !important;
    color: black !important;
    font-weight: 500 !important;
    font-family: sans-serif !important;
    border-left: 3px solid #1ec28b !important;
    height: 35px !important;
  }
  .explore-tabName-india {
    font-size: 12px !important;
    color: black !important;
    font-weight: 500 !important;
    font-family: sans-serif !important;
    /* border-left: 3px solid #1ec28b !important; */
    height: 35px !important;
  }
  .explore-tabPnelContainer {
    background-color: rgb(30 194 139 / 10%) !important;
    margin-top: 25px !important;
  }
  .explore-PostContainer {
    display: block;
    columns: 1 auto !important;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }
  .explore-PostsCard {
    width: 100% !important;
    height: auto;
    margin-bottom: 50px !important;
    box-shadow: 0px 10px 1px -1px #164938 !important;
  }

  .explore-homepageIMGContainer {
    height: 200px;
    width: 100%;
  }
  .explore-latestpostsImage {
    height: 150px;
    width: 150px;
    background: rgb(30 194 139 / 60%);
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    margin: auto;
  }
  .explore-latestpostsIMG {
    height: 125px;
    width: 125px;
    padding-top: 12px;
    border-radius: inherit;
  }
  .explore-homePageConnectBtn {
    background-color: #ff8d8d !important;
    color: white !important;
    border: none !important;
    width: 45%;
    height: 40px !important;
    font-size: 15px !important;
    margin-top: 16px !important;
    border-radius: 9px !important;
    margin-bottom: 80px !important;
    justify-content: center !important;
    margin: auto !important;
}
}
