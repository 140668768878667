.admindashboard-profile-main {
  display: flex;
}
.admindashboard-heading {
  font-size: 30px;
  text-align: justify;
  margin-top: 20px;
}
.admindashboard-register {
  font-size: 24.5px !important;
  font-weight: 600 !important;
  font-family: sans-serif;
  text-align: justify !important;
  margin-top: 38px !important;
  padding-top: 40px;
}
.admin-subhead {
  color: #1ec28b;
  font-weight: 600;
}
.admindashboard-highlight {
  color: black;
  font-weight: 600;
  font-size: 42px;
}

.admindashboard-para1 {
  font-size: 14px !important;
  font-weight: 400;
  text-align: initial;
  margin-bottom: -14px;
}

.admindashboard-place1 {
  color: #1ec28b;
  font-size: 30px;
  font-weight: 600;
}

.admindashboard-subpara1 {
  font-weight: 600;
  font-size: 19.4px;
}

.admindashboard-register {
  font-size: 20px;
  text-align: justify;
  margin-top: 50px;
}
.admindashboard-box {
  background: white;
  width: 102%;
  height: fit-content;
  margin-top: 35px;
  padding-left: 63px;
  margin-left: -36px;
  margin-bottom: 20px;
  border-radius: 8px;
  padding-bottom: 65px;
}

.admindashboard-card1 {
  justify-content: space-around;
  width: 38%;
  height: 82px;
  /* background: rgba(53, 211, 158, 0.268) !important; */
  background-color: #e8f9f3 !important;
  margin-top: 39px;
  margin-bottom: 20px !important;
  display: flex;
  align-items: center;
  border-radius: 10px !important;
  box-shadow: none !important;
}
.admindashboard-card2 {
  justify-content: space-around;
  width: 51%;
  height: 87px;
  /* background: rgb(30 194 139 / 20%) !important; */
  background-color: #e8f9f3 !important;
  margin-top: 39px;
  display: flex;
  align-items: center;
  box-shadow: none !important;
}
.admindashboard-card3 {
  justify-content: space-around;
  width: 36%;
  height: 87px;
  /* background: rgb(30 194 139 / 20%) !important; */
  background-color: #e8f9f3 !important;
  margin-top: 20px;
  display: flex;
  align-items: center;
  box-shadow: none !important;
}
/* .admindashboard-gap-alignment {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
} */
.admindashboard-circularinputs {
  display: flex;
  margin-top: 50px !important;
  /* margin-left: 15px !important;
  margin-right: 265px !important; */
  margin-left: -107px !important;
  /* margin-right: 302px !important; */
}

.admindashboard-circularinputsouter {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.admindashboard-circularinputsprogressbar {
  width: 200px !important;
  height: 200px !important;
  color: blue !important;
  border-radius: 100%;
  box-shadow: inset 0 0 0px 5px lightgray;
  background: transparent;
}
.admindashboard-circularinputsTextMain {
  position: absolute !important;
  display: flex !important;
  flex-direction: column !important;
  color: #1ec28b;
  font-weight: 900 !important;
}
.admindashboard-circularinputsText {
  display: flex;
  font-size: 14px;
  color: gray;
  font-weight: 800;
}

.admindashboard-grid1 {
  border: 3.5px solid #cfcfcf;
  height: 126px;
  width: 57%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: none !important;
}
.admindashboard-tripsdatecard {
  /* background: #838fd0 !important; */
  background-color: #99a7ff !important;
  color: white !important;
  height: 277px;
  margin-left: -16px;
}
.admindashboard-tripsbarcard {
  display: flex;
  align-items: center;
  margin-left: -16px;
  border: 2px solid darkgray;
  position: absolute;
  width: 34.2%;
  height: 280px;
}
.admindashboard-weekanddate {
  margin-top: 18px;
  margin-bottom: 34px;
  font-size: 17px;
  /* font-weight: 600; */
  opacity: 0.9;
  letter-spacing: 0.5px;
}
.admindashboard-tripceated-datewise {
  column-gap: 40px;
  margin-top: 10px !important;
  justify-content: center;
}
.admindashboard-dategrid {
  border: 2px solid darkgray !important;
  margin-top: 16px !important;
  margin-right: 28px;
height: 280px;
}
.admindashboard-datetextfield {
  background: #f3f3f3 !important;
  border: none !important;
  width: 45%;
  font-size: 11px !important;
  padding: 6.5px 51px;
  height: 35px;
  margin-left: -13px;
}
.admindashboard-showresults-btn {
  background: #1ec28b !important;
  color: white !important;
  width: 40%;
  /* height: 30px; */
}
.marginBottom {
  margin-bottom: 10px !important;
}
.admindashboard-grid-subpara1 {
  font-size: 14px;
  margin-top: 10px;
  /* color: #aeaeae; */
  color: #595959;
}

.admindashboard-trip1 {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: -12px !important;
}

.admindashboard-total-trip1 {
  font-size: 13px;
  margin-top: 9px !important;
}

.admindashboard-trip-place1 {
  font-weight: 600;
  font-size: 20px;
}
.small-size .ant-picker {
  /* width: 97% !important; */
  width: 220px !important;
  margin-left: -12px !important;
}
.custom-date-picker .ant-picker {
  border: none !important;
  box-shadow: none !important;
  background-color: #f3f3f3 !important;
  width: 345px;
  height: 34px;
  padding: 20px;
}
.ant-picker input{
  transition: none !important;

}
.ant-picker-input {
  margin-left: 10px;
}

/* .ant-space-item {
  font-size: small;
  margin-left: -16px;
} */
.ant-picker .ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
  display: flex;
  margin-left: -40px !important;
  font-size: medium;
  font-weight: bold !important;
  /* margin-right: 23px */
}
.ant-picker .ant-picker-input {
  font-size: 12px;
  margin-right: 20px;
}

.small-size .ant-picker .ant-picker-input {
  font-size: large;
  margin-right: -20px;
}
/* .custom-date-text-alignment {
  text-align: center !important;

  white-space: pre-wrap !important;
  text-overflow: ellipsis !important;
} */
.custom-date-picker {
  /* border: 0px solid lightgray;
  background-color: whitesmoke; */
  /* padding: -16.5px 81px; */
  margin-top: 14px;
  border-radius: 8px;
  padding-left: 18px;
  margin-left: -47px;
  height: 40px;
  text-align: center;
}
.back2Top-arrow{
  margin-left: 8px;
}
.admindashboard-side-head {
  /* margin-top: 1725px; */
  /* margin-bottom: -10px;
  font-size: x-small;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center; */
  position: fixed;
  bottom: 0;
  left: 99px;
  margin-bottom: 28px;
  color:#6c6b6b;
  font-size: x-small;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiBreadcrumbs-separator {
  font-size: 25px;
}
.admindashboard-counts {
  font-size: 300%;
  font-weight: 500;
}
.path {
  font-size: large;
}
/* :where(.css-dev-only-do-not-override-190m0jy).ant-picker .ant-picker-input >input{
  font-weight: 600;
} */
.anticon svg {
  margin-left: -25px !important;
  font-size: large;
}
.admindashboard-side-head-arrow {
  margin-left: 90px;
  margin-bottom: -17px;
}
/* bar graph */

.admin-bar-graph.with-margin .apexcharts-legend {
  margin-bottom: -13px;
  padding-bottom: 10px;
}
.admin-bar-graph .apexcharts-menu-icon {
  display: none !important;
}
/* .admin-bar-graph .apexcharts-canvas {
  border: 2px solid #000;
} */
.admin-bar-graph .apexcharts-inner {
  border: 2px solid #000;
}
.admin-bar-graph:not(svg) {
  text-decoration: none;
}
.admin-bar-graph {
  display: flex;
  justify-content: center;
  margin-top: -14px;
  height: 250px !important;
}

.bar-graph-left-label {
  display: flex;
  align-items: center;
  justify-content: center;
  /*rotate: -90deg !important;*/
  -webkit-transform: rotate(-90deg);
  /* text-orientation: mixed;
  writing-mode: vertical-lr;*/
  font-size: 12px !important;
  position: absolute;
  left: -51%;
  top: 43%;
  /*height: 10rem;
  width: 20rem;*/
  font-weight: bolder;
  /* background-color: #ababab;*/
  position: relative;
}

.bar-graph-bottom-label {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -84%;
  left: 1%;
  font-size: 12px !important;
  font-weight: bolder;
  position: relative;
}

.anticon-close-circle {
  display: none !important;
}


.bar-graph-top-line::before {
  content: "";
  position: absolute;
  width: 61%;
  height: 61%;

  border: 2px solid #949494;
  margin-left: -142px;
  margin-bottom: -19px;
}

.squareBar-dashboard {
  width: 53%;
  height: 166px;
  border: 1.5px solid #bfbfbf;
  position: absolute;
  left: 23%;
  bottom: 29.5%;
}

.trip-piecharts-reports {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* margin: 0 0; */
  padding: 0px !important;
}

.barChart-dashboard{
  margin-left: 2rem;
}

.left-label-dashboard{
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: rotate(-90deg);
  position: absolute;
  left: -1%;
  top: 41%;
  font-weight: bolder;
  font-size: 13px;
}
.Admin-bar-xaxis text{
color: #000000 !important;
font-weight: bold;
fill: 0 !important;
}

.Admin-bar-yaxis text{
  color: #000000 !important;
  font-weight: bold;
  fill: 0 !important;
  }

.bottom-label-dashboard{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 14%;
  left: 32%;
  font-weight: bolder;
  font-size: 13px;
}

.dashboard-graph-arrow{
  font-size: 18px;
  margin-left: 5px;
}

.legend-square{
  width: 6px;
  height: 6px;
}

.legend-name{
  font-size: 10px;
  margin-left: 0.5rem;
  color: #000;
  font-weight: bold;
}

.legend-con{
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.legend-parent-con{
  display: flex;
  justify-content: center;
}

.dashboard-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
 }
 .dashboard-btn {
  background-color: #1ec28b;
  color: #ffffff;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-right: 2.7rem;
  cursor: pointer;
  letter-spacing: 0.1mm;
}

