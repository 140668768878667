.sent-myprofile-main {
  display: flex;
}
.invitation-sentlist-formob {
  display: flex !important;
}

.sent-ListBox {
  background-color: rgb(30 194 139 / 10%) !important;
  height: auto;
  width: 700px;
  margin: auto;
  margin-top: -25px;
  border-radius: 20px;
  margin-bottom: 25px;
}

.sent-ListText {
  text-align: start;
  padding-top: 34px;
  font-weight: 600;
  font-family: sans-serif;
  font-size: 25px;
  margin-left: 25px;
}

.sent-Listsearchbtn {
  border: 2px solid #1ec28b;
  width: 85%;
  border-radius: 5px;
  height: 50px;
  margin-top: 25px;
  /* margin-left: -47px; */
}

.sent-Listsearchbtnicon {
  margin-left: -40px !important;
  color: #1ec28b !important;
}

.sent-ListmessageContainer-main {
  display: flex !important;
  margin-top: 35px !important;
}

/* 
.sentListmessageGrid {
  padding: 20px;
}

.sentListbox {
  line-height: 1;
  background-color: white;
  width: 610px;
  height: 90px;
  margin-left: 20px;
  border-radius: 10px;
  border: 2px solid #1ec28b !important;
}

.sentListmessageImg {
  height: 51px;
  padding-top: 5px;
  border-radius: inherit;
  width: 86%;
}

.sentListcontainer {
  height: 5px;
  width: 5px;
  margin-left: 18px;
  margin-top: 12px;
}

.sentListmessageinner {
  height: 62px;
  width: 65px;
  background: white;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-left: 145%;
  margin-top: 1%;
  border: 1.5px solid #1ec28b !important;
}

.sentListThara {
  font-family: sans-serif !important;
  font-size: 19px !important;
  font-weight: 600 !important;
}
.sentListTextContainer {
  display: flex;
  flex-direction: column !important;
  align-items: baseline;
  margin-left: 110px;
}

.sentListharaText {
  font: menu !important;
 
  padding-top: 8px !important;
}

.sentListDetailsBTN {
  background-color: #1ec28b;
  margin-top: -35px;
  border: none;
  width: 85px;
  height: 25px;
  border-radius: 15px;
  color: white;
  font-size: 12px;
}
.sentListCancelBTN {
  background-color: #1ec28b;
  margin-top: -35px;
  width: 85px;
  height: 25px;
  border-radius: 15px;
  color: white;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;

 
}
.sentListBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-right: 35px;
  column-gap: 20px;
}
.cardInnerContaineSentlist {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}
.sentListAbdul {
  font-family: sans-serif !important;
  font-size: 19px !important;
  font-weight: 600 !important;
  margin-left: -94px !important;
}

.sentListAbdulText {
  font: menu !important;
  margin-left: -105px !important;
  padding-top: 9px !important;
}

.sentListPallavi {
  font-family: sans-serif !important;
  font-size: 19px !important;
  font-weight: 600 !important;
  margin-left: -143px !important;
}

.sentlistPallaviText {
  font: menu !important;
  margin-left: -118px !important;
  padding-top: 9px !important;
}
.sentListKoushik {
  font-family: sans-serif !important;
  font-size: 19px !important;
  font-weight: 600 !important;
  margin-left: -82px !important;
}

.sentListKoushikText {
  font: menu !important;
  margin-left: -127px !important;
  padding-top: 9px !important;
}
*/
.sentinvitation-upcoming-delete-dalog {
  display: flex;
  justify-content: center;
  column-gap: 30px;
}

.sentinvitation-Hrline {
  height: 1px;
  border-width: 0px;
  color: rgb(30, 194, 139);
  background-color: rgb(30, 194, 139);
  width: 70%;
}

.sentinvitation-yesButton {
  background: #1ec28b !important;
  color: white !important;
  height: 27px !important;
  border-radius: 5px !important;
  border: none;
}
.sentinvitation-noButton {
  background: #1ec28b !important;
  color: white !important;
  height: 27px !important;
  border-radius: 5px !important;
  border: none;
}

.sentinvitation-areUSure {
  display: flex;
  justify-content: center;
}

.sentinvitation-deleteConfirmationIcon {
  margin-top: 1px;
  color: #ff8d8d !important;
  font-size: 27px;
}

.sentinvitation-buttonsContainer {
  margin-bottom: 30px !important;
  justify-content: center !important;
  display: flex !important;
  column-gap: 100px;
} 

.sent-ListmessageGrid {
  margin-top: 10px;
}

.sent-List-messageMainbox {
  line-height: 1;
  background-color: white;
  width: 90%;
  margin: auto;
  height: 75px;
  border-radius: 10px;
  border: 1.5px solid #1ec28b !important;
  padding-top: 4px;
  margin-bottom: 15px;
}

.sent-Listmessagecontainer {
  height: 5px;
  width: 5px;
  margin-left: 18px;
  margin-top: 2px;
}

.sent-Listmessageinner {
  height: 62px;
  width: 65px;
  background: white;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-top: 1%;
  border: 1.5px solid #1ec28b !important;
}

.sent-ListmessageImg {
  height: 52px;
  width: 52px;
  margin-left: 0px;
  padding-top: 5px;
  padding-left: 0px;
  border-radius: inherit;
}

.sent-ListmessageThara {
  font-family: sans-serif !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    text-align: initial;
}
.sent-ListharaText {
  font: menu !important;
  text-align: initial;
}

.sent-ListButton{
  display: flex;
  column-gap: 5px;
}
.sent-ListButtonDetailGrid{
  display: contents;
  justify-content: end;
}

.sent-ListProfile {
  /* background-color: #1ec28b !important;
  color: white !important;
  width: 70px;
  margin-top: 20px !important;
  border-radius: 7px !important;
  height: 25px;
  border: none !important; */
  background-color: #1ec28b !important;
  color: white !important;
  width: 91px;
  margin-top: 40px !important;
  border-radius: 7px !important;
  height: 30px;
  border: none !important;
}

.sent-load-more-btn{
  margin: auto;
    color: white;
    margin-top: 34px;
    background-color: #ff8d8d;
    width: 23%;
    display: flex;
    padding-top: 10px;
    text-align: center;
    border-radius: 5px;
    padding-left: 9px;
    height: 25px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .invitation-sentlist-sidebar-main {
    display: none;
  }
  .invitation-sentlist-formob {
    flex-direction: column-reverse !important;
    display: flex !important;
    width: 100%;
  }
  .sent-Listmessagecontainer {
    /* margin-left: 5px; */
    /* margin-top: 3px; */
    display: contents;
  }
  
  .sent-ListText {
    text-align: start;
    padding-top: 34px;
    font-weight: 600;
    font-family: sans-serif;
    font-size: 25px;
    /* margin-left: 10px; */
  }
  .sent-ListBox {
    background-color: rgb(30 194 139 / 10%) !important;
    height: auto;
    width: 100%;
    margin-top: -25px;
    border-radius: 20px;
    margin-bottom: 25px;
  }
  .sent-ListmessageThara {
    font-family: sans-serif !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    text-align: initial;
  }

  .sent-ListButton{
    display: flex;
    column-gap: 5px;
  }
  .sent-ListButtonDetailGrid{
    display: flex;
    justify-content: end;
  }
  .sent-ListProfile {
    /* background-color: #1ec28b !important;
    color: white !important;
    width: 50px;
    margin-top: 20px !important;
    border-radius: 7px !important;
    height: 25px;
    border: none !important;
    font-size: 10px; */
    background-color: #1ec28b !important;
    color: white !important;
    width: 46px;
    margin-top: 20px !important;
    border-radius: 7px !important;
    height: 22px;
    border: none !important;
    font-size: 9px;
    padding: 4px;
  }
  .sent-ListharaText {
    font: menu !important;
    width: 100% !important;
    display: block;
    flex-direction: column;
    text-align: initial;
}
.sentinvitation-upcoming-delete-dalog {
  display: flex;
  justify-content: center;
  column-gap: 15px;
}
.sentinvitation-buttonsContainer {
  margin-bottom: 30px !important;
  justify-content: center !important;
  display: flex !important;
  column-gap: 60px;
  /* margin-top: -10px; */
}
.sentinvitation-areUSure {
  display: flex;
  justify-content: center;
  margin-top: 0px;
}
  .sent-List-messageMainbox {
    line-height: 1;
    background-color: white;
    width: 98%;
    /* margin: auto; */
    height: 75px;
    border-radius: 10px;
    border: 1.5px solid #1ec28b !important;
    padding-top: 4px;
    margin-bottom: 15px;
  }
  .sent-load-more-btn {
    margin: auto;
    color: white;
    margin-top: 34px;
    background-color: #ff8d8d;
    width: 43%;
    display: flex;
    padding-top: 7px;
    text-align: center;
    border-radius: 5px;
    padding-left: 9px;
    font-size: 13px;
    height: 25px;
}
}