/* .accordionTitle {
  display: flex;
  font-size: 25px;
  line-height: 2;
  font-weight: 500;
  
}
.accordionContent {
  display: flex;
  font-size: 23px;
  margin-left: 27px;
  line-height: 2;
  font-weight: 450;
  margin-top: 25px;
  text-align: initial;
}
.plusMinusIcon {
  
  color: #1ec28b;
  font-size: 30px !important;
}
.accordionIcons {
  margin-left: auto;
  margin-right: 65px;
  margin-top: 10px;
}
.sideMenuAccordionContainer {
  margin-top: 80px !important;
} */
.faq-accordionTitle {
  font-size: 25px;
  line-height: 1.25;
  font-weight: 500;
  text-align: initial;
}
.faq-accordionContent {
display: flex;
font-size: 23px;
line-height: 2;
font-weight: 400;
text-align: initial;
}
.faq-plusMinusIcon {
color: #1ec28b;
font-size: 30px !important;
}
.faq-accordionIcons {
display: flex;
justify-content: end;
margin-top: -20px;
cursor: pointer;
}
.sideMenuAccordionContainer {
margin-top: 80px !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
.faq-accordionTitle {
font-size: 13px;
line-height: 1.25;
font-weight: 600;
text-align: initial;
}

.faq-accordionIcons {
display: flex;
margin: auto;
cursor: pointer;
}

/* .faq-accordionItem{

} */
.faq-plusMinusIcon {
color: #1ec28b;
font-size: 20px !important;
margin-top: -7px;
}


.faq-accordionContent {
display: flex;
font-size: 15px;
line-height: 2;
text-align: initial;
}


}
