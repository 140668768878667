.admin-main {
  margin-top: 45px;
}
.admin-trip-heading {
  font-weight: bold;
  width: 15%;
  color: rgb(12 12 12 / 80%);
  margin-top: 33px;
}
.admin-icon {
  width: 10%;
  height: 22px;
}
.admin-trip-stats {
  display: contents;
}
.admin-trip-stat {
  width: 75% !important;
  margin-top: 36px !important;
  margin-left: 45px;
}
.admin-trip-subheading {
  margin-bottom: 25px;
  margin-top: 5px;
}
.admin-subheading {
  color: #1ec28b;
}
.admin-trip-main {
  display: flex;
  background-color: #f9fbfc;
  /* margin-top: 45px; */
}
.admin-trip-box-main {
  column-gap: 20px;
  display: flex;
  margin: auto;
  justify-content: space-around;
  margin-top: 40px !important;
}
.admin-box {
  border: 2px solid lightgray;
  width: 100% !important;
  height: 100px;
  border-radius: 10px !important;
  color: #1ec28b !important;
  font-weight: bold;
  box-shadow: none !important;
}
/* .admin-companion-srch .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root {
  border-color: #c2c2c2 !important;
} */
/* .admin-companion-srch .MuiOutlinedInput-root {
  border-color: #c2c2c2 !important;
} */
/* this is effecting */
.admin-companion-srch .MuiOutlinedInput-notchedOutline {
  border-color: #c2c2c2 !important;
}
.admin-trip-number {
  font-size: 30px;
  margin-top: 10px;
}
.admin-box-color {
  color: grey !important;
  font-weight: bold;
  margin-top: 20px !important;
}

.admin-trip-search {
  width: 50%;
  height: 35px;
}
.admin-trip-srch {
  width: 88% !important;
  margin-top: 35px !important;
  border: 1px solid lightgrey !important;
  border-radius: 8px;
}
.admin-trip-stat {
  width: 75%;
  margin-top: 36px;
  height: 35px;
}
.admin-trip-tbl {
  justify-content: center !important;
  display: flex !important;
}
.admin-trip-tblname {
  border-radius: 10px !important;
  border-collapse: collapse;
  width: 97% !important;
  margin-bottom: 35px;
  margin-top: 20px;
  color: lightgrey;
}
.admin-trip-table {
  border: 1px solid lightgrey;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 35px;
  margin-top: 25px;
  border-radius: 20px !important;
  justify-content: center;
  align-items: center;
}
.admin-trip-border {
  border: 1px solid lightgray;
  background: rgb(211 211 211 / 30%);
  border-collapse: collapse;
  height: 35px !important;
}

.admin-trip-border1 {
  border: 1px solid lightgrey !important;
  border-collapse: collapse !important;
}
.admin-trip-border2 {
  border: 1px solid lightgray !important;
  border-collapse: collapse !important;
}

.admin-trip-border3 {
  border: 1px solid lightgray;
  border-collapse: collapse;
}

.admin-trip-icon1 {
  color: white;
  border-radius: 3px;
  width: 25px;
  height: 20px;
  background: rgb(255 191 0 / 50%) !important;
  cursor: pointer;
}
.admin-trip-cmicon {
  /* column-gap: 10px; */
  display: flex;
  /* align-items: baseline; */
  justify-content: center;
  margin-top: 27px;
  margin-bottom: 21px;
}
.admin-trip-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 40px !important;
  color: white !important;
}
.admin-trip-img {
  font-size: 10px;
}
.admin-td-image {
  width: 20%;
}
.admin-trip-modal {
  display: flex !important;
  justify-content: center !important;
}
.admin-trip-box {
  border-radius: 20px;
  height: auto !important;
  width: 100%;
  box-shadow: none;
  /* margin-top: 5px; */
}
.admin-wrong {
  justify-content: end;
  display: flex;
  font-size: 40px;
  cursor: pointer;
  padding: 10px;
}
.admin-subhead {
  display: flex;
  margin-left: 12px;
  gap: 22px;
}
.admin-trip-name {
  /* display: flex; */
  margin-top: 17px;
  margin-bottom: 20px;
  text-align: start;
  font-size: 14px;
  height: auto;
  margin-left: 12px;
  /* word-wrap: break-word; */
}
.admin-trip-img-start {
  display: flex;
  justify-content: center;
  align-items: center !important;
  width: 100%;
  column-gap: 20px;
  margin-top: 18px;
  margin-bottom: 18px;
}
.admin-trip-img-start1 {
  align-items: center !important;
  width: 100%;
  column-gap: 20px;
  margin-top: -27px;
}

.admin-trip-head {
  display: flex;
  gap: 15px;
  padding: 8px;
  align-items: center;
}
.admin-trip-popup-image {
  border: 1px solid lightgrey !important;
  border-radius: 119px;
  height: 40px;
}
.admin-description {
  color: grey;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 30px;
}
.admin-color {
  color: blue;
}
.admin-descr {
  /* display: flex; */
  word-wrap: break-word !important;
  font-size: 14px;
  text-align: justify;
  margin-left: 12px;
}
.admin-popup-start {
  display: flex;
  align-items: center !important;
  width: 40%;
  column-gap: 20px;
  height: 20px;
}
.admin-desc {
  font-weight: 600;
  font-size: 14px;
  row-gap: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 12px;
  display: flex;
}
.admin-name {
  font-weight: bold;
}
.admin-heading {
  margin-top: 28px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  text-align: initial;
  color: #000;
}
.admin-trip-image {
  border: 1px solid lightgray;
  width: 30%;
  border-radius: 10px;
  height: 50px;
}
.admin-trip-img {
  justify-content: center;
  display: flex;
  width: 56%;
}
.admin-trip-front-image {
  border: 1px solid lightgrey !important;
  border-radius: 119px;
  height: 40px;
}
.admin-email {
  font-size: 14px;
}
.search-img {
  width: 17%;
  justify-content: center;
  margin-bottom: 20px;
}
.trip-no-results-found {
  font-size: 20px;
  font-weight: 600;
  color: rgb(229, 86, 50);
  margin-bottom: 20px;
}
.search-results-found-main-div {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}
.admin-src-image {
  border-radius: 20px;
  border: 1px solid grey;
  margin-top: 18px;
  /* width: 74%; */
  margin-bottom: 20px;
  height: 132px;
}
.admin-main-btn {
  display: flex !important;
  justify-content: center;
  margin-top: 37px;
  column-gap: 15px;
  margin-bottom: 40px;
}

.admin-trip-button {
  /* border: 1px solid grey !important; */
  background: #1ec28b !important;
  color: #f7fdfb !important;
  width: 55% !important;
  display: flex !important;
  justify-content: center !important;
  font-weight: 600 !important;
  letter-spacing: 1.2px !important;
}
.admin-trip-bttn {
  width: 92% !important;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 25px;
}
.admin-modalBackground {
  width: 100%;
  height: 70rem;
  background-color: rgba(226, 214, 214, 0.447);
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -708px;
  margin-bottom: -360px;
  z-index: 2;
}

.admin-modalContaner {
  width: 32%;
  height: max-content !important;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-bottom: 220px;
}
.admin-modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}
.admin-titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}
.admin-titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 23px;
  color: pink;
  cursor: pointer;
  margin-top: -13px;
}
.admin-modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}
.admin-modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: rgb(32, 90, 197);
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}
#admin-cancelBtn {
  background-color: crimson;
}

.admin-trip-stat .css-16awh2u-MuiAutocomplete-root {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #c2c2c2 !important;
}

.admin-trip-stat .MuiOutlinedInput-root {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-color: #c2c2c2 !important;
}

.admin-trip-stat label {
  top: -6px;
  margin-left: 12px;
}

.custom-notched-outline .MuiOutlinedInput-notchedOutline {
  border: 1px solid #c2c2c2 !important;
}

