.dashboard-main {
    display: flex;
  }
  .settings-mobile-version {
    display: contents;
  }
  .setting-heading {
    font-size: 25px;
    font-weight: 600;
    text-align: initial;
    margin-left: 15px;
  }
  .setting-changepassword-heading {
    display: flex;
    text-align: initial;
    margin-bottom: 30px;
    margin-left: 25px;
    margin-top: 50px;
  }
  .setting-labels {
    font-size: 13px;
    color: #1ec28b;
  }
  .setting-inside-grid {
    text-align: initial;
    width: 95%;
    margin: auto;
    margin-top: 30px;
  }
  .setting-textfield {
    border-bottom-color: #1ec28b !important;
    width: 100%;
  }
  .setting-outside-container {
    border: 1.5px solid pink !important;
    height: auto;
    border-radius: 10px;
  }
  .setting-inside-container {
    border: 1.5px solid #1ec28b !important;
    height: auto;
    width: 90%;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  .save-btn-container {
    padding: 40px;
  }
  /* .setting-save-btn:hover{
    cursor: pointer;
  } */
  
  .setting-save-btn {
    background-color: #1ec28b;
    color: white;
    border: none !important;
    width: 20%;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .setting-delete-btn-container {
    /* margin-top: 25px; */
    /* margin-bottom: 100px !important; */
    justify-content: center;
    column-gap: 40px;
    padding: 30px;
    /* justify-content: space-evenly; */
    display: flex;
  }
  
  .setting-delete-ac-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .setting-delete-btn {
    background-color: #1ec28b;
    color: white;
    border: none;
    width: 15%;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  .admin-changePassword-Heading{
    /* margin-top: 30px;
    margin-bottom: 30px; */
    text-align-last: start;
    margin-left: 25px;
    font-weight: 600;
    font-size: larger;
    font-family: sans-serif;
  }
  @media screen and (min-width: 320px) and (max-width: 480px) {
    .siderbar-mobile-version {
      display: none;
    }
    .settings-mobile-version {
      display: flex;
      flex-direction: column-reverse;
    }
    .setting-save-btn {
      background-color: #1ec28b;
      color: white;
      border: none;
      width: 30%;
      font-size: 15px;
      border-radius: 5px;
    }
    .setting-delete-btn {
      background-color: #1ec28b;
      color: white;
      border: none;
      width: 30%;
      font-size: 15px;
      border-radius: 5px;
    }
  }
  