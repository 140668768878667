.connect-invitation-myprofile-main {
  display: flex;
}

.connect-invitation-formob {
  display: contents;
}
.connectedListBox {
  background-color: rgb(30 194 139 / 10%) !important;
  height: auto;
  width: 700px;
  margin: auto;
  margin-top: -25px;
  border-radius: 20px;
  margin-bottom: 25px;
  margin-left: 15px;
  margin-right: 15px;
}

.connectedListText {
  text-align: start;
  margin-left: 35px;
  padding-top: 34px;
  font-weight: 600;
  font-family: sans-serif;
  font-size: 25px;
}

.connectedListsearchbtn {
  border: 2px solid #1ec28b;
  width: 88%;
  border-radius: 5px;
  height: 50px;
  margin-top: 25px;
  /* margin-left: -94px; */
}

.connectedListsearchbtnicon {
  margin-left: -40px !important;
  color: #1ec28b !important;
}

.connectedListmessageContainer-main {
  display: flex !important;
  flex-direction: column !important;
  margin-top: 18px !important;
}

.connectedListmessageGrid {
  margin-top: 10px;
}

.connectedList-messageMainbox {
  line-height: 1;
  background-color: white;
  width: 90%;
  margin: auto;
  height: 75px;
  border-radius: 10px;
  border: 1.5px solid #1ec28b !important;
  padding-top: 4px;
  margin-bottom: 15px;
}

.connectedListmessagecontainer {
  height: 5px;
  width: 5px;
  margin-left: 18px;
  margin-top: 2px;
}

.connectedListmessageinner {
  height: 62px;
  width: 65px;
  background: white;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-top: 1%;
  border: 1.5px solid #1ec28b !important;
}

.connectedListmessageImg {
  height: 52px;
  width: 52px;
  margin-left: 0px;
  padding-top: 5px;
  padding-left: 0px;
  border-radius: inherit;
}

.connectedListmessageThara {
  font-family: sans-serif !important;
  font-size: 19px !important;
  font-weight: 600 !important;
  /* text-align: initial; */
}

.connectedListTharaText {
  font: menu !important;
  /* text-align: initial; */
  padding-top: 10px;
}

.connectedListProfile {
  background-color: #1ec28b !important;
  color: white !important;
  width: 100px;
  margin-top: 18px !important;
  border-radius: 7px !important;
  height: 30px;
}

.connectedList-load-more-btn{
  margin: auto;
    color: white;
    margin-top: 20px;
    background-color: #ff8d8d;
    width: 16%;
    display: flex;
    padding-top: 10px;
    text-align: center;
    border-radius: 5px;
    padding-left: 9px;
    height: 25px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .connect-invitation-sidebarlink {
    display: none;
  }
  .connect-invitation-formob {
    flex-direction: column-reverse !important;
    display: flex !important;
  }
  .connect-invitation-myprofile-main {
    display: block;
  }
  .connectedListBox {
    background-color: rgb(30 194 139 / 10%) !important;
    height: auto;
    width: 100%;
    margin: auto;
    margin-top: -25px;
    border-radius: 20px;
    margin-bottom: 25px;
  }

  .connectedListProfile {
    background-color: #1ec28b !important;
    color: white !important;
    width: 77px;
    margin-top: 18px !important;
    border-radius: 7px !important;
    height: 25px;
  }
  .connectedList-messageMainbox {
    line-height: 1;
    background-color: white;
    width: 95%;
    margin: auto;
    height: 75px;
    border-radius: 10px;
    border: 1.5px solid #1ec28b !important;
    padding-top: 4px;
    margin-bottom: 15px;
  }
  .connectedList-load-more-btn {
    margin: auto;
    color: white;
    margin-top: 7px;
    background-color: #ff8d8d;
    width: 27%;
    display: flex;
    padding-top: 7px;
    text-align: center;
    border-radius: 5px;
    padding-left: 9px;
    font-size: 13px;
    height: 25px;
}
}