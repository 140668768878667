.pasttripdashboard-main {
  display: flex;
}
.pasttrip-maincontainer {
  display: flex;
}
.pastdashbox {
  width: 700px;
  height: auto;
  background-color: aliceblue;
  margin-top: -46px;
  margin-left: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  margin-right: 15px;
}

/* Recent Invites */
.pasttriprecieved-text {
  text-align: left;
  margin-left: 7%;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-top: 45px;
  padding-top: 35px;
}

.pasttripcard-past-trip1 {
  display: inline-block;
  width: 38% !important;
  margin-bottom: 30px !important;
  height: 650px;
  margin-left: 16px;

  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
  padding-right: 10px;
}

.past-trip-loading {
  font-size: 20px;
  width: max-content;
  margin-left: 80%;
}

.pasttripcard-recentcontainer {
  height: 150px;
  width: 150px;
}
.pasttripcard-past-trip-inner {
  height: 125px;
  width: 125px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-left: 31%;
  margin-top: 5%;
}
.pasttripcardrecent-img {
  height: 100px;
  width: 100px;
  padding-top: 12px;
  border-radius: inherit;
}
.pasttrip-card-places {
  text-align: initial;
  line-height: 3;
  font-weight: 600;
}


.pasttripaccept-btn {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 21px !important;
  border: none;
  font-size: 11px !important;
  height: 26px;
}

.pasttripcancel-btn {
  background-color: white !important;
  border: 1px solid #164938 !important;
  color: black !important;
  border-radius: 21px !important;
  border: none;
  font-size: 9px !important;
  height: 20px;
  margin-left: 19px !important;
  margin-top: 10px !important;
}
.pasttripaccept-load-more-btn{
  margin: auto;
    color: white;
    margin-top: 34px;
    background-color: #ff8d8d;
    width: 15%;
    display: flex;
    padding-top: 10px;
    text-align: center;
    border-radius: 5px;
    padding-left: 9px;
    cursor: pointer;
}


@media screen and (min-width: 320px) and (max-width: 480px) {
  .pastrip-dashboardside {
    display: none;
  }
  .pasttrip-maincontainer {
    display: flex;
    flex-direction: column-reverse;
  }
  .pasttripdashboard-main {
    display: block;
  }
  .pastdashbox {
    width: 100%;
    height: auto;
    background-color: aliceblue;
    border-radius: 10px;
    margin: auto;
  }
  /* .card-past-trip1 {
    display: block;
    width: 90% !important;
    height: 554px;
    box-shadow: 0px 10px 1px -1px #164938 !important;
    margin: auto;
    margin-bottom: 30px;
  } */
  .pasttripcard-past-trip1 {
    display: block;
    width: 90% !important;
    margin-bottom: 30px !important;
    height: auto;
    margin-left: 0px;
    margin-top: 15px;
    box-shadow: 0px 10px 1px -1px #164938 !important;
    padding-right: 10px;
    margin: auto;
}
.pasttripaccept-load-more-btn {
  margin: auto;
  color: white;
  margin-top: 34px;
  background-color: #ff8d8d;
  width: 28%;
  display: flex;
  padding-top: 7px;
  text-align: center;
  border-radius: 5px;
  padding-left: 9px;
  font-size: 13px;
  height: 25px;
}
}
