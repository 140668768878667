.invitation-dashboard-main {
  display: flex;
}
.invitationRecieveddashbox {
  width: 700px;
  height: auto;
  background-color: aliceblue;
  margin-top: 0px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.invitation-search-btn-recievelist {
  border: 2px solid #1ec28b;
  width: 90%;
  border-radius: 5px;
  height: 50px;
  margin-top: 11px;
  /* margin-left: 22px; */
}

.invitation-search-btn-recievelist-icon {
  margin-left: -40px !important;
  color: #1ec28b !important;
}

/* Recent Invites */
.invitation-recieved-text {
  text-align: left;
  margin-left: 5%;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-top: 45px;
  padding-top: 35px;
}

.invitation-card-receivedList-main-card {
  display: contents;
}

.invitation-card-receivedList1 {
  width: 42% !important;
  margin-bottom: 30px;
  height: auto;
  margin-left: 42px;
  margin-top: 51px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
}

.invitation-card-deletebutton {
  text-align: end;
  color: pink;
}

.invitation-card-recentcontainer {
  height: 150px;
  width: 100%;
}
.invitation-card-receivedList-inner {
  height: 125px;
  width: 125px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  /* margin-left: 40%; */
  /* margin-top: 12%; */
  margin: auto;
}
.invitation-cardrecent-img {
  height: 110px;
  padding-top: 8px;
  border-radius: inherit;
  width: 90%;
}

.invitation-card-places {
  text-align: initial;
  line-height: 3;
  font-weight: 600;
}

.invitation-accept-btn {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 21px !important;
  border: none;
  font-size: 11px !important;
  height: 26px;
  margin-bottom: 25px !important;
}

.invitation-cancel-btn {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 21px !important;
  border: none;
  font-size: 11px !important;
  height: 26px;
  margin-left: 50px !important;
  margin-bottom: 25px !important;
}

/* ==========================delete modal */
.modal-size {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}

.invitation-upcoming-delete-dalog {
  display: flex;
  justify-content: center;
  column-gap: 30px;
}

.invitation-Hrline {
  height: 1px;
  border-width: 0px;
  color: rgb(30, 194, 139);
  background-color: rgb(30, 194, 139);
  width: 70%;
}

.invitation-yesButton {
  background: #1ec28b !important;
  color: white !important;
  height: 27px !important;
  border-radius: 5px !important;
}
.invitation-noButton {
  background: #1ec28b !important;
  color: white !important;
  height: 27px !important;
  border-radius: 5px !important;
}

.invitation-areUSure {
  display: flex;
  justify-content: center;
}

.invitation-deleteConfirmationIcon {
  margin-top: 1px;
  color: #ff8d8d !important;
  font-size: 27px;
}

.invitation-buttonsContainer {
  margin-bottom: 30px !important;
  justify-content: center !important;
  display: flex !important;
  column-gap: 100px;
}

/* ========================accept modal */

.modal-size {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}

.invitationaccept-upcoming-delete-dalog {
  display: flex;
  justify-content: center;
  column-gap: 30px;
}

.invitationaccept-yesButton {
  background: #1ec28b !important;
  color: white !important;
  height: 27px !important;
  border-radius: 5px !important;
}
.invitationaccept-noButton {
  background: #1ec28b !important;
  color: white !important;
  height: 27px !important;
  border-radius: 5px !important;
}

.invitationaccept-areUSure {
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 400;
}

.invitationaccept-deleteConfirmationIcon {
  margin-top: 1px;
  color: #ff8d8d !important;
  font-size: 27px;
}

.invitationaccept-buttonsContainer {
  margin-bottom: 30px !important;
  justify-content: center !important;
  display: flex !important;
  column-gap: 100px;
}

.my-swal {
  background: rgb(255 255 255) !important;
  backdrop-filter: blur(2px);
}
.invitation-recievedlist-formob {
  display: flex;
}

.invitation-load-more-btn {
  margin: auto;
  color: white;
  margin-top: 34px;
  background-color: #ff8d8d;
  width: 23%;
  display: flex;
  padding-top: 10px;
  text-align: center;
  border-radius: 5px;
  padding-left: 9px;
  height: 25px;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .invitationRecieveddashbox {
    width: 100%;
    height: auto;
    margin-top: -46px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  .invitation-dashboard-main {
    display: contents;
  }
  .invitation-recievedlist-main {
    display: none;
  }
  .invitation-recievedlist-formob {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .invitation-card-receivedList-main-card {
    display: block;
  }
  .invitation-card-receivedList1 {
    width: 90% !important;
    margin-bottom: 25px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    box-shadow: 0px 10px 1px -1px #164938 !important;
  }
  .invitation-load-more-btn {
    margin: auto;
    color: white;
    margin-top: 34px;
    background-color: #ff8d8d;
    width: 43%;
    display: flex;
    padding-top: 7px;
    text-align: center;
    border-radius: 5px;
    padding-left: 9px;
    font-size: 13px;
    height: 25px;
  }

  .invitationaccept-areUSure {
    display: flex;
    justify-content: center;
    text-align: initial;
    font-weight: 400;
  }

  .invitationaccept-buttonsContainer {
    margin-bottom: 30px !important;
    justify-content: center !important;
    display: flex !important;
    column-gap: 25px;
  }
}
