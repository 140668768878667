.yerString{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f3f3f3;
    padding: 0.8rem;
    border-radius: 5px;
    color: #4e4e4e;
    font-family: sans-serif;
    width: 240px;
    font-size: 15px;
    position: relative;
    /* z-index: 2; */
}

.calender{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding:  0rem 0.5rem 0rem 0.5rem;
}

.prefer-drop-down{
    display: flex;
   flex-direction: column;
    /* padding:  0rem 0.5rem 0rem 0.5rem; */
}

.prefer-drop-down-con{
    border: 1.5px solid #ededed;
    /* padding: 0.5rem; */
    /* padding-top: 2rem; */
    border:1px solid #1EC28B;
    position: absolute;
    top: 100%;
    left: 0%;
    width: 310px;
    z-index: 1;
    background-color: #ffffff;
}

.calender-con{
    border: 1.5px solid #ededed;
    /* padding: 0.5rem; */
    padding-top: 2rem;
    /* position: absolute;
    top: 100%;
    left: 0%; */
    width: 263px;
    /* z-index: 10; */
    background-color: #ffffff;
    border-radius: 5px;
}
.calendar-nav{
    border-bottom:1.5px solid #ededed; 
    height: 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom:85%;
    padding: 0rem 0rem 0rem 0rem;
    /* background-color:    aqua; */
}

.yearString{
    margin-bottom:0.8rem;
    margin-top: 0.5rem;
}
.year-span{
    text-align: center;
    padding: 0.3rem;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    /* margin: 0.6rem; */
}

.year-span-disabled{
    color: rgb(189, 187, 187);
    cursor: not-allowed;
}

.year-span-selected{
    background-color:#1EC28B;
}
.yearString-selected{
background-color: #8ee0c5;
color: white;
}

.yearLabel{
    margin-left: 1.5rem;
}

.arrows-calender{
    margin-right: 1.5rem; 
    width: 25%;
    display: flex;
    justify-content: space-between;
    /* margin-bottom:x 0.5rem;  */
}

.calender-icon{
cursor: pointer;
}



.code-box-demo .demo {
    overflow: auto;
  }
  .code-box-demo .ant-btn {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .code-box-demo .ant-btn-rtl {
    margin-right: 0;
    margin-left: 8px;
    margin-bottom: 8px;
  }
  #components-popover-demo-arrow .ant-btn {
    width: 70px;
    text-align: center;
    padding: 0;
  }

  .ant-popover-inner{
    padding: 0 !important;
    border-radius: 10px !important;
  }

  .calender-left-arrow{
color: #1b1b1b;
  }

  .calender-right-arrow{
    color: #1b1b1b;
  }
  .calender-left-arrow-disable{
    color: #a09c9c;
  }