.dashboard-main {
  display: flex;
}
.dashbox {
  width: 700px;
  height: auto;
  background-color: #edfaf5;
  margin-left: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  margin-right: 15px;
}
.search-btn-dashboard {
  border: 2px solid #1ec28b;
  width: 90%;
  border-radius: 5px;
  height: 50px;
  margin-top: 36px;
  margin-left: -4px;
}

.search-btn-icon {
  margin-left: -40px !important;
  color: #1ec28b !important;
}
.exploreButton {
  background-color: #ff8d8d !important;
  color: white !important;
  border: none !important;
  /* width: 120px !important; */
  height: 40px !important;
  font-size: 15px !important;
  margin-top: 16px !important;
  border-radius: 9px !important;
  margin-bottom: 50px !important;
}

.upcoming-text-dashboard {
  text-align: left;
  margin-left: 6%;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-top: 45px;
}

.card-dash-Uptrip1 {
  display: inline-block;
  width: 110% !important;
  margin-bottom: 30px;
  height: 670px;
  margin-left: 50px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
  padding-right: 10px;
}
.card-dash-Uptrip2 {
  display: inline-block;
  width: 110% !important;
  margin-bottom: 30px;
  height: 670px;
  margin-left: 150px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
  padding-right: 10px;
}
.card-dash-recent1 {
  display: inline-block;
  width: 110% !important;
  margin-bottom: 30px;
  height: 580px;
  margin-left: 50px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
  padding-right: 10px;
}
.card-dash-recent2 {
  display: inline-block;
  width: 110% !important;
  margin-bottom: 30px;
  height: 580px;
  margin-left: 150px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
  padding-right: 10px;
}
.card-Uptripcontainer {
  height: 150px;
  width: 150px;
}
.card-dash-Uptrip-inner {
  height: 125px;
  width: 125px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-left: 40%;
  margin-top: 12%;
}
.carduptrip-img {
  height: 100px;
  padding-top: 12px;
}
.uptrip-name {
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 600;
  margin-top: -16px;
}
.uptrip-date {
  font-size: 14px;
  font-family: sans-serif;
  font-weight: 600;
  margin-top: -12px;
}
.uptrip-city {
  font-size: 13px;
  font-family: sans-serif;
  font-weight: 600;
  margin-top: -12px;
}

/* Recent Invites */
.recent-text-dashboard {
  text-align: left;
  margin-left: 6%;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-top: 45px;
}
.card-recent {
  display: inline-block;
  width: 100% !important;
  margin-bottom: 30px;
  height: 380px;
  margin-left: 129px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
}
.card-recentcontainer {
  height: 150px;
  width: 150px;
}
.card-dash-recent-inner {
  height: 125px;
  width: 125px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-left: 40%;
  margin-top: 12%;
}
.cardrecent-img {
  height: 100px;
  padding-top: 12px;
}
.cardrecent-name {
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 600;
  margin-top: -16px;
}
.cardrecent-date {
  font-size: 14px;
  font-family: sans-serif;
  font-weight: 600;
  margin-top: -20px;
}
.cardrecent-city {
  font-size: 13px;
  font-family: sans-serif;
  font-weight: 600;
  margin-top: -20px;
}
.cardrecent-para {
  font-size: 11px;
  margin-top: -18px;
  width: 198px;
}
.accept-btn {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 21px !important;
  border: none;
  font-size: 11px !important;
  height: 26px;
}

.cancel-btn {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 21px !important;
  border: none;
  font-size: 11px !important;
  height: 26px;
  margin-left: 50px !important;
}

/* Profile  */
.card-profile {
  display: inline-block;
  width: 80% !important;
  margin-bottom: 30px;
  height: 250px;
}
.card-profilecontainer {
  height: 150px;
  width: 150px;
}
.card-profile-inner {
  height: 150px;
  width: 150px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin-left: 25%;
  margin-top: 5%;
}
.cardprofile-img {
  height: 110px;
  padding-top: 20px;
}
.profile-name {
  font-family: sans-serif;
  font-size: 25px;
  margin-left: 27px;
  font-weight: 600;
  margin-top: 10px !important;
}
.profile-edit-btn {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 5px !important;
  border: none;
  font-size: 11px !important;
  margin-left: 33px !important;
  margin-top: 15px !important;
}
.dashboard-page-upcoming {
  display: flex !important;
  /* text-align: initial; */
}
.recentlist-dashboard-exploremore {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 40px;
}

.dashboard-upcoming-card {
  display: inline-block;
  width: 40% !important;
  margin-bottom: 30px;
  height: auto;
  margin-left: 85px;
  margin-right: -65px;
  margin-top: 15px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
}

/* New CSS */
.dashboard-formobile-main {
  display: contents;
}

.dashpageboardContainer,
.dashpageboardTripContainer {
  display: contents;
  justify-content: space-evenly;
}

.dashboard-invitation-card-receivedList1 {
  width: 40% !important;
  margin-bottom: 30px;
  height: auto;
  margin-left: 48px;
  margin-top: 25px;
  box-shadow: 0px 10px 1px -1px #164938 !important;
}

.recent-exploremore-dashboard-button {
  color: white !important;
  border-color: #ff8d8d !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  background-color: #ff8d8d !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .dashbaord-sidebar-links {
    display: none;
  }
  .dashboard-formobile-main {
    display: flex;
    flex-direction: column-reverse !important;
    width: 100%;
  }
  .dashbox {
    width: 93%;
    height: auto;
    background-color: #edfaf5;
    margin-bottom: 20px;
    border-radius: 10px;
    /* margin: auto; */
  }
  .dashboard-page-upcoming {
    display: block !important;
    /* text-align: initial; */
  }
  .dashboard-invitation-card-receivedList1 {
    width: 85% !important;
    margin-bottom: 30px;
    height: auto;
    margin-left: 32px;
    margin-top: 25px;
    box-shadow: 0px 10px 1px -1px #164938 !important;
  }
}
