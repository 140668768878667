.profile-page-main-heading {
  display: flex;
}
.profile-page-text {
  text-align: start;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
  margin-left: 15px;
}
.profile-box {
  width: 90%;
}
.profile-page-about {
  text-align: initial;
  font-size: 20px;
  font-weight: bold;
}
.profile-page-card-container {
  height: 150px;
  width: 50%;
}
.profile-page-edit-icon {
  margin-bottom: 1px !important;
  font-size: 15px !important;
}
.profile-page-card-inner {
  height: 185px;
  width: 70%;
  margin: auto;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
}
.profile-page-img {
  height: 145px;
  margin-top: 20px !important;
  width: 80%;
  border-radius: inherit;
}

.profile-commas {
  text-align: start;
  display: block;
  font-size: 20px;
  transform: translateX(0px);
}
.comma2 {
  margin-left: -8px;
}
.profile-edit-btn1 {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 5px !important;
  border: none;
  font-size: 11px !important;
  text-align: right;
}
.profile-page-name-label {
  font-size: 20px;
  font-weight: 600;
}
.profile-page-dataIcons {
  color: #1ec28b;
}
.profile-page-info-text {
  text-align: initial;
  font-size: 18px;
}
.profile-date {
  font-weight: bold;
  text-align: initial;
  font-size: 18px;
  display: block;
}
/*----------------------------- seconfBox CSS ------------------------------*/
.profile-page-second-box {
  margin-top: 50px;
  width: 60%;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .profile-page-main-heading {
    display: block !important;
  }
  .profile-page-sidebarlink {
    display: none !important;
  }
  .profile-box {
    width: 100%;
    height: auto !important;
}
  .profile-page-second-box {
    margin-top: 50px;
    width: 100%;
  }
  .profile-page-edit-icon {
    margin-bottom: 1px !important;
    font-size: 10px !important;
  }
  .profile-edit-btn1 {
    background-color: #1ec28b !important;
    color: white !important;
    border-radius: 5px !important;
    font-size: 9px !important;
  }
  .profile-page-card-container {
    height: 150px;
    width: 100%;
  }
  .profile-page-card-inner {
    height: 75px;
    width: 85%;
    margin: auto;
    background: rgb(30 194 139 / 60%);
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
  }
  .profile-page-text {
    text-align: start;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 15px;
}
  .profile-page-img {
    height: 55px;
    margin-top: 10px !important;
    width: 75%;
    border-radius: inherit;
  }
  .profile-page-name-label {
    font-size: 15px;
    font-weight: 600;
  }
  .profile-page-about {
    text-align: initial;
    font-size: 15px;
    font-weight: bold;
  }
  .profile-commas {
    text-align: start;
    display: block;
    font-size: 15px;
    transform: translateX(0px);
  }
  .profile-page-info-text {
    text-align: initial;
    font-size: 12px;
  }
  .profile-page-dataIcons {
    color: #1ec28b;
    font-size: 15px !important;
  }
  .profile-date {
    font-weight: bold;
    text-align: initial;
    font-size: 12px;
    display: block;
  }
  .profile-page-card-main-editMain{
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 20px
  }
}
@media screen and (min-width: 360px) and (max-width: 480px) {
  .profile-page-card-inner {
    height: 75px;
    width: 75%;
    margin: auto;
    background: rgb(30 194 139 / 60%);
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
}


}