/* css for one stop tab  */

.search-loginEmailError {
  color: red;
}
.Onetab-section {
  /* background: rgba(30, 194, 139, 0.1); */
  height: 150px !important;
  border-radius: 8px;
  border: 1px solid #1ec28b;
}

.cancel-icon-custom-from {
  display: none !important;
}

.cancel-symbol-con-from:hover .cancel-icon-custom-from {
  display: block !important;
}
.cancel-icon-custom-to {
  display: none !important;
}

.cancel-symbol-con-to:hover .cancel-icon-custom-to {
  display: block !important;
}
.OnesourcePaper {
  display: flex;
  align-items: center;
  height: 56px;
  border: 1px solid #1ec28b;
}
.flyfrom {
  display: flex;
  align-items: center;
  height: 56px;
  /* border: 1px solid #1ec28b; */
  border-color: #1ec28b;
}
.destinationTo {
  display: flex;
  align-items: center;
  height: 56px;
  /* border: 1px solid #1ec28b; */
  border-color: #1ec28b;
}

.OnetabsubHeading {
  text-align: initial;
  font-size: 16px;
  margin-top: 20px !important;
  font-family: sans-serif;
}

.OnesourceTextFeild {
  margin-top: 25px !important;
}

.OnesearchButton {
  background-color: #ff8d8d !important;
  color: white !important;
  border: none !important;
  height: 40px !important;
  font-size: 15px !important;
  margin-top: -20px !important;
  border-radius: 9px !important;
  width: 15% !important;
  font-family: sans-serif !important;
}

.OnesearchButtonText {
  color: white !important;
  text-decoration: none !important;
  font-family: sans-serif;
}

.validationField span {
  color: #ff8d8d !important;
}

.validationField label::after {
  content: "*";
  color: #ff8d8d;
  margin-left: 0.3rem;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  /* ---------------------------------- css for SearchBar --------------------------------------- */
  .Onetab-section {
    /* background: rgba(30, 194, 139, 0.1); */
    height: 500px !important;
    border-radius: 8px;
    border: 1px solid #1ec28b;
  }
  .OnesearchButton {
    background-color: #ff8d8d !important;
    color: white !important;
    border: none !important;
    height: 35px !important;
    font-size: 15px !important;
    margin-top: -100px !important;
    border-radius: 9px !important;
    width: 30% !important;
    font-family: sans-serif !important;
  }
}
/* -----------I pad and tablet css--------------- */
@media only screen and (max-width: 767px) {
  .Onetab-section {
    height: 422px !important;
    border-radius: 8px;
    border: 1px solid #1ec28b;
  }
}
@media only screen and (max-width: 1024px) and (max-height: 1366px) {
  .Onetab-section {
    height: 422px !important;
    border-radius: 8px;
    border: 1px solid #1ec28b;
  }
}
