.Tandc-container{
    padding-right: 110px;
    padding-left: 126px;
    display: flex;
    text-align: initial;
    line-height: 1.5;
    font-family: sans-serif;
}
.tandc{
    margin-bottom: 15px;
    font-weight: 600;
    margin-top: 40px;
}
.agreementIncludes{
    font-weight: 600;
    margin-top: 15px;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
    .Tandc-container{
    padding-right: 15px!important;
    padding-left: 15px!important;
    margin-left:-2px!important;
    display: flex;
    text-align: initial;
    line-height: 1.5;
    }
    

}