.manage-members-popup-box {
  background: white !important;
  border-radius: 20px;
  height: 525px !important;
  width: 100%;
  /* margin-top: 5px; */
}
.manage-members-cancel-span {
  justify-content: end;
  display: flex;
  font-size: 40px;
  cursor: pointer;
}
.admin-companion-img-start1 {
  display: flex;
  align-items: center !important;
  width: 100%;
  column-gap: 20px;
}
.admin-companion-img {
  font-size: 10px;
}
.admin-src-image {
  border-radius: 20px;
  border: 1px solid grey;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
  justify-content: center !important;
  display: flex;
}
.profilepopup-editpopup-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 158px;
  width: 158px;
  /* margin-bottom: 30px; */
  background: rgb(30 194 139 / 60%);
  margin: auto;
  border-radius: 50%;
  padding: 0.5rem;
  /* margin-top: 38px !important; */
}
.profilepopupEditImg-con{
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  /* padding: 0.5rem; */
  margin-top: 0 !important;
  /* background-color: rgb(128, 0, 255); */

}
.profilepopup-editpopup-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  /* padding: 1rem; */
  margin-top: 15px;
  /* margin-bottom: 30px; */
  /* background-color: aqua; */
}
.managemembers-modalBackground {
  width: 100%;
  height: 44rem;
  background-color: rgba(226, 214, 214, 0.447);
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -539px;
  z-index: 2;
}
.profieedit-profile-icon {
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
  justify-content: flex-end !important;
  display: flexbox !important;
  /* display: block;  */
}
.profiledit-main-icon {
  justify-content: center !important;
  font-size: 30px;
  display: flex;
  margin-bottom: 15px !important;
}
.profileedit-main-heading {
  /* border-radius: 20px; */
  height: 70px;
  background: #1ec28b;
  /* margin: auto !important; */
  width: 100% !important;
  margin-bottom: 20px !important;
}
.profileedit-heading {
  text-align: center !important;
  height: 70% !important;
  color: white !important;
  font-size: 25px;
  font-weight: 500;
}
.managemembers-modalContainer {
  width: 41%;
  height: 60%;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.manage-members-country-code{
  display: flex !important;
  text-align: center !important ;
  justify-content: center !important;
  margin-bottom: 20px;
}
.manage-members-mobile-number{
  display: flex;
  text-align: initial;
  justify-content: initial;
}
.manage-members-created-at{
  display: flex;
  text-align: center !important;
  justify-content: center !important;
}
.manage-members-fullname{
  display: flex;
  justify-content: center;
  font-size: 20px;
  align-items: center;
  font-weight: 600 !important;
  margin-top: -26px !important;
}
.manage-members-icon-main-div{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.manage-members-phone-icon{
  font-size: 28px;
  color: #1ec28b;
}
.manage-members-calendar-icon{
  font-size: 25px;
  color: #1ec28b;
}
.manage-members-createdon-div{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}