.sidebaricon {
  color: #1ec28b;
}
.settings-card-profilecontainer {
  height: 150px;
  width: 100%;
}

.settings-card-profile-inner {
  height: 150px;
  width: 150px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin: auto !important;
}
.settings-profile-img {
  height: 110px;
  margin-top: 20px !important;
  width: 110px;
  border-radius: inherit;
}
.settings-profile-text-content-main {
  display: contents;
}
.settings-profile-name {
  font-family: sans-serif;
  font-size: 25px;
  font-weight: 600;
}
.settings-profile-edit-btn {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 5px !important;
  border: none;
  font-size: 11px !important;
}

.active {
  color: #ff8d8d !important;
  text-decoration: none;
  
}
.inactive {
  color: black;
  text-decoration: none;
}
.active,
.inactive:hover {
  color: #ff8d8d !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .settings-card-profilecontainer {
    height: 200px;
    width: 100%;
    display: contents;
  }
  .settings-card-profile-inner {
    height: 150px;
    width: 150px !important;
    background: rgb(30 194 139 / 60%);
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
  }
  .settings-profile-img {
    height: 110px;
    margin-top: 20px !important;
    width: 110px;
    border-radius: inherit;
  }

  .settings-profile-name {
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 600;
    margin-top: 70px;
  }
  .settings-profile-edit-btn {
    background-color: #1ec28b !important;
    color: white !important;
    border-radius: 5px !important;
    margin-top: 15px !important;
    font-size: 11px !important;
  }
  .active {
    color: #ff8d8d !important;
    text-decoration: none;
    
  }
  .inactive {
    color: black;
    text-decoration: none;
  }
  .active,
  .inactive:hover {
    color: #ff8d8d !important;
  }
}
