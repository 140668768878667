.connectpeole-main {
  display: flex;
  margin-top: 3rem;
}
/* heading text */

.connectpeopleHeading {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 30px;
  font-family: sans-serif;
  text-align: initial;
}

.connectpeopleHeadingText {
  font-family: sans-serif;
  text-align: initial;
  font-size: 28px;
  font-weight: 500;
  width: 100%;
  line-height: 2;
}

.connectpeopleImage {
  width: 82%;
  margin-bottom: 5%;
  box-shadow: 0px 10px 1px -1px #1ec28b !important;
}

/* Card section */

.connectpeople-container {
  height: 200px;
  width: 100%;
}

.connectpeople-inner {
  height: 189px;
  width: 200px;
  background: rgb(30 194 139 / 60%);
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  margin: auto;
}

.connectpeople-MainCard {
  margin-top: 50px !important;
}

.connectpeople-Card {
  display: inline-block;
  width: 80% !important;
  margin-bottom: 85px;
  height: auto !important;
  box-shadow: 0px 10px 1px -1px #164938 !important;
}

.connectpeople-contentCat {
  font-size: x-large;
  text-align: initial;
  font-family: sans-serif;
}
.connectpeople-contentplace {
  font-size: 15px;
  font-family: sans-serif;
  text-align: initial;
  width: 100%;
}
.loadMoreReasultsDiv {
  margin-bottom: 65px;
  min-width: 50%;
  /* background-color: #1ec28b; */
  /* background-color: black; */
}
.loadMoreText {
  margin-bottom: 35px;
}
/* .loadmoreDiv {
  margin-left: 395px;
} */
.searchResultsHeading {
  display: flex;
  margin-top: 40px;
  margin-bottom: -20px;
  font-size: 25px;
  font-weight: 600;
}

.contentPeopleJohn {
  font-size: x-large;
  font-family: sans-serif;
}

.contentPeoplePraveen {
  font-size: x-large;
  font-family: sans-serif;
}

.contentPeopleVinsent {
  font-size: x-large;
  font-family: sans-serif;
}

.connectpeople-contentplaceOffer {
  font-size: 14px;
  font-family: sans-serif;
  width: 100%;
  margin-top: -6px;
}

.connectpeople-contentplaceExpected {
  font-size: 14px;
  font-family: sans-serif;
  width: 100%;
  margin-left: 7px;
  margin-top: -6px;
}

.connectpeople-img {
  height: 159px;
  width: 85%;
  padding-top: 15px;
  border-radius: inherit;
}

.connectpeople-date {
  font-size: 22px;
}

.connectpeople-cardPara {
  font-size: 15px;
  text-align: start;
  margin-left: 25px;
  font-family: sans-serif;
  line-height: 1.5;
  width: 100%;
}

.connectpeople-cardButton {
  background-color: #ff8d8d !important;
  color: white !important;
  border: none !important;
  width: 122px !important;
  height: 40px !important;
  font-size: 15px !important;
  margin-top: 16px !important;
  border-radius: 9px !important;
  margin-bottom: 20px !important;
}

.connectpeople-cardButtonText {
  color: white !important;
  text-decoration: none !important;
}
/* Load more Results */
.LoadmoreResults {
  margin-bottom: 40px;
  font-family: sans-serif;
}
.languageDiv {
  display: flex;
  margin-left: 20px;
}
.languageText {
  margin-top: 0px !important;
  margin-left: 5px;
}
.LanguageRoundedIconConnectCard {
  color: #1ec28b !important;
}

.connnect-ppl-card-places {
  text-align: initial;
  line-height: 2;
  font-weight: 600 !important;
  /* margin-top: -9px; */
}
.filter-mobile {
  display: none;
  margin-bottom: 3rem;
}
.connectpeople-contentplace-main-name {
  display: flex;
  text-align: initial;
  line-height: 1.5;
}
.connectpeople-contentplace-name {
  font-size: 17px;
  line-height: 1.75;
}

.loadmoreDiv {
  /* background-color: #1ec28b; */
  width: 100%;
  
  /* margin-left: 395px; */
}
.loadmoreDiv button{
  background-color: #ff8d8d;
  color: white;
  border: none;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .connectpeopleHeading {
    font-size: 20px;
    margin-top: 0px;
  }
  .connectpeople-Card {
    display: inline-block;
    width: 100% !important;
    margin-bottom: 30px;
    height: auto !important;
    box-shadow: 0px 10px 1px -1px #164938 !important;
  }
  .connectpeople-contentplace {
    font-size: 15px;
    font-family: sans-serif;
    text-align: initial;
    width: 90%;
  }
  .connectpeole-main {
    display: contents;
  }
  .connectpeople-inner {
    height: 150px;
    width: 150px;
    background: rgb(30 194 139 / 60%);
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    margin: auto;
  }
  .connectpeople-img {
    height: 120px;
    padding-top: 15px;
    width: 120px;
    border-radius: inherit;
  }
  .searchResultsHeading {
    display: flex;
    margin-top: 0px;
    font-size: 18px;
    font-weight: 600;
  }
  .filter-mobile {
    display: block;
    margin-bottom: 2rem !important;
  }
  .filter-btn {
    font-size: 18px;
    border: none;
    display: contents;
    font-weight: 600;
  }
  .connectpeople-contentplace-main-name {
    display: flex;
    text-align: initial;
    line-height: 1.5;
    font-size: 15px;
  }
  .connectpeople-contentplace-name {
    font-size: 17px;
  }
}