.navbar-dash {
  background-color: #ffffff;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  margin: 0px;
  padding: 0px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.nav-container-dash {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  gap: 52px;
}

.nav-logo-dash {
  color: #f5b921;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  width: 40%;
}

.nav-menu-dash {
  display: flex;
  list-style: none;
  text-align: center;
  column-gap: 15px;
}

.nav-links-dash {
  color: #000000;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: 600;
  border-bottom: 3px solid transparent;
}

.nav-links-dash:hover {
  border-bottom: 5px solid palevioletred;
}
/* .activeCreateTrip{
  color: #000000;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: 600;
  border-bottom: 3px solid red;

    
} */

.nav-links-icon-dash {
  color: #1ec28b !important;
  text-decoration: none !important;
  /* padding: 0.5rem 1rem !important; */
  height: 100% !important;
  /* border-bottom: 3px solid transparent !important; */
  width: 30px !important;
  /* margin-right: 245px; */
}



.nav-item-dash {
  line-height: 1.75;
  margin-top: 10px !important;
}

.nav-item-dash:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

/* .nav-links-button {
    background-color: #1ec28b !important;
    color: #383838 !important;
    text-decoration: none !important;
  } */

.nav-icon-dash {
  display: none;
}

.navbar-image-dash {
  width: 80%;
  height: 40px;
  display: flex;
}

.notifications-dash {
  display: none !important;
}

.nav-links-button-login-dash {
  display: none !important;
}

.nav-item-dash-Img {
  height: 32px;
  padding-top: 4px;
  border-radius: inherit;
  width:80%
}

.nav-item-dash-container {
  height: 5px;
  margin-top: 10px;
}

.nav-item-dash-inner {
  height: 40px;
  width: 40px;
  background: #1ec28b;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  /* margin-left: -163px; */
  margin-top: -35px;
  /* border: 1.5px solid #1ec28b !important;  */
}
.dashboard-img-icon-modal {
  display: flex;
  margin-top: 80px !important;
}
@media screen and (max-width: 960px) {
  .dash-navbar-container{
    margin-bottom: 0px;
  }
  .navbar-dash {
    background-color: #ffffff;
    height: 80px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    position: relative;
    top: -35px;
    z-index: 20;
    margin: 0px;
    padding: 0px;
    /* margin-bottom: 30px; */
    margin-top: 30px;
    width: 100%;
    /* margin-left: -16px; */
  }
  .nav-menu-dash {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-container-dash {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    column-gap: 13px;
  }

  .nav-logo-dash {
    color: #f5b921;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    /* width: 100%; */
    width: auto;
    margin-left: 5px;
  }
  /* .nav-menu-dash.active {
      background: #ffffff !important;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    } */

  .nav-links-dash {
    padding: 1.5rem;
    width: 100%;
    display: table;
    line-height: 0;
    display: inline-block;
    visibility: hidden;
  }

  .nav-links-icon-dash {
    color: #1ec28b !important;
    text-decoration: none !important;
    height: 100% !important;
    margin-top: -7px;
  }

  .nav-item-dash-inner {
    height: 40px;
    width: 40px;
    background: #1ec28b;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    /* margin-top: -38px; */
    /* border: 1.5px solid #1ec28b !important; */
    margin-right: 5px;
  }

  .nav-icon-dash {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #1ec28b;
  }

  .navbar-image-dash {
    width: 100% !important;
    height: 25px !important;
  }
  .dashboard-img-icon-modal {
    display: flex;
    margin-top: 50px !important;
  }

}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .dash-navbar-container {

    position: sticky;
  }
}