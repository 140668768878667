.filter-main-desktop {
  width: 100%;
  height: auto;
  border: 1px solid #1ec28b !important;
  border-radius: 5px;
  /* margin-top: 50px; */
  display: block;
}

.filter-second {
  background: #1ec28b;
  color: white;
  display: flex;
}
.filter-icon-section {
  color: #1ec28b !important;
}
.filter-text-section {
  margin-top: 10px;
  font-family: sans-serif;
  font-weight: 600;
}
.filter-listed {
  width: 180px;
  display: inline-block !important;
}

.airlines-listed {
  width: 180px;
  display: inline-block !important;
}

.tipamt-listed {
  width: 180px;
  display: inline-block !important;
}
.tip-amt-text {
  margin-top: -25px;
  margin-left: 15px;
}
.apply-filter-btn {
  background-color: #ff8d8d !important;
  color: white !important;
  border: none !important;
  width: 120px !important;
  height: 25px !important;
  font-size: 12px !important;
  margin-top: 16px !important;
  border-radius: 5px !important;
  margin-bottom: 10px !important;
}
.range-amount {
  height: 20px;
  width: 55px;
  border: 1px solid#1ec28b;
  margin-top: -43px;
  border-radius: 8px;
  margin-left: 100px;
}

.currency-options {
  border: 1px solid#1ec28b;
  width: 55px;
  height: 22px;
  border-radius: 8px;
  margin-left: -85px;
}
.travelExploreIcon {
  color: #1ec28b !important;
}
.bookingStatus {
  font-size: 18px !important;
  font-family: sans-serif;
  margin-top: 0;
  margin-bottom: 0;
}
.SortSharpIcon {
  margin-top: 0px !important;
}
.checkBox{
  text-align: initial;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
}

.checkBox input{
  accent-color:#ff8d8d;
  color: white !important;
  width: 15.5px;
  height: 15.5px;
  content: "";
  display: inline-block;
  vertical-align: -25%;
  background-color: white;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 4px;
  box-shadow: inset 0 2px 5px rgba(0,0,0,0.25);
  margin-right: 0.5em;
}



@media screen and (min-width: 320px) and (max-width: 480px) {
  .filter-main-desktop {
    width: 100%;
    height: auto;
    border: 1px solid #1ec28b;
    border-radius: 5px;
    margin-top: 50px;
    display: none !important;
    /* margin-left: -115px; */
  }
  .filter-main-mobile {
    height: auto;
    border: 2px solid #1ec28b;
    border-radius: 5px;
    margin-top: 50px;
    /* padding:1rem; */
    background-color: white;
    width: 100%;
    /* margin-left: -115px; */
  }
}




  .checkbox-wrapper-65 *,
  .checkbox-wrapper-65 ::after,
  .checkbox-wrapper-65 ::before {
    box-sizing: border-box;
  }
  .checkbox-wrapper-65 .cbx {
    position: relative;
    display: block;
    float: left;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #606062;
    background-image: linear-gradient(#474749, #606062);
    box-shadow: inset 0 1px 1px rgba(255,255,255,0.15), inset 0 -1px 1px rgba(0,0,0,0.15);
    transition: all 0.15s ease;
  }
  .checkbox-wrapper-65 .cbx svg {
    position: absolute;
    top: 3px;
    left: 3px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 17;
    stroke-dashoffset: 17;
    transform: translate3d(0, 0, 0);
  }
  .checkbox-wrapper-65 .cbx + span {
    float: left;
    margin-left: 6px;
  }
  .checkbox-wrapper-65 {
    user-select: none;
  }
  .checkbox-wrapper-65 label {
    display: inline-block;
    cursor: pointer;
  }
  .checkbox-wrapper-65 input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }
  .checkbox-wrapper-65 input[type="checkbox"]:checked + .cbx {
    background-color: #606062;
    background-image: linear-gradient(#255cd2, #1d52c1);
  }
  .checkbox-wrapper-65 input[type="checkbox"]:checked + .cbx svg {
    stroke-dashoffset: 0;
    transition: all 0.15s ease;
  }


