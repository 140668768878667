.footerParentContainer {
  background-color: #2e483f;
  margin-top: 50px;
}
.footercontainer {
  color: white;
}
.footerImg {
  line-height: 5;
  color: #007bff;
  background-color: aliceblue;
  width: 74%;
  align-items: center;
  display: flex;
  border-radius: 5px;
  margin-top: 28px;
}
.footerLogo {
  width: 100%;
  transform: rotateY(45deg);
  height: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.footer-logo-content {
  width: 86%;
  line-height: 2;
}
.footerLinks {
  font-size: 20px;
  line-height: 1.5;
  text-align: initial;
}
.footer-nav-links {
  color: white;
  text-decoration: none;
}
.footerLinks2 {
  font-size: 20px;
  line-height: 1.5;
  text-align: initial;
}
.firstcolumn {
  width: 100%;
  font-size: 20px;
  text-align: initial;
}

.secondcolumn {
  color: #1ec28b;
  text-align: initial;
  width: 100%;
  font-size: 25px;
  font-weight: bold;
  margin-top: 35px;
}

.thirdcolumn {
  color: #1ec28b;
  font-size: 25px;
  margin-top: 35px;
  width: 100%;
  font-weight: bold;
  text-align: initial;
}
.fourthcolumn {
  color: #1ec28b;
  font-size: 25px;
  margin-top: 35px;
  width: 100%;
  text-align: initial;
  font-weight: bold;
}
.fourthCoulmn-query {
  font-size: 20px;
  text-align: initial;
  line-height: 2;
}

.thirdcolumncontent {
  width: 100%;
  text-align: initial;
  font-size: 22px;
}
.footer-contact-button {
  background-color: white !important;
  color: #040303 !important;
  text-decoration: none !important;
  font-weight: 700 !important;
  border-radius: 8px !important;
  margin-bottom: 20px !important;
}
.footerDisclaimer {
  color: white;
  text-align: initial;
  font-size: 20px;
}
.footerDisclaimerProperty{
  color: white;
  text-align: initial;
  font-size: 20px;

}
.underlinefooter {
  background: #1ec28b;
  width: 100%;
  height: 4px;
  margin-bottom: 10px;
}
.footer-contact-mail {
  display: flex;
  width: 100%;
  margin-bottom: 12px;
}
.footerMail {
  font-size: 16px;
}
.footer-number-main {
  margin-top: 5px;
  margin-bottom: 10px;
}
.footer-number {
  font-size: 20px;
}
.emailUnderLine {
  background: #1ec28b;
  width: 100%;
  height: 3px;
  margin-bottom: 8px;
}
.footer-bottom-icon-content {
  display: flex;
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .footer-logo-content {
    width: 93%;
    font-size: 16px;
    line-height: 2;
  }
  
  .secondcolumn {
    margin-top: 0px;
  }
  .thirdcolumn {
    margin-top: 0px;
  }
  .footerLinks {
    line-height: 1;
    font-size: 20px;
  }

  .footerLinks2 {
    line-height: 1;
    font-size: 20px;
  }
  .fourthcolumn {
    margin-top: 0px;
  }
  .fourthCoulmn-query {
    font-size: 16px;
    text-align: initial;
    line-height: 2;
  }
  .underlinefooter {
    transform: translate(0px, 50px);
  }
  .footerDisclaimer {
    color: white;
    text-align: initial;
    font-size: 13px;
  }
  .footerDisclaimerProperty{
    color: white;
    text-align: initial;
    font-size: 13px;
    margin-top: 10px;

  }
  .footer-bottom-icon-content {
    display: flex;
    flex-direction: column-reverse !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .underlinefooter {
    transform: translate(0px, 45px);
  }
  .footerDisclaimer {
    color: white;
    text-align: initial;
    font-size: 15px;
  }
  .footerDisclaimerProperty{
    color: white;
    text-align: initial;
    font-size: 15px;
    margin-top: 10px;

  }
  .footer-bottom-icon-content {
    display: flex;
    flex-direction: column-reverse !important;
  }
}
