.privacyPolicy-Heading {
  display: flex;
  font-size: 28px;
}
.privacyPolicy-Text1 {
  width: 100%;
  margin-top: 0px;
  line-height: 2;
  font-size: 28px;
  text-align: initial;
}
.privacyPolicy-infoHeading {
  font-size: 19px;
  color: rgba(0, 0, 0, 80%);
  font-weight: 600;
}
.privacyPolicy-infoText {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #1ec28b;
  line-height: 0;
}
.privacyPolicy-infoText span {
  background: #fff;
  padding: 0 10px;
}
.privacyPolicy-Text2 {
  width: 100%;
  line-height: 2;
  text-align: initial;
  font-size: 29px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .privacyPolicy-Heading {
    display: flex;
    font-size: 17px !important;
  }
  .privacyPolicy-Text1 {
    width: 100%;
    margin-top: 0px;
    line-height: 2;
    font-size: 15px;
    text-align: initial;
  }
  .privacyPolicy-infoHeading {
    font-size: 8px;
    color: rgba(0, 0, 0, 80%);
    font-weight: 600;
  }
  .privacyPolicy-infoText {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #1ec28b;
    line-height: 0;
  }
  .privacyPolicy-infoText span {
    background: #fff;
    padding: 0 10px;
  }
  .privacyPolicy-Text2 {
    width: 100%;
    line-height: 2;
    text-align: initial;
    font-size: 15px;
    margin: auto;
  }
}
