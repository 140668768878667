.user-main {
  margin-top: 45px;
}
.user-trip-heading {
  font-weight: bold;
  width: 20% !important;
  color: rgb(12 12 12 / 80%);
  margin-top: 33px;
}
.user-popup-person {
  width: 10%;
  margin-left: -62px;
}
.user-popup-usertype {
  width: 36%;
  margin-left: 41px !important;
}
.userfilter-popup {
  display: flex;
  column-gap: 66px;
  border: none;
  border-bottom: 1px solid black;
  font-weight: 600;
  /* margin-top: 20px; */
  padding-bottom: 21px;
  padding-top: 20px;
  background-color: rgb(247 247 247);
}
.user-popup-date {
  width: 34%;
  /* margin-top: -13px !important; */
}
.user-trip-accordian {
  /* display: flex;
  column-gap: 81px;
  padding: 31px;
  height: 0px !important;
  margin-top: 10px; */

  display: flex;
  grid-column-gap: 81px;
  column-gap: 13px;
  padding: 31px;
  height: 0px !important;
  margin-top: 10px;
  width: -webkit-fill-available;
}
.user-dtls-fulname {
  width: 10%;
  font-size: 14px;
}
.usertrip-accordian {
  border-bottom: 1px solid black !important;
}

.user-popup-conn {
  font-weight: 770;
  margin-top: 20px;
  margin-bottom: 5px;
}
.user-pop-icon {
  display: flex;
  margin-left: 70px;
  font-size: smaller;
  /* margin-top: 4px; */
  color: #1ec28b;
}
.user-trip-accordian1 {
  display: flex;
  column-gap: 77px;
}
.user-trip-accordian2 {
  display: flex;
  column-gap: 77px;
}

.user-icon {
  display: flex;
  font-size: 17px;
}
.user-trip-subheading {
  margin-bottom: 25px;
  margin-top: 5px;
}
.user-subheading {
  color: #1ec28b;
}
.user-popup-txt {
  text-align: justify;
  margin-left: 46px;
  font-size: small;
  line-height: 2;
}
.userpopup-heading {
  width: 97%;
  margin: auto;
}
.user-trip-main {
  display: flex;
  background-color: #f9fbfc;
  /* margin-top: 45px; */
}
.user-trip-box-main {
  column-gap: 20px;
  display: flex;
  margin: auto;
  justify-content: space-around;
  margin-top: 40px !important;
}
.user-box {
  border: 2px solid lightgray;
  width: 100% !important;
  height: 100px;
  border-radius: 10px !important;
  color: #1ec28b !important;
  font-weight: bold;
}
.user-trip-number {
  font-size: 30px;
  margin-top: 10px;
}
.user-box-color {
  color: grey !important;
  font-weight: bold;
  margin-top: 20px !important;
}
/* .user-trip-search {
} */

.user-trip-srch {
  width: 100% !important;
  margin-top: 32px !important;
  /* border: 1px solid lightgrey !important; */
}
.user-trip-srch .MuiOutlinedInput-notchedOutline {
  border: 1px solid #c2c2c2 !important;
}

.user-trip-stat {
  width: 75%;
  margin-top: 36px;
  height: 35px;
}
.user-trip-tbl {
  justify-content: center !important;
  display: flex !important;
  flex-direction: column;
}
.user-trip-tblname {
  border-radius: 10px !important;
  border-collapse: collapse;
  width: 97% !important;
  margin-bottom: 35px;
  margin-top: 20px;
  color: lightgrey;
}
.user-trip-filter {
  display: flex;
  justify-content: end;
  /* width: 80%; */
  cursor: pointer;
}
.user-filter {
  margin-top: 43px;
  font-weight: bold;
}
.user-trip-table {
  border: 1px solid lightgrey;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 35px;
  margin-top: 10px;
  border-radius: 20px !important;
  justify-content: center;
  align-items: center;
}
.user-trip-border {
  border: 1px solid lightgray;
  background: rgb(211 211 211 / 30%);
  border-collapse: collapse;
  height: 35px !important;
}

.user-trip-border1 {
  border: 1px solid lightgrey !important;
  border-collapse: collapse !important;
}
.user-trip-border2 {
  border: 1px solid lightgray !important;
  border-collapse: collapse !important;
}

.user-trip-border3 {
  border: 1px solid lightgray;
  border-collapse: collapse;
}
.user-trip-icon1.css-cveggr-MuiListItemIcon-root {
  min-width: 22px !important;
  padding-top: 0px !important;
}
.user-trip-cmicon {
  column-gap: 10px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 25px;
}
.user-trip-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 40px !important;
  color: white !important;
}
.user-trip-img {
  font-size: 10px;
}
.user-td-image {
  width: 20%;
}
.user-trip-modal {
  display: flex !important;
  justify-content: center !important;
}
.user-trip-box {
  background: white !important;
  border-radius: 20px;
  height: 600px !important;
  width: 100%;
  margin-top: 15px;
}
.user-wrong {
  justify-content: end;
  display: flex;
  font-size: 40px;
  cursor: pointer;
}
.user-subheading {
  display: flex;
}
.userpopup-name {
  width: 100%;
  margin-left: 93px;
  margin-bottom: 20px;
  /* margin-top: 3px; */
}
.user-popup-card {
  display: contents;
}
.user-popup-typo1 {
  width: 38;
}
.user-popup-text {
  display: flex;
  justify-content: initial;
  column-gap: 16%;
  text-align: justify;
  width: 100%;
  margin-top: 15px;
  font-size: 15px;
  line-height: 42px;
}
/* .user-table-name {
} */
.user-card {
  width: 75%;
  display: flex;
  justify-content: center;
  /* background: #1ec28b; */
  margin-left: 47px;
  margin-bottom: 40px;
}
.userpopup-card {
  /* width: 88%; */
  display: contents;
}
.user-popup-text1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-popup-text2 {
  position: absolute;
  bottom: 1%;
  border: 1px solid white;
  background: white;
  height: 19px;
  width: 6%;
  border-radius: 4px;
}
.user-popup-trip {
  width: 15%;
  margin-bottom: 21px;
  margin-top: 25px;
  font-weight: 600;
}

.user-filter-initial {
  display: flex;
  margin-top: 17px;
  width: 55%;
  justify-content: initial;
  margin-left: 13px;
}
.user-trip-dtls-img {
  display: flex;
  justify-content: start;
  width: 65%;
  margin-left: 44px;
}
.user-details-img {
  width: 47%;
  margin-top: 10px;
}
.user-trip-image {
  border: 1px solid lightgray;
  width: 35% !important;
  border-radius: 30px;
  height: 30px;
}
.usertrip-image {
  border: 1px solid lightgray;
  border-radius: 20px;
  width: 13%;
  height: 125px;
}
.user-popup-id {
  margin-top: 10px;
}
.user-dtls-name {
  margin-top: 15px;
}
.user-popup-textig {
  display: flex;
  margin-top: 17px;
}
.user-trip-image1 {
  border: 1px solid lightgray;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  /* margin-bottom: 15px; */
  /* margin-top: -22px !important; */
}
.user-popup-img {
  width: 45%;
  height: 200px;
}
.user-trip-name {
  display: flex;
  margin-top: 17px;
  margin-bottom: 18px;
}
.user-trip-img-start {
  display: flex;
  justify-content: center;
  align-items: center !important;
  width: 100%;
  column-gap: 20px;
  margin-top: 18px;
  margin-bottom: 18px;
}
.user-trip-img-start1 {
  display: flex;
  align-items: center !important;
  width: 100%;
  column-gap: 20px;
}
.user-trip-popup-image {
  border: 1px solid lightgrey !important;
  border-radius: 119px;
  height: 40px;
}
.user-description {
  color: grey;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 30px;
}

.user-descr {
  display: flex;
}
.user-popup-start {
  display: flex;
  align-items: center !important;
  width: 40%;
  column-gap: 20px;
  height: 20px;
}
.user-desc {
  font-weight: bold;
  row-gap: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
}
.user-name {
  font-weight: bold;
}
.user-heading {
  margin-top: 30px;
  font-weight: bold;
  display: flex;
}
/* .user-trip-image {
  border: 1px solid lightgray;
  width: 21%;
  border-radius: 30px;
  height: 35px;
} */
.admin-trip-img {
  justify-content: center;
  display: flex;
}
.admin-trip-front-image {
  border: 1px solid lightgrey !important;
  border-radius: 119px;
  height: 40px;
}

.admin-src-image {
  border-radius: 20px;
  border: 1px solid grey;
  margin-top: 10px;
  width: 70%;
  margin-bottom: 20px;
}
.admin-mainbtn {
  display: flex !important;
  justify-content: center;
  margin-top: 37px;
  column-gap: 15px;
  margin-bottom: 10px;
}

.admin-button {
  border: 1px solid grey !important;
  background: #1ec28b !important;
  color: white !important;
  width: 92% !important;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 25px;
}
.admin-ModalBackground {
  width: 100%;
  height: 111rem;
  background-color: rgba(226, 214, 214, 0.447);
  position: sticky;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: -731px;
  z-index: 2;
}

.admin-ModalContainer {
  width: 28% !important;
  height: 18% !important;
  border-radius: 12px;
  /* background-color: white; */
  /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.admin-modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}
.admin-titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}
.admin-titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 23px;
  color: pink;
  cursor: pointer;
  margin-top: -13px;
}
.admin-modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}
.admin-modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: rgb(32, 90, 197);
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}
#admin-cancelBtn {
  background-color: crimson;
}

.tablee-container {
  width: 100%;
  word-break: break-all;
}
.user-trip-modal {
  position: relative;
}
.user-popupContainer {
  width: 100%;
  position: absolute;
  top: 0%;
}
.user-trip-icon1 {
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: blue;
}

.admin-search-main {
  margin-bottom: 10px !important;
  margin-top: 1rem;
}
.admin-trip-status {
  display: flex;
  justify-content: end;
}
.user-name {
  display: flex;
  border-bottom: 1px solid black;
  column-gap: 78px;
}
.userpopup-margin {
  width: 95%;
  margin-left: 20px;
}
.user-table-name {
  width: 27% !important;
  word-wrap: break-word !important;
}
