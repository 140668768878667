.ads-box {
  border: 2px solid #ff8d8d !important;
  width: 70% !important;

  height: auto !important;
  border-radius: 10px;
  margin-bottom: 30px;

  margin-top: 37px;
  margin: auto;
}

.ads-headingText {
  color: #000000;
  font-size: 45px;
  font-weight: bold;
}

.ads-mainPage {
  justify-content: space-evenly;
}

.ads-Text {
  display: flex;
  margin-top: 20px;
  color: #1ec28b;
}

.ads-phone-text-label{
    text-align: initial;
    color: #1ec28b;
    margin-top: 30px !important;

}

.ads-phone {
  display: flex;
  width: 100%;
}

.ads-code {
  margin-left: 0px !important;
}

.ads-PhoneText {
  width: 100%;
}

.ads-Capthch-mainPage{
    justify-content: space-between;
  }

.ads-CaptchaLabel {
  margin: 17px !important;
}

.ads-Captcha {
  display: flex;
  justify-content: center;
  margin-top: 70px !important;
}

.ads-btn {
  margin-top: 6px !important;
  margin-bottom: 25px !important;
  background: #ff8d8d !important;
  width: 35%;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .ads-box {
    border: 2px solid pink !important;
    width: 75% !important;
    height: auto !important;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-top: 37px;
    margin: auto;
  }

  .ads-headingText {
    color: #000000;
    font-size: 28px;
    font-weight: bold;
  }

  .ads-mainPage {
    justify-content: space-evenly;
  }

  .ads-Text {
    display: flex;
    margin-top: 20px;
    color: #1ec28b;
    font-size: 14px;
  }

  .ads-phone-text-label{
    text-align: initial;
    color: #1ec28b;
    margin-top: 30px !important;

}
  .ads-phone {
    display: flex;
    width: 100%;
  }
  
  .ads-code {
    margin-left: 0px !important;
  }
  
  .ads-PhoneText {
    width: 100%;
  }

  .ads-Capthch-mainPage{
    justify-content: end;
  }

  .ads-CaptchaLabel {
    margin: 0px !important;
    font-weight: 600 !important;
  }

  .ads-Captcha {
    display: block;
    justify-content: center;
    margin-top: 30px !important;
    text-align: initial;
  }

  .ads-btn {
    margin-top: 6px !important;
    margin-bottom: 25px !important;
    background: #ff8d8d !important;
    width: 50%;
  }
}
