.profile-edit-main-div {
  width: 100%;
}
.myProfile-Heading-text {
  text-align: initial;
}
.profileEditbox {
  width: 100%;
  border-radius: 20px;
  border: 1.5px solid pink !important;
}
.profile-edit-outer {
  height: 200px;
  width: 100%;
  margin-top: 15px;
}
.profile-edit-inner {
  height: 175px;
  width: 50%;
  background: #1ec28b;
  margin: auto;
  border-radius: 100px;
}
.profileEditImg {
  height: 135px;
  width: 75%;
  margin-top: 20px;
  border-radius: inherit;
}
.changePhoto {
  text-align: initial;
  align-items: center !important;
  display: flex;
}
.plusIcon {
  font-size: 18px !important;
  color: #1ec28b;
}
.changePhotoBtn {
  text-transform: lowercase;
}
.profile-edit-name {
  width: 100%;
}

.profile-edit-age-group-input {
  width: 100%;
  margin-top: 15px;
}

.profile-edit-gender-selectInput {
  width: 100%;
  margin-top: 15px !important;
}

.profile-edit-email {
  width: 100%;
}

.profile-edit-country {
  width: 100%;
  margin-top: 15px !important;
}

.profile-edit-country-code {
  width: 20%;
  margin-top: 15px !important;
}
.profile-edit-country-code-phone {
  width: 80%;
  margin-top: 15px !important;
}

.profile-edit-state {
  width: 100%;
  margin-top: 15px;
}

.profile-edit-about {
  width: 100%;
}
.profile-edit-save-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 40px;
  column-gap: 20px;
}
.profile-edit-save-btn {
  width: 15%;
  height: 40px;
  background-color: #1ec28b !important;
  border: none !important;
  color: white !important;
  border-radius: 7px !important;
  font-size: 15px !important;
}

/* ====================================popup css */
.profileedit-popupbackground {
  border-radius: 10px;
  height: auto;
  background: white !important;
  margin: auto !important;
  width: 45%;
  margin-top: 50px !important;
}
.profileedit-heading {
  text-align: center !important;
  height: 70% !important;
  color: white !important;
  font-size: 25px;
  font-weight: 500;
}
.profiledit-main-icon {
  justify-content: center !important;
  font-size: 30px;
  display: flex;
  margin-bottom: 15px !important;
}
.profileedit-main-heading {
  background: #1ec28b;
  margin: auto !important;
  width: 100% !important;
}
.profieedit-profile-icon {
  cursor: pointer;
  color: white;
  margin-bottom: 10px;
  justify-content: flex-end !important;
  display: flexbox !important;
  /* display: block;  */
}
.profile-edit-outer {
  height: 200px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px !important;
}
.profilepopup-edit-inner {
  height: 200px;
  width: 33%;
  background: rgb(30 194 139 / 60%);
  margin: auto;
  border-radius: 100px;
  margin-top: 60px !important;
}
.profilepopup-editpopup-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  /* padding: 1rem; */
  margin-top: 15px;
  margin-bottom: 30px;
  /* background-color: aqua; */
}
/* .profilepopupEditImg {
  height: 120px;
  width: 77%;
  border-radius: inherit;
  justify-content: center;
  display: flex;
  margin: auto;
  padding-top: 19px;

} */

.profilepopup-editpopup-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 158px;
  width: 158px;
  margin-bottom: 30px;
  background: rgb(30 194 139 / 60%);
  margin: auto;
  border-radius: 50%;
  padding: 0.5rem;
  /* margin-top: 38px !important; */
}

.profilepopupEditImg-con {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  /* padding: 0.5rem; */
  margin-top: 0 !important;
  /* background-color: rgb(128, 0, 255); */
}
.transparent-loading {
  position: absolute;
  background-color: white;
  top: 0%;
  right: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.7;
}

.profilepopupEditImg-con img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  /* object-fit: cover; */
}

.profileedit-btnpart {
  justify-content: center;
  display: flex;
}

.profiledit-removephoto {
  border: 1px solid #1ec28b !important;
  color: #1ec28b !important;
  margin-bottom: 30px !important;
}
.profiledit-removephoto:disabled {
  border: 1px solid #9fc5b8 !important;
  color: #c6e3d9 !important;
}

.profilepopupedit-addphoto {
  background: #1ec28b !important;
  color: white !important;
  margin-bottom: 30px !important;
}
.profile-popup-edit-changeicon {
  font-size: 25px;
  align-items: center !important;
  display: flex;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .profilepopupEditImg-con {
    position: relative;
    /* height: 135px;
    width: 135px; */
    /* border-radius: 50px; */
    /* background-color: aqua; */
  }

  .profile-edit-change-photo-main {
    margin-top: 21px;
  }
  .profile-edit-sidebar-main {
    display: none;
  }
  .profile-edit-outer {
    height: 110px;
    width: 100%;
    margin-top: 15px;
  }
  .profile-edit-inner {
    height: 90px;
    width: 90px;
    background: #1ec28b;
    margin: auto;
    border-radius: 100px;
  }
  .profileEditImg {
    height: 68px;
    width: 68px;
    margin-top: 10px;
    border-radius: inherit;
  }
  .profile-edit-save-btn {
    width: 25%;
    height: 35px;
    background-color: #1ec28b;
    border: none;
    color: white;
    border-radius: 7px;
    font-size: 15px;
  }

  /* ====================================popup css */
  .profileedit-popupbackground {
    border-radius: 10px;
    height: auto;
    background: white !important;
    margin: auto !important;
    width: 99% !important;
    margin-top: 50px !important;
  }
  /* .profileedit-heading {
    margin-bottom: 12px;
    text-align: center;
    width: 100% !important;
    color: white;
    background-color: #1ec28b;
    font-size: 17px;
    font-weight: 500;
    height: 13%;
    column-gap: 67px;
    display: flex;
    
    justify-content: center;
    align-items: center;
  } */

  .profileedit-heading {
    text-align: center !important;
    /* height: 70% !important; */
    color: white !important;
    font-size: 15px;
    font-weight: 500;
  }
  .profiledit-main-icon {
    justify-content: end !important;
    font-size: 30px;
    display: flex;
    font-weight: 500 !important;
  }

  .profilepopup-editpopup-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    /* padding: 1rem; */
    margin-top: 15px;
    margin-bottom: 30px;
    /* background-color: aqua; */
  }
  .profilepopup-editpopup-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 158px;
    width: 158px;
    margin-bottom: 30px;
    background: rgb(30 194 139 / 60%);
    margin: auto;
    border-radius: 100px;
    margin-top: 38px !important;
  }
  /* .profilepopupEditImg {
    height: 125px;
    width: 80%;
    border-radius: inherit;
    justify-content: center;
    display: flex;
    margin: auto;
    padding-top: 17px;
  } */

  .profileedit-main-heading {
    background: #1ec28b;
    margin: auto !important;
    height: 60px;
    width: 100% !important;
  }
  .profieedit-profile-icon {
    cursor: pointer;
    color: white;
    margin-bottom: 10px;
    justify-content: flex-end !important;
    display: flexbox !important;
    /* display: block;  */
  }

  .profileedit-btnpart {
    justify-content: center;
    display: flex;
  }

  .profiledit-removephoto {
    border: 1px solid #1ec28b !important;
    color: #1ec28b !important;
    font-size: 12px !important;
    height: 35px;
  }

  .profilepopupedit-addphoto {
    background: #1ec28b !important;
    color: white !important;
    font-size: 12px !important;
  }

  .profile-popup-edit-changeicon {
    display: flex;
    font-size: 20px;
    align-items: center !important;
  }
}

/* @media (min-width: 1200px) {
  .css-1oqqzyl-MuiContainer-root {
    max-width: none !important;
  }
} */
