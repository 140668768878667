.contactUS-heading {
  text-align: initial;
  font-family: sans-serif;
}

.contactUS-headingText {
  color: #000000;
  font-size: 50px;
}

.contactUS-Image {
  margin-top: 0px;

  width: 100%;
}

.contactUS-Btn {
  background-color: #ff8d8d !important;
  color: white !important;

  width: 40% !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  margin-bottom: 50px !important;
  margin-top: 20px !important;
}

/* --------------------------------CONTACT-FORM-------------- */

.contactFormPage {
  margin-top: 50px !important;
}

.contactFormfullNameText {
  text-align: initial;
  display: block;
}
.contactFormfullNameInput {
  width: 100%;
  margin-top: 10px !important;
}

.MuiFormLabel-root {
  color: #000000 !important;
}

.contactFormText {
  display: flex;
  margin-top: 20px;
}

.contactFormInput {
  margin-top: 5px !important;
}

.contactFormShortDescText {
  width: 100%;
  height: 100px !important;
  border-radius: 10px;
  margin-top: 48px;

  border: 1px solid #1ec28b;
  font-size: 17px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.contactFormShortDescMaxChar {
  margin-top: 6px;
  color: #ff8d8d;
  font-size: 13px;
  text-align: end;
}

.contactFormcountryGrid {
  margin-top: 38px !important;
}

.contactFormPhoneText {
}

.contactFormPhoneNumber {
  display: flex;

  margin-top: 30px;
  width: 100%;
}

.contactFormCodeText {
  display: flex;
  margin-left: 19px;
}

.contactFormCode {
  margin-left: 18px !important;
  margin-top: 0px !important;
}

.contactFormPhoneInput {
  width: 100%;
}

.contact-CaptchaLabel {
  font-size: 16px !important;
  font-weight: bold !important;
  margin-top: 6px !important;
}

.contact-Captchacontent {
  margin-left: 0px;
}

.contact-Captcha {
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .contactUS-heading {
    text-align: initial;
    font-family: sans-serif;
    margin-top: -35px;
  }

  .contactUS-headingText {
    color: #000000;
    font-size: 30px;
  }

  .contactUS-Image {
    margin-top: 0px;

    width: 100%;
  }

  .contactUS-Btn {
    background-color: #ff8d8d !important;
    color: white !important;

    width: 40% !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    margin-bottom: 50px !important;
    margin-top: 20px !important;
  }

  /* --------------------------------CONTACT-FORM-------------- */

  .contactFormfullNameText {
    text-align: initial;
    display: block;
  }

  .contactFormfullNameInput {
    width: 100%;
    margin-top: 10px !important;
  }

  .MuiFormLabel-root {
    color: #000000 !important;
  }

  .contactFormText {
    display: flex;
    margin-top: 20px;
  }

  .contactFormInput {
    margin-top: 5px !important;
  }

  .contactFormShortDescText {
    width: 100%;
    height: 100px !important;
    border-radius: 10px;
    margin-top: 48px;

    border: 1px solid #1ec28b;
    font-size: 17px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .contactFormShortDescMaxChar {
    margin-top: 6px;
    color: hotpink;
    font-size: 13px;
    text-align: end !important;
  }

  .contactFormcountryGrid {
    margin-top: 38px !important;
  }

  .contactFormPhoneText {
  }

  .contactFormPhoneNumber {
    display: flex;

    margin-top: 30px;
  }
  .contactFormCode {
    margin-left: 20px !important;
  }
  .contactFormPhoneInput {
    width: 100%;
  }

  .contact-CaptchaLabel {
    text-align: initial;
  }
  .contact-Captchacontent {
    margin-left: 10px !important;
  }

  .contact-Captcha {
    display: contents;
    justify-content: center;
    column-gap: 25px;
  }
}