.manage-members-heading {
  font-size: 25px;
  justify-content: start;
  display: flex;
  font-weight: 500 !important;
  margin-top: 20px;
}
.manage-memebers-subhead {
  font-size: 19px !important;
  margin-bottom: 30px;
}
.manage-members-subheading {
  margin-top: 10px;
  color: #1ec28b;
}
.manage-member-home {
  color: gray !important;
  text-decoration: none !important;
  font-size: 15px !important;
}
.manage-member-addbutton-main {
  display: grid;
  justify-content: end;
  margin-top: -34px;
}
.manage-member-add-button {
  background: #1ec28b !important;
  color: white !important;
  justify-content: end !important;
  display: flex !important;
  /* text-transform: lowercase !important; */
}
.manage-member-add-icon {
  align-items: center !important;
  display: flex;
  font-size: 15px !important;
}
.manage-members-card-maingrid {
  display: flex;
  column-gap: 80px;
}
.manage-members-card {
  margin-bottom: -36px;
  height: 115px;
  border-radius: 10px !important;
  border: 2px solid lightgray;
}

.manage-members-number {
  font-size: 30px;
  color: #1ec28b;
  justify-content: center;
  margin-top: 20px;
  font-weight: bold;
}
.manage-members-content-active {
  font-size: 20px;
  color: 12 12 12 (70%);
  font-weight: 500;
  margin-top: 20px;
}
.manage-members-content-members {
  font-size: 20px;
  font-weight: 500;
  color: 12 12 12 (70%);
  margin-top: 20px;
}
.manage-members-content-holding {
  font-size: 20px;
  color: 12 12 12 (70%);
  font-weight: 500;
  margin-top: 20px;
}
.manage-member-search-main-grid {
  margin-top: 20px !important;
  margin-bottom: 30px !important;
  align-items: center !important;
}
.manage-member-search-feild {
  display: flex !important;
  border-radius: 5px;
  margin-top: 40px !important;
  font-size: 20px !important;
}
.manage-member-searchicon {
  cursor: pointer;
  display: flex !important;
  font-size: 30px !important;
}
.manage-member-select-grid {
  /* margin-top: 20px; */
  border-color: 1px #1ec28b;
}
.select-all {
  height: 37px;
  width: 100%;
}
.manage-member-select {
  width: 75%;
  margin-top: 11px !important;
  height: 38px;
  border: 1px solid #1ec28b;
  border-radius: 5px !important;
}
.admin-trip-stat {
  width: 75% !important;
  margin-top: 36px !important;
  margin-left: 45px;
}

.manage-members-table {
  width: 100%;
  height: 30px;
  border: 1px solid lightgray !important;
  border-collapse: collapse;
  border-radius: 10px !important;
  justify-content: center !important;
  display: inline-table !important;
}
.manage-members-heading-tr-main {
  background: lightgray;
  height: 50px;
}
.manage-members-heading-tr {
  border: 1px solid lightgrey !important;
  border-collapse: collapse !important;
}
.manage-members-main-td {
  column-gap: 10px !important;
  display: flex;
  margin-top: 15px;
  justify-content: center;
}
.manage-members-eye-icon {
  color: white;
  border-radius: 3px;
  width: 25px;
  height: 20px;
  background: rgb(255 191 0 / 50%) !important;
  cursor: pointer;
}
.manage-members-deleteicon {
  border-radius: 3px !important;
  width: 37%;
  color: white;
  height: 20px;
  cursor: pointer;
  background: rgb(239 61 25 /50%);
}
.manage-members-pagination {
  margin-top: 30px;
  border: none !important;
  display: flex;
  justify-content: center !important;
  width: 100%;
  color: white !important;
  margin-bottom: 30px;
}
.manage-members-img {
  border-radius: 50px !important;
  width: 15% !important;
  height: 28px !important;
  margin-top: 11px;
}

.manage-members-img-td {
  display: flex !important ;
}
.managemembers-container {
  background-color: rebeccapurple;
  position: relative !important;
}
.managemember-div {
  position: absolute !important;
}
.manage-members-search-bar-no-results-found-main-div {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}
.manage-members-search-illustration-img {
  width: 17%;
  justify-content: center;
  margin-bottom: 20px;
}
.manage-member-no-results-found {
  font-size: 20px;
  font-weight: 600;
  color: rgb(229, 86, 50);
  margin-bottom: 20px;
}
.manage-member-search-feild .MuiOutlinedInput-notchedOutline {
  border: 1px solid #c2c2c2 !important;
}
.custom-notched-outline .MuiOutlinedInput-notchedOutline {
  border: 1px solid #c2c2c2 !important;
}

.managemembers-delete-dialog {
  height: 47rem;
  background-color: rgba(226, 214, 214, 0.111);
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}