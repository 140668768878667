.bodyBackground {
  margin-top: 50px;
  background-color: aliceblue;
}
.imageContainer {
  background-color: white;
}
.resetImage {
  width: 240px;
  display: flex;
  margin-bottom: -50px;
  margin-top: 5px;
  margin-left: 30px;
}

.backIcon {
  display: flex;
}

.reset-innerContainer {
  padding-top: 30px;
  padding-bottom: 45px;
}

.resetPasswordMain {
  height: 435px;
  background-color: white;
  width: 50%;
  padding: 10px;
  margin-top: 20px;
}

.resetPasswordHeading {
  text-align: initial;
  font-size: 25px;
  font-weight: 600;
}
.reset-new-PasswordLabel {
  display: flex;
  padding-left: 35px;
}
.reset-confirmPasswordLabel {
  display: flex;
  padding-left: 35px;
  margin-top: 20px;
}
.reset-inputNewPassword {
  width: 86%;
  height: 40px;
  margin-top: 5px;
}
.reset-newPasswordError {
  display: flex;
  padding-left: 35px;
  margin-top: 5px;
  color: red;
}

.reset-inputPassword {
  width: 86%;
  height: 40px;
  margin-top: 5px;
}
.reset-confirmPasswordError {
  display: flex;
  padding-left: 35px;
  margin-top: 5px;
  color: red;
}

.formMain {
  margin-top: 20px;
}
.iconLeftAlignment {
  display: flex;
  margin-top: 10px;
}
.reset-requestBtn {
  /* margin-right: 265px; */
  margin-top: 25px;
  border: none;
  border-radius: 35px;
  height: 42px;
  font-size: 20px;
  width: 230px;
  background-color: #1ec28b;
  border: none !important;
  color: #ffffff;
  display: flow-root;
  margin-left: 33px;
  /* justify-content: left; */
}
.backLoginText {
  margin-left: 10px;
  margin-bottom: 20px;
  font-weight: 350px;
}

.backArrow {
  margin-left: 10px;
  font-weight: 350px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .resetPasswordMain {
    height: 435px;
    background-color: white;
    width: 94%;
    padding: 10px;
    margin-top: 20px;
  }
  .reset-new-PasswordLabel {
    display: flex;
    padding-left: 3px;
  }
  .reset-inputNewPassword {
    width: 96%;
    height: 40px;
    margin-top: 5px;
  }
  .reset-confirmPasswordLabel {
    display: flex;
    padding-left: 3px;
    margin-top: 20px;
  }
  .reset-inputPassword {
    width: 96%;
    height: 40px;
    margin-top: 5px;
  }
  .reset-newPasswordError {
    display: flex;
    padding-left: 3px;
    margin-top: 5px;
    color: red;
  }
  .reset-confirmPasswordError {
    display: flex;
    padding-left: 3px;
    margin-top: 5px;
    color: red;
  }
  .reset-requestBtn {
    margin-top: 25px;
    border: none !important;
    border-radius: 35px;
    height: 42px;
    font-size: 15px;
    width: 50%;
    background-color: #1ec28b;
    color: #ffffff;
    display: flow-root;
    margin-left: 10px;
  }
}