.ads-manage-create-form {
  width: 100% !important;
  background: white;
  margin-top: 30px;
}
.dbsql-profile-main {
  display: flex;
}
.admindashboard-heading {
  font-size: 25px;
  justify-content: start;
  display: flex;
}
.ads-manage-home {
  color: gray !important;
  text-decoration: none !important;
}
.ads-management-breadcrumbs {
  font-size: 19px !important;
  /* font-weight: 200; */
}
.ads-management-subheading {
  color: #1ec28b;
}
.ads-manage-edit-main-div {
  justify-content: end;
  display: flex;
}
.ads-manage-edit-icon {
  display: flex !important;
  align-items: center !important;
  font-size: 20px !important;
}
.ads-manage-edit-btn {
  column-gap: 10px !important;
  color: white !important;
  justify-content: end !important;
  display: flex !important;
  background: rgba(68, 68, 187, 0.801) !important;
}
.ads-manage-name {
  /* margin-bottom: 20px; */
  justify-content: start !important;
  display: flex !important;
  color: #1ec28b;
}
.ads-manage-title {
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 12px;
  justify-content: start !important;
  display: flex !important;
  color: #1ec28b;
}

.ads-manage-name-span {
  color: red;
  font-size: 20px !important;
}
.ads-manage-create-name {
  width: 100%;
}
.ads-manage-create-company-name {
  width: 100%;
}
.ads-manage-create-website-name {
  width: 100%;
}
.ads-manage-create-email {
  width: 100%;
}
.ads-manage-create-ph-number {
  width: 100%;
}
.ads-manage-phone-num-grid {
  text-align: initial;
  color: #1ec28b;
}
.ads-manage-phone {
  display: flex;
  width: 100%;
}
.datePicker-icon-button:nth-child(0){
  display: none !important;
}
.country-code-grid{
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #1ec28b;
  height: 56px;
  background-color: transparent;
  z-index: 1;
  border-radius: 5px;
}
.ads-manage-textfield {
  width: 20%;
  height: 99%;
  top: 0%;
  left: -0%;
  background-color: rgb(232, 249, 243);
  border-radius: 5px 0.5rem 0.5rem 5px;
}
.ads-manage-phone-second-textfield {
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
}

.ads-manage-phone-second-textfield input{
  border: none;
}
.ads-manage-create-ad-link {
  width: 100%;
}
.ads-manage-create-ad-price {
  width: 100%;
}
.ads-manage-create-ad-duration {
  width: 100%;
}
.ads-manage-create-created-on {
  width: 100%;
}
.ads-manage-create-updated-on {
  width: 100%;
}
.ads-manage-ads-main-div {
  width: 100%;
  border: dotted #1ec28b;
  margin-top: 30px !important;
  height: auto;
  margin-bottom: 30px;
}
.ads-manage-create-city-level {
  width: 100%;
}
.ads-manage-create-city {
  width: 100% !important;
  justify-content: start;
  display: flex !important;
}
.ads-manage-create-state {
  width: 100% !important;
  justify-content: start;
  display: flex !important;
}
.ads-manage-create-country {
  width: 100% !important;
  justify-content: start;
  display: flex !important;
}
.ads-management-add-button {
  background: rgba(68, 68, 187, 0.801);
  color: white;
  justify-content: center !important;
  display: inline-block;
  width: 8%;
  margin-bottom: 20px;
  column-gap: 2px !important;
  margin-top: 20px;
  border-radius: 3px;
}
.ads-management-icon {
  column-gap: 0px !important;
  /* margin-right: -7px !important; */
  font-size: 15px;
}
.ads-mange-button {
  text-transform: lowercase !important;
  color: white !important;
}
.ads-manage-create-ad-placement {
  width: 100%;
  margin-bottom: 20px;
}
.ads-management-previous {
  display: flex;
  align-items: center;
}
.ads-manage-pre-ads {
  font-size: 20px;
  margin-bottom: 20px;
  column-gap: 10px !important;
  justify-content: space-around !important;
  display: flex;
  color: #1ec28b;
}
.ads-manage-checkbox {
  align-items: center;
  display: flex;
  justify-content: center !important;
}
.ads-manage-post-button {
  background: #1ec28b;
  justify-content: center !important;
  width: 25%;
  margin-top: 20px;
  color: white !important;
  font-weight: 600;
  margin-bottom: 20px !important;
  outline: none;
  border: none;
  height: 30px;
  border-radius: 3px;
}
.ads-manage-post-main-div {
  justify-content: center !important;
}
.ads-manage-create-cancel-main {
  font-size: 35px;
  display: flex;
  color: rgba(227, 51, 51, 0.871);
  margin-top: 30px;
  justify-content: right;
  align-items: center;
}
.ads-manage-cancel-icon {
  align-items: center;
  font-size: 35px;
  color: rgba(227, 51, 51, 0.871);
  width: 30%;
  display: flex;
  justify-content: end;
}
.ads-manage-city-main-grid {
  column-gap: 11px !important;
}
.ads-manage-create-upload-ads {
  border-radius: 5px;
  display: flex;
  margin-bottom: 30px !important;
  justify-content: center;
  width: 100%;
  border: 1px solid #1ec28b;
  cursor: pointer;
  padding: 5px;
}
.ads-manage-create-image-add-icon {
  cursor: pointer;
  color: #36e196;
  justify-content: center;
}
.ads-mange-create-upload-main-div {
  background-color:#e8f9f3;
  border: 1.5px dashed rgb(176, 174, 174);
  padding: 1.5rem;
  height: 75px;
}

.ads-manage-click-image {
  justify-content: center;
  color: black;
  font-weight: 600;
}
.ads-manage-drap-drop {
  font-size: 10px;
}
.ads-management-error-message {
  color: rgba(227, 51, 51, 0.871);
  text-align: center;
}
.ads-manage-create-desktop-img {
  width: 100%;
  height: 31rem;
  cursor: pointer;
}
.ads-manage-create-desktop-img img {
  cursor: pointer;
  width: 100%;
  height: 95%;
}
/* .ads-manage-without-disabled {
  opacity: 0;
} */
.ads-manage-with-disabled {
  opacity: 0.5;
  user-select: none;
}
.ads-management-ads-disabled {
  font-size: 17px;
  margin-bottom: 10px;
  justify-content: start;
  opacity: 0.5;
  display: flex;
}
.ads-management-ads-cancel-btn {
  display: flex;
  justify-content: end;
  font-size: 30px;
}
.ads-manage-edit-btn-disabled {
  column-gap: 10px !important;
  color: black !important;
  justify-content: end !important;
  display: flex !important;
  background: lightgrey !important;
  opacity: 0.5;
}
.ads-management-new-back-page {
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: start;
}
.ads-manage-click-here {
  opacity: 0.5;
}
.ads-manage-without-click {
  opacity: 0;
}


.bordered {
  display: inline-block;
  padding: 20px;
  /* Optional: 
     A normal dashed border can be set as a fallback, particularly if older browsers need to be supported. Notes:
     - the 4px width here is overwritten with any border-image-width value
     - a border-image-width can be omitted if it is the same value as the dashed border width
  */
  border: dashed 3px #c9c4c4;/*optional*/

  /* the initial value of border-style is "none", so it must be set to a different value for the border-image to show*/
  border-style: dashed;

  /* Individual border image properties */
  /* border-image-source: url("https://i.stack.imgur.com/wLdVc.png"); */
  border-image-slice: 2;
  border-image-repeat: round;

  /* or use the shorthand border-image */
  /* border-image: url("https://i.stack.imgur.com/wLdVc.png") 2 round; */
}


/*The border image of this one creates wider gaps*/

.largeGaps {
  border-image-source: url("data:image/svg+xml,%3csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' stroke='lightgrey' stroke-width='1.5' stroke-dasharray='6%2c 20' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
  margin: 0 0px;
}
.file-name-con{
  position: absolute;
  top: 0%;
  display: flex;
  align-items: center;
}

.file-text{
height: 50px;
display: flex;
flex-direction: column;
justify-content: center;
}

.file-text :nth-Child(1){
  margin-bottom: 2px;
}

.textDisplay{
  font-size: 12px;
  font-weight: bold;
  color: #3d3e3e;
}

.progress {
  background: #e8f9f3;
  justify-content: flex-start;
  border-radius: 4px;
  align-items: center;
  position: relative;
  display: flex;
  height: 50px;
}
.cancel-div{
  text-align: end;
  margin-bottom: 1rem;
}
.progress-con{
  margin-top: 2rem;
  position: relative;
}

.progress-value {
  transition: all 0.5s;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 4px;
  background: #97e3c9;
  height: 50px;
}

.file-icon{
  margin: 0 7px 0 7px;
}

.delete-file-icon{
  position: absolute;
  left: 47%;
  top: 35%;
}

@keyframes load {
  0% { width: 0; }
  100% { width: 100%; }}

  .ads-manage-create-loading-con{
    display: flex;
    flex-direction: column;
    width: 100%;
  }