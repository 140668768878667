/* .createTripHeading{
    
} */
.cameraIcon{
  display: none;
}

.createTripHeading {
  font-weight: bold;
  font-size: 2.2rem;
  /* background-color: aqua; */
  font-family: sans-serif;
  text-align: initial;
  /* margin-left: 114px;  */
}

.terms {
  display: flex;
  flex-direction: row !important;
margin-top: 1.5rem;
}
.terms_text {
  margin-top: 0.7rem !important;
  /* display: flex; */
  /* align-items: center; */
  text-align: start !important;
  /* background-color: #1ec28b; */
  width: 87%;
}

.termscon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.createTripHeadingText {
  font-family: sans-serif;
  text-align: initial;
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
  line-height: 2;
  /* background-color: #1ec28b; */
}

.Panel_Container {
  width: 100%;
}
.panel_image img {
  height: 80%;
  width: 100%;
}

.createTrip_body {
  margin: 4.5rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-1 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 3rem;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center_column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Stepper_heading {
  /* font-weight:bold !important; */
  text-align: initial;
  /* background-color: aqua; */
  font-family: sans-serif;
  border: none;
  padding-top: 1.2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #1ec28b;
  font-size: 1.7rem;
  font-weight: 520;
}

.step_head {
  display: flex;
  width: 93%;
  justify-content: center;
  align-items: center;
  background-color: #1ec28b;
}

.Stepper {
  width: 100%;
  /* background-color: #1ec28b; */
}

/*----------------------------- stepTitle ----------------------------*/
.stepTitle1 {
  font-weight: bold;
  /*background-color:"red"; */
  width: 100%;
  text-align: "center";
  font-size: 1.5rem;
  font-weight: 520;
}

.stepTitle0 {
  font-weight: bold;
  /*background-color:"red"; */
  width: 50%;
  text-align: "center";
  font-size: 1.5rem;
  font-weight: 520;
}

.stepTitle2 {
  font-weight: bold;
  /*background-color:"red"; */
  width: 40%;
  text-align: "center";
  font-size: 1.5rem;
  font-weight: 520;
}

.stepTitle3 {
  font-weight: bold;
  /*background-color:"red"; */
  width: 60%;
  text-align: "center";
  font-size: 1.5rem;
  font-weight: 520;
}

/*----------------------------- stepTitle ----------------------------*/
/*----------------------------- step_subTitle ----------------------------*/
.step_subTitle0 {
  width: 50%;
}

.step_subTitle1 {
  width: 60%;
}

.step_subTitle2 {
  width: 67%;
}
.step_subTitle3 {
  width: 50%;
}

/*----------------------------- step_subTitle ----------------------------*/

/*-----------------------------Traveller Details------------------------------------*/
.card_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.travellerdNeed {
  /* box-shadow: 0px 3px 1px -1px #1ec28b !important; */
  background-color: rgba(30, 194, 139, 0.1) !important;
  height: 100%;
  width: 20%;
  border-radius: 12px !important;
  padding: 3rem;
}

.activeTravellerNeed {
  /* background-color: rgba(30, 194, 139, 0.1) !important; */
  height: 100%;
  width: 20%;
  border-radius: 12px !important;
  padding: 3rem;
  /* border: 3px solid #1ec28b; */
  box-shadow: 0px 5px 1px -1px #5addb1 !important;
 color: white !important;
  background-color: #52ddaf !important;
}
.cretae-trip-ain-btn{
  /* background-color: #b4f6df; */
  margin-top: 2rem;
  width: 95%;
}
.maximum {
  width: 90%;
  display: flex;
  justify-content: end;
  color: #ff8d8d;
}

.radio_container {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border-radius: 2px;
  /* background-color: aqua; */
}
.radio_container_2 {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1.5px solid #1ec28b;
  width: 78%;
  border-radius: 0.5rem;
}

.radio_card {
  padding: 1rem;
  width: 78%;
  border: 1.5px solid #1ec28b;
  border-radius: 0.5rem;
}

.radio_super_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Radio {
  display: flex;
  /* background-color: aquamarine; */
  align-items: center;
  justify-content: center;
}

.Image_upload_container {
  /* position: absolute; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #0000ff; */
}

.firstLine {
  display: flex;
  flex-direction: row;
  background-color: aqua;
}

.destinationSelfButton {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 60px;
  margin-right: 102px;
}

.destinationSelfBtn {
  /* background-color: #1ec28b !important; */
  color: white !important;
  /* font-size: 13px !important; */
  /* border-radius: 15px !important; */
  /* background-color: #c1f4e3; */
}



/* .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input{
    border-bottom: 2px solid #1ec28b !important;
}
.css-407ebk-MuiInputBase-root-MuiInput-root:before{
    border-bottom: 2px solid #1ec28b !important;
}

.MuiInputBase-root input{
    outline: none !important;
} */

/* .css-q8hpuo-MuiFormControl-root{
    border-bottom: 2px solid #1ec28b !important;
} */

/* .css-1ptx2yq-MuiInputBase-root-MuiInput-root{
    border-bottom: 2px solid #1ec28b !important;

} */
/* textarea{
    border: 1px solid #1ec28b !important;
    border-radius: 0.5rem;
} */


/* style={{display:"flex",justifyContent:"end",backgroundColor:"red",width:"78%",marginTop:"2rem",padding:"1rem"}} */
.FulldetailPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  /* background-color: aquamarine; */
}

textarea:focus {
  border: 2px solid #1ec28b !important;
}

.stepperNextBtn {
  background-color: #1ec28b !important;
}

/*------------------trial -------------------------------*/
/*  */
/*------------------trial -------------------------------*/

/* MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary MuiInputBase-formControl css-1ptx2yq-MuiInputBase-root-MuiInput-root */
/*-----------------------------Traveller Details------------------------------------*/

/*-----------------------------Full details------------------------------------*/
.fullDetail {
  /* display: flex; */
  padding: 3rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 80%;
  border: 2px solid #ff8d8d;
  border-radius: 1rem;
}
.Share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.label_con {
  display: flex;
}
.fullDetailEdit {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 7px !important;
  border: none;
  /* font-size: 11px !important;  */
  height: 26px;
  /* margin-top: 20px !important; */
}

.share_con {
  display: flex;
  align-items: center;
  justify-content: end;
  /* border-radius:35% !important; */
  border: none;
  /* padding: 0.5rem; */
  width: 50%;
}
.icon_con {
  background-color: #b4f6df;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: none;
  border-radius: 2rem;
}

.country_con {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.personDetails {
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  background-color: #d9f8ed;
  border-radius: 0.5rem;
}
.detail_line {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}
.own_detail {
  width: 60%;
  /* background-color: #1ec28b; */
}
.own_detail_data {
  margin-left: 2rem;
  display: grid;
  grid-template-columns: 30% 3% 65%;
  margin-bottom: 1rem;
}
.Full_image {
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem 0 2rem;
  /* background-color:red; */
}
.cardTop {
  /* flex-direction: column !important; */
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  /* background-color: aqua; */
}

.font5 {
  /* font-family:sans-serif !important; */
  text-align: initial;
  font-size: 1.1rem !important;
  font-weight: 600 !important ;
  /* background-color: chocolate; */
  /* line-height: normal; */
  margin-left: 0.5rem !important;
  padding-left: 0.3rem;
  /* overflow: hidden; */
  text-overflow: ellipsis !important;
}
.font5_label {
  /* font-family:sans-serif !important; */
  text-align: initial;
  font-size: 1.1rem !important;
  /* font-weight:600 !important ; */
  /* line-height: normal; */
  /* background-color: brown; */
}
.right_grid {
  display: grid;
  grid-template-columns: 20% 80%;
  gap: 1rem;
}
.right_r {
  display: flex;
}

.addPhoto_without {
  position: absolute;
  bottom: -5%;
  right: 0%;
  z-index: 1;
}

.addPhoto_with {
  position: absolute;
  bottom: -5%;
  right: -22%;
  z-index: 1;
}

.addPhoto_without_camera {
  display: none;
  position: absolute;
  bottom: -15%;
  right: -10%;
  z-index: 1;
}

.addPhoto_with_camera{
  display: none;
  position: absolute;
  bottom: -5%;
  right: 22%;
  z-index: 1;
}

.flight_dir {
  margin-bottom: 1.5rem;
}

.transit {
  width: 100%;
  /* background-color: blue; */
  text-align: center;
  /* background-color: #1ec28b; */
}

.error {
  color: red;
  width: 70%;
  text-align: center;
  /* background-color: #1ec28b; */
}

.error_w_100 {
  color: red;
  width: 100%;
  text-align: center;
}

/*----------------------------- step_subTitle ----------------------------*/

/*-----------------------------Traveller Details------------------------------------*/
.card_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
/* .travellerdNeed {
  /* box-shadow: 0px 3px 1px -1px #1ec28b !important; *
  background-color: rgba(30, 194, 139, 0.1) !important;
  height: 100%;
  width: 20%;
  border-radius: 12px !important;
  padding: 3rem;
} */

.maximum {
  width: 90%;
  display: flex;
  justify-content: end;
  color: #ff8d8d;
}

/* .activeTravellerNeed {
  background-color: rgba(30, 194, 139, 0.1) !important;
  height: 100%;
  width: 20%;
  border-radius: 12px !important;
  padding: 3rem;
  box-shadow: 0px 5px 1px -1px #1ec28b !important;
} */

.radio_container {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border-radius: 2px;
  /* background-color: aqua; */
}
.radio_container_2 {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1.5px solid #1ec28b;
  width: 78%;
  border-radius: 0.5rem;
}

.radio_card {
  padding: 1rem;
  width: 78%;
  border: 1.5px solid #1ec28b;
  border-radius: 0.5rem;
}

.radio_super_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Radio {
  display: flex;
  /* background-color: aquamarine; */
  align-items: center;
  justify-content: center;
}

.Image_upload_container {
  /* postion: absolute; */
  width: 53%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #0000ff; */
}

.firstLine {
  display: flex;
  flex-direction: row;
  /* background-color: aqua; */
}

.destinationSelfButton {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 60px;
  margin-right: 102px;
}

.destinationSelfBtn {
  background-color: #1ec28b !important;
  color: white !important;
  /* font-size: 13px !important; */
  /* border-radius: 15px !important; */
  /* background-color: #c1f4e3; */
}

.inputField input {
  text-align: center !important;
  /* border-bottom: 2px solid #1ec28b !important; */
}

.backTohome {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 78%;
  margin-top: 2rem;
  padding: 1rem;
}

.FulldetailPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  /* background-color: aquamarine; */
}

textarea:focus {
  border: 2px solid #1ec28b !important;
}

.stepperNextBtn {
  background-color: #1ec28b !important;
}

/*------------------trial -------------------------------*/

/*------------------trial -------------------------------*/

/* MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary MuiInputBase-formControl css-1ptx2yq-MuiInputBase-root-MuiInput-root */
/*-----------------------------Traveller Details------------------------------------*/

/*-----------------------------Full details------------------------------------*/
.fullDetail {
  /* display: flex; */
  padding: 3rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 80%;
  border: 2px solid#ff8d8d;
  border-radius: 1rem;
}
.Share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.label_con {
  display: flex;
}
.fullDetailEdit {
  background-color: #1ec28b !important;
  color: white !important;
  border-radius: 7px !important;
  border: none;
  /* font-size: 11px !important;  */
  height: 26px;
  /* margin-top: 20px !important; */
}

.share_con {
  display: flex;
  align-items: center;
  justify-content: end;
  /* border-radius:35% !important; */
  border: none;
  /* padding: 0.5rem; */
  width: 50%;
}
.icon_con {
  background-color: #b4f6df;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border: none;
  border-radius: 2rem;
}



.personDetails {
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  background-color: #d9f8ed;
  border-radius: 0.5rem;
}
.detail_line {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}
.own_detail {
  width: 60%;
  /* background-color: #1ec28b; */
}
.own_detail_data {
  margin-left: 2rem;
  display: grid;
  grid-template-columns: 30% 3% 65%;
  margin-bottom: 1rem;
}
.Full_image {
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem 0 2rem;
  /* background-color:red; */
}
.cardTop {
  /* flex-direction: column !important; */
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  /* background-color: aqua; */
}

.font5 {
  /* font-family:sans-serif !important; */
  text-align: initial;
  font-size: 1.1rem !important;
  font-weight: 600 !important ;
  /* background-color: chocolate; */
  /* line-height: normal; */
  margin-left: 0.5rem !important;
  padding-left: 0.3rem;
  /* overflow: hidden; */
  text-overflow: ellipsis !important;
}
.font5_label {
  /* font-family:sans-serif !important; */
  text-align: initial;
  font-size: 1.1rem !important;
  /* font-weight:600 !important ; */
  /* line-height: normal; */
  /* background-color: brown; */
}
.right_grid {
  display: grid;
  grid-template-columns: 20% 80%;
  gap: 1rem;
}
.right_r {
  display: flex;
}
.transit {
  width: 100%;
  /* background-color: blue; */
  display: grid;
  /* gap: 1rem; */
  grid-template-columns: 20% 80%;
  margin-bottom: 0.6rem;
  /* background-color: chartreuse; */
}

.tansit_data {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 0.6rem;
  /* background-color: cadetblue; */
}

.colon_con {
  display: grid;
  grid-template-columns: 90% 10%;
}

.colon_con_tr {
  display: grid;
  grid-template-columns: 90% 10%;
}

.tansit_right {
  display: grid;
  grid-template-columns: 25% 80%;
  margin-bottom: 0.6rem;
  /* background-color: cadetblue; */
}

.Avatar_con {
  height: 6rem;
  width: 100%;
  position: relative;
}

.field1 {
  width: 95%;
  margin-top: 3rem;
}
.field2 {
  width: 90%;
  margin-top: 4rem;
  text-align: initial;
}

.transitCard {
  width: 90%;
  margin-top: 2rem;
  background-color: #e8f9f3;
  padding: 4rem;
  border-radius: 0.5rem;
  /* background-color: #1ec28b; */
}
.transit_line1 {
  display: flex;
  align-items: center;
  width: 100%;
  /* background-color: #ff8d8d; */
}

.transit_linem {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  /* background-color: #1ec28b ; */
}

.transit_linem_dest {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  /* background-color: lightcyan ; */
}
.line1_w {
  width: 70%;
  /* background-color: aqua; */
  /* background
        -color: aqua; */
}
/* .style={{textAlign:"initial",marginBottom:"1rem"}} */

.Auto_w {
  width: 70%;
}
.Auto_w_l {
  text-align: initial;
  margin-bottom: 1rem !important;
}
.Auto_w_p_dest {
  width: 70%;
}
.Auto_w_p {
  width: 100%;
}
.cancel_btn {
  /* background-color: #b4f6df !important; */
  margin-top: 0.5rem !important;
  color: #ff8d8d !important;
}

.cancel_div {
  display: none;
}
/* style={{backgroundColor:"#b4f6df",margin:"0.5rem",color:"#1ec28b"}}  */

/* style={{display:"flex",alignItems:"center",width:"100%",marginTop:"2rem"}} */
/* style={{display:"flex",alignItems:"center",width:"100%"}} */
/* style={{width:"90%",marginTop:"2rem",backgroundColor:"#e8f9f3",padding:"4rem",borderRadius:"0.5rem"} */
/* .style={{width:"88%", marginTop:"3rem"} */
/* .style={{height:"6rem",width:"100%",position:"relative"} */
.trip_w {
  width: 90%;
  margin-top: 2rem;
}

.textArea {
  outline: none;
  text-align: start !important;
  border: 2px solid #1ec28b !important;
  font-size: 1rem;
  font-family: sans-serif;
  width: 98% !important;
  /* background-color: #1ec28b; */
}

textarea::placeholder {
  color: #b6b6b6;
}

/* .css-1ptx2yq-MuiInputBase-root-MuiInput-root{
    padding-bottom: 0.8rem !important;
  } */

/* .css-407ebk-MuiInputBase-root-MuiInput-root{
    margin-top: none !important;
  } */
/* .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 2px solid #1ec28b !important;
  }
  .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid #1ec28b !important;
  }
  
  .css-11xev0i-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    border-bottom: 2px solid #1ec28b !important;
  }
  
  .css-11xev0i-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
    border-bottom: 2px solid #1ec28b !important;
  }
  .css-407ebk-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 2px solid #1ec28b !important;
  }
  .css-407ebk-MuiInputBase-root-MuiInput-root:after {
      border-bottom: 1px solid #1ec28b !important;
    } */

.xxx div::after {
  border-bottom: 2px solid #1ec28b !important;
}
.xxx div::before {
  outline: none !important;
  border-bottom: 2px solid #1ec28b !important;
}
.aaaaa::after {
  border: none !important;
}
.inputField input:disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.779);
}
.inputField_l input:disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.779);
}

.aaaaa::before {
  border-bottom: 2px solid #1ec28b !important;
}


.travellerdeatils-needtravel-text {
  font-size: 23px !important;
}
/*-----------------------------Full details------------------------------------*/

@media screen and (max-width: 1050px) {
  .createTrip_body {
    margin: 3rem;
  }

  .step_subTitle0 {
    width: 60%;
  }

  .step_subTitle1 {
    width: 70%;
  }

  .step_subTitle2 {
    width: 80%;
  }
  .travellerdeatils-needtravel-text {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 992px) {
  .stepTitle3 {
    font-weight: bold;
    width: 65%;
    text-align: "center";
    font-size: 1.5rem;
    font-weight: 520;
  }

  .step_subTitle1 {
    width: 75%;
  }

  .step_subTitle2 {
    width: 85%;
  }
}



@media screen and (max-width: 939px) {
  .step_subTitle1 {
    width: 80%;
  }

  .step_subTitle2 {
    width: 90%;
  }
}
@media screen and (max-width: 907px) {
  .transitCard {
    width: 90%;
    margin-top: 2rem;
    background-color: #e8f9f3;
    padding: 4rem 2rem 4rem 2rem;
    border-radius: 0.5rem;
  }
  .travellerdNeed {
    background-color: rgba(30, 194, 139, 0.1) !important;
    height: 100%;
    width: 25%;
    border-radius: 12px !important;
    padding: 2rem;
  }

  .activeTravellerNeed {
    /* background-color: rgba(30, 194, 139, 0.1) !important; */
    height: 100%;
    width: 25%;
    border-radius: 12px !important;
    padding: 2rem;
    box-shadow: 0px 5px 1px -1px #1ec28b !important;
    color: white !important;
    background-color: #52ddaf !important;
  }
}

@media screen and (max-width: 906px) {
  .stepTitle3 {
    font-weight: bold;

    width: 70%;
    text-align: "center";
    font-size: 1.5rem;
    font-weight: 520;
  }

  .step_subTitle3 {
    width: 70%;
  }
}

@media screen and (max-width: 897px) {
  .step_subTitle1 {
    width: 90%;
  }
  .backTohome {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 90%;
    margin-top: 2rem;
    padding: 1rem;
  }

  .step_subTitle2 {
    width: 100%;
  }
  .tansit_data {
    display: grid;
    grid-template-columns: 35% 3% 60%;
    margin-bottom: 0.6rem;
    /* background-color: cadetblue; */
  }
}
@media screen and (max-width: 849px) {
  .fullDetail {
    padding: 1rem;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 90%;
    border: 2px solid #ff8d8d;
    border-radius: 1rem;
  }
}

@media screen and (max-width: 856px) {
  .step_subTitle0 {
    width: 70%;
  }
}

@media screen and (max-width: 854px) {
  .step_subTitle4 {
    width: 100%;
  }
  .stepTitle3 {
    font-weight: bold;

    width: 80%;
    text-align: "center";
    font-size: 1.5rem;
    font-weight: 520;
  }
}

@media screen and (max-width: 854px) {
  .own_detail_data {
    margin-left: 2rem;
    display: grid;
    grid-template-columns: 35% 3% 60%;
    margin-bottom: 1rem;
  }
  .stepTitle1 {
    font-weight: bold !important;

    width: 80%;
    text-align: "center";
    font-size: 1rem;
    font-weight: 520;
  }

  .stepTitle3 {
    font-weight: bold !important;

    width: 80%;
    text-align: "center";
    font-size: 1rem;
    font-weight: 520;
  }
  .stepTitle0 {
    font-weight: bold !important;
    /*background-color:"red"; */
    width: 50%;
    text-align: "center";
    font-size: 1rem;
    font-weight: 520;
  }

  .stepTitle2 {
    font-weight: bold !important;
    /*background-color:"red"; */
    width: 40%;
    text-align: "center";
    font-size: 1rem;
    font-weight: 520;
  }
}

@media screen and (max-width: 824px) {
  .Auto_w {
    width: 80%;
  }

  .transitCard {
    width: 90%;
    margin-top: 2rem;
    background-color: #e8f9f3;
    padding: 4rem 1rem 4rem 1rem;
    border-radius: 0.5rem;
  }
  .step_subTitle3 {
    font-size: 0.9rem !important;
    width: 100%;
  }
  .step_subTitle2 {
    font-size: 0.9rem !important;
    width: 100%;
  }
  .step_subTitle1 {
    font-size: 0.9rem !important;
    width: 100%;
  }
  .step_subTitle0 {
    font-size: 0.9rem !important;
    width: 70%;
  }
  .center_column {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .travellerdeatils-needtravel-text {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 792px) {
  .createTrip_body {
    margin: 2rem;
  }
}

@media screen and (max-width: 762px) {
  .transitCard {
    width: 95%;
    margin-top: 2rem;
    background-color: #e8f9f3;
    padding: 4rem 1rem 4rem 1rem;
    border-radius: 0.5rem;
  }
  .createTrip_body {
    margin: 1rem;
  }
}

@media screen and (max-width: 726px) {
  .transitCard {
    width: 95%;
    margin-top: 2rem;
    background-color: #e8f9f3;
    padding: 4rem 1rem 4rem 1rem;
    border-radius: 0.5rem;
  }
  .createTrip_body {
    margin: 0.5rem;
  }
}

@media screen and (max-width: 714px) {
  .transitCard {
    width: 100%;
    margin-top: 2rem;
    background-color: #e8f9f3;
    padding: 4rem 1rem 4rem 1rem;
    border-radius: 0.5rem;
  }
  .createTrip_body {
    margin: auto;
  }

  .responsive {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
}

@media screen and (max-width: 696px) {
  .createTrip_body {
    margin: 0;
  }
  .S_H {
    display: none !important;
  }
  .transit {
    width: 100%;
    /* background-color: blue; */
    display: grid;
    /* gap: 1rem; */
    grid-template-columns: 25% 80%;
    /* background-color: chartreuse; */
  }
  .backTohome {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    padding: 1rem;
  }
}

@media screen and (max-width: 662px) {
  .right_grid {
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 1.5rem;
  }
  .field1 {
    width: 100%;
    margin-top: 3rem;
  }
  .field2 {
    width: 100%;
    margin-top: 3rem;
  }
  .trip_w {
    width: 100%;
    margin-top: 2rem;
  }

  .travellerdNeed {
    /* box-shadow: 0px 3px 1px -1px #1ec28b !important; */
    background-color: rgba(30, 194, 139, 0.1) !important;
    height: 100%;
    width: 30%;
    border-radius: 12px !important;
    padding: 2rem;
  }

  .activeTravellerNeed {
    /* background-color: rgba(30, 194, 139, 0.1) !important; */
    height: 100%;
    width: 30%;
    border-radius: 12px !important;
    padding: 2rem;
    box-shadow: 0px 5px 1px -1px #1ec28b !important;
    color: white !important;
    background-color: #52ddaf !important;
  }
}

@media screen and (max-width: 644px) {
  .own_detail {
    width: 75%;
    /* background-color: #1ec28b; */
  }
  .right_grid {
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 1.5rem;
  }
  .field1 {
    width: 100%;
    margin-top: 3rem;
  }
  .field2 {
    width: 100%;
    margin-top: 3rem;
  }
  .trip_w {
    width: 100%;
    margin-top: 2rem;
  }

  .travellerdNeed {
    /* box-shadow: 0px 3px 1px -1px #1ec28b !important; */
    background-color: rgba(30, 194, 139, 0.1) !important;
    height: 100%;
    width: 30%;
    border-radius: 12px !important;
    padding: 2rem;
  }

  .activeTravellerNeed {
    /* background-color: rgba(30, 194, 139, 0.1) !important; */
    height: 100%;
    width: 30%;
    border-radius: 12px !important;
    padding: 2rem;
    box-shadow: 0px 5px 1px -1px #1ec28b !important;
    color: white !important;
    background-color: #52ddaf !important;
  }
}
@media screen and (max-width: 630px) {
  .font5 {
    /* font-family:sans-serif !important; */
    text-align: initial;
    font-size: 0.8rem !important;
    font-weight: 600 !important ;
    /* background-color: chocolate; */
    /* line-height: normal; */
    margin-left: 0.5rem !important;
    padding-left: 0.3rem;
    /* overflow: hidden; */
    text-overflow: ellipsis !important;
  }
  .font5_label {
    /* font-family:sans-serif !important; */
    text-align: initial;
    font-size: 0.8rem !important;
    /* font-weigt:600 !important ; */
    /* line-height: normal; */
    /* background-color: brown; */
  }
}
@media screen and (max-width: 561px) {
  .right_grid {
    display: block;
    /* flex-direction: row !important; */
    /* background-color: #1ec28b; */
  }

  .colon_con_tr {
    display: flex;
    /* grid-template-columns: 80% 10%; */
  }
  .right_r {
    padding-left: 1rem;
  }

  .own_detail_data {
    margin-left: 1rem;
    display: grid;
    grid-template-columns: 30% 3% 65%;
    margin-bottom: 1rem;
  }
  .icon_con {
    background-color: #b4f6df;
    padding: 0rem 0rem 0rem 0.3rem;
    border: none;
    border-radius: 2rem;
  }
}

@media screen and (max-width: 546px) {
  .Auto_w_l {
    text-align: initial;
    margin-bottom: 1rem;
    width: 30%;
  }
  .Auto_w_p {
    width: 80%;
  }
  .Auto_w {
    width: 70%;
  }
  .line1_w {
    display: flex;
    /* flex-direction: column !important; */
    width: 100%;
    /* background-color: aqua; */
    margin-bottom: 1rem;
  }

  .transit_linem {
    display: block;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
  }

  .transit_line1 {
    display: block;
    align-items: center;
    width: 100%;
  }
  .travellerdNeed {
    /* box-shadow: 0px 3px 1px -1px #1ec28b !important; */
    background-color: rgba(30, 194, 139, 0.1) !important;
    height: 100%;
    width: 32%;
    border-radius: 12px !important;
    padding: 2rem;
    font-weight: 400;

    /* font-size: 2px !important; */
  }
  .stepper-steps-text {
    font-size: 1.3rem !important;
  }

  .Radio h6 {
    font-size: 1.2rem !important;
  }

  .activeTravellerNeed {
    /* background-color: rgba(30, 194, 139, 0.1) !important; */
    height: 100%;
    width: 32%;
    border-radius: 12px !important;
    padding: 2rem;
    box-shadow: 0px 5px 1px -1px #1ec28b !important;
    color: white !important;
    background-color: #52ddaf !important;
  }
}

@media screen and (max-width: 520px) {
  .travellerdNeed {
    /* box-shadow: 0px 3px 1px -1px #1ec28b !important; */
    background-color: rgba(30, 194, 139, 0.1) !important;
    height: 100%;
    width: 30%;
    border-radius: 12px !important;
    padding: 2rem;
    font-weight: 400;

    /* font-size: 2px !important; */
  }
  .stepper-steps-text {
    font-size: 1.3rem !important;
  }

  .activeTravellerNeed {
    /* background-color: rgba(30, 194, 139, 0.1) !important; */
    height: 100%;
    width: 30%;
    border-radius: 12px !important;
    padding: 2rem;
    box-shadow: 0px 5px 1px -1px #1ec28b !important;
    color: white !important;
    background-color: #52ddaf !important;
  }

  .Image_upload_container {
    /* position: absolute; */
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #0000ff; */
  }
  .Avatar_con {
    height: 5rem;
    width: 100%;
    position: relative;
  }
  .travellerdeatils-needtravel-text {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 482px){
  .travellerdeatils-needtravel-text {
    font-size: 14px !important;
  }
  .addPhoto_without_camera {
    display: block;
    position: absolute;
    bottom: -15%;
    right: -10%;
    z-index: 1;
  }
  
  .addPhoto_with_camera{
    display: block;
    position: absolute;
    bottom: -5%;
    right: 22%;
    z-index: 1;
  }
  .travellerdNeed {
    /* box-shadow: 0px 3px 1px -1px #1ec28b !important; */
    background-color: rgba(30, 194, 139, 0.1) !important;
    height: 100%;
    width: 30%;
    border-radius: 12px !important;
    padding-left: 1rem;
    font-weight: 400;

    /* font-size: 2px !important; */
  }

  .activeTravellerNeed {
    /* background-color: rgba(30, 194, 139, 0.1) !important; */
    height: 100%;
    width: 30%;
    border-radius: 12px !important;
    padding-left: 1rem;
    box-shadow: 0px 5px 1px -1px #1ec28b !important;
    color: white !important;
    background-color: #52ddaf !important;
  }

}
@media screen and (max-width: 456px) {
  .Auto_w_l {
    text-align: initial;
    margin-bottom: 1rem;
    width: 30%;
  }
  .Auto_w_p {
    width: 90%;
  }
  .Auto_w {
    width: 70%;
  }
  .line1_w {
    display: flex;
    width: 100%;
    /* background-color: aqua; */
    margin-bottom: 1rem;
  }

  .transit_linem {
    display: block;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
  }

  .transit_line1 {
    display: block;
    align-items: center;
    width: 100%;
  }
}
@media screen and (max-width: 456px) {
  .cancel_div {
    display: block;
    margin-top: 1rem !important;
    color: #ff8d8d !important;
  }
  .cancel_btn {
    display: none !important;
  }
  .Auto_w {
    width: 90%;
  }
  .Auto_w_l {
    text-align: initial;
    margin-bottom: 1rem;
    width: 30%;
  }
  .Auto_w_p {
    width: 100%;
  }

  .line1_w {
    display: flex;
    width: 100%;
    /* background-color: aqua; */
    margin-bottom: 1rem;
  }

  .transit_linem {
    display: block;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
  }

  .transit_line1 {
    display: block;
    align-items: center;
    width: 100%;
  }
}

@media screen and (max-width: 449px) {
  .radio_container_2 {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column !important;
    justify-content: space-around;
    border: 1.5px solid #1ec28b;
    width: 78%;
    border-radius: 0.5rem;
  }
  .Radio {
    width: 100%;
    display: flex;
    justify-content: start;
    flex-direction: row-reverse !important;
    /* background-color: aqua; */
  }

  .Image_upload_container {
    /* position: absolute; */
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #0000ff; */
  }
}

@media screen and (max-width: 482px) {
  .travellerdNeed {
    /* box-shadow: 0px 3px 1px -1px #1ec28b !important; */
    background-color: rgba(30, 194, 139, 0.1) !important;
    height: 100%;
    width: 28%;
    border-radius: 12px !important;
    padding: 2rem;
    font-weight: 400;

    /* font-size: 2px !important; */
  }
  .edit-btn{
    display: none;
  }
  .stepper-steps-text {
    font-size: 1.2rem !important;
  }

  .activeTravellerNeed {
    /* background-color: rgba(30, 194, 139, 0.1) !important; */
    height: 100%;
    width: 28%;
    border-radius: 12px !important;
    padding: 2rem;
    box-shadow: 0px 5px 1px -1px #1ec28b !important;
    color: white !important;
    background-color: #52ddaf !important;
  }
}

@media screen and (max-width: 476px) {
  .travellerdNeed {
    /* box-shadow: 0px 3px 1px -1px #1ec28b !important; */
    background-color: rgba(30, 194, 139, 0.1) !important;
    height: 100%;
    width: 28%;
    border-radius: 12px !important;
    padding: 2rem;
    font-weight: 400;

    /* font-size: 2px !important; */
  }
  .stepper-steps-text {
    font-size: 1rem !important;
  }
  .Radio h6 {
    font-size: 1rem !important;
  }

  .activeTravellerNeed {
    /* background-color: rgba(30, 194, 139, 0.1) !important; */
    height: 100%;
    width: 28%;
    border-radius: 12px !important;
    padding: 2rem;
    box-shadow: 0px 5px 1px -1px #1ec28b !important;
    color: white !important;
    background-color: #52ddaf !important;
  }
}
@media screen and (max-width: 420px) {
  .cancel_div {
    display: block;
    margin-top: 1rem !important;
    color: #ff8d8d !important;
  }
  .cancel_btn {
    display: none !important;
  }
  .Auto_w {
    width: 70%;
  }
  .Auto_w_l {
    text-align: initial;
    margin-bottom: 1rem;
    width: 35%;
  }
  .Auto_w_p {
    width: 100%;
  }
  .Auto_w_p_dest {
    width: 100%;
  }

  .line1_w {
    display: flex;
    width: 100%;
    /* background-color: aqua; */
    margin-bottom: 1rem;
  }

  .transit_linem {
    display: block;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
  }

  .transit_line1 {
    display: block;
    align-items: center;
    width: 100%;
  }

 .cardHeight{
  display: flex;
  align-items: center;
  height: 4rem;
 }
}

@media screen and (max-width: 360px) {
  .Image_upload_container {
    /* position: absolute; */
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #0000ff; */
  }
  .Avatar_con {
    height: 4.5rem;
    width: 100%;
    position: relative;
  }
}
@media screen and (max-width: 420px) {
  .cancel_div {
    display: block;
    width: 2%;
  }
  .dummy {
    width: 0;
  }
  .cancel_btn {
    display: none !important;
  }
  .Auto_w {
    width: 85%;
  }
  .Auto_w_l {
    text-align: initial;
    margin-bottom: 1rem;
    width: 40%;
  }
  .Auto_w_p {
    width: 100%;
  }

  .line1_w {
    display: flex;
    flex-direction: column !important;
    width: 100%;
    /* background-color: aqua; */
    margin-bottom: 1rem;
  }

  .transit_linem {
    display: block;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
  }

  .transit_line1 {
    display: block;
    align-items: center;
    width: 100%;
  }
}
@media screen and (max-width: 382px) {
  .Image_upload_container {
    /* position: absolute; */
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #0000ff; */
  }

  .Image_upload_container h6 {
    font-size: 1rem;
  }
}


/*---------------------------------------- Checking --------------------------------------*/

@media screen and (max-width: 308px) {
  .aaaaaX {
    height: 2rem;
  }
  .backTohome {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    padding: 1rem;
  }
}

@media only screen and (max-width: 992px) {
  .createTripHeadingText {
    font-family: sans-serif;
    text-align: initial;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    line-height: 1.5;
    /* background-color: #1ec28b; */
  }
  .terms{
    display: flex;
    flex-direction: row !important;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .Stepper_heading {
    /* font-weight: bold !important; */
    text-align: initial;
    /* background-color: aqua; */
    font-family: sans-serif;
    border: none;
    padding-top: 1.2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #1ec28b;
    font-size: 19px !important;
    font-weight: 520;
    line-height: 1.5;
  }
  .stepper-steps-text {
    font-size: 17px !important;
  }
  /* .travellerdNeed {
     box-shadow: 0px 3px 1px -1px #1ec28b !important; 
    background-color: rgba(30, 194, 139, 0.1) !important;
    height: 60px;
    width: 25%;
    border-radius: 12px !important;
    padding: 1.5rem;
    font-weight: 400;
  } */
  /* .travellerdeatils-needtravel-text {
    font-size: 15px !important;
  } */

  .terms_text {
    /* margin-top: 26px !important; */
    text-align: start !important;
    width: 36%;
    font-size: 13px;
  }
}
